import axios from 'axios';
import { MutationOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { orderStateUpdateOrderKeyAction } from 'reducers';
import { IOrderCheckout } from './interfaces';

export interface IUpdateOrderCompleteData {
	notes: string;
}

export const updateOrderComplete = async (
	orderKey: string,
	data: IUpdateOrderCompleteData,
	orderDispatch: any
) => {
	return await axios
		.patch<IOrderCheckout>(`/api/v2/shop/orders/${orderKey}/complete`, data)
		.then(response => {
			orderDispatch(orderStateUpdateOrderKeyAction(''));
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating order item:`, error);
			return Promise.reject(error);
		});
};

export function useUpdateOrderCompleteMutation(
	orderKey: string,
	orderDispatch: any,
	mutationOptions?: UseMutationOptions<IOrderCheckout, any, IUpdateOrderCompleteData>
) {
	return useMutation((data: IUpdateOrderCompleteData) => {
		return updateOrderComplete(orderKey, data, orderDispatch);
	},{
		...mutationOptions
	});
}
