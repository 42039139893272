import React from 'react';
import Image, { ImageProps } from 'next/image';
import {
	buildTransformationsQuery,
	IMOTransformationProps
} from 'litto-lib/utils';

export type ICloudinaryImageProps = {
	cloudinaryOptions?: {
		transformations?: IMOTransformationProps;
	};
} & ImageProps;

export const CloudinaryImage: React.FC<ICloudinaryImageProps> = ({
	cloudinaryOptions,
	alt,
	...props
}) => {
	const cloudinaryLoader = ({ src, width, quality }: any) => {
		const transformationsQuery = buildTransformationsQuery({
			...cloudinaryOptions?.transformations,
			w: `${width}`,
			q: `${quality}`
		});
		return `${src}${transformationsQuery}`;
	};
	return <Image loader={cloudinaryLoader} alt={alt} quality={100} {...props} />;
};
