import { SkeletonText } from 'litto-lib/components/common';
import { getImageUrl, uniqueObjArray } from 'litto-lib/utils';
import React from 'react';

import { PersonSkeleton } from 'components/person';
import { GalleryGrid, ImageSlider } from 'components/shared';
import { IImage } from 'services/shop/interfaces';

export interface ISingleHeroProps {
	title?: string;
	contentTop?: JSX.Element;
	contentBottom?: JSX.Element;
	images: IImage[];
}

export const SingleHero: React.FC<ISingleHeroProps> = ({
	title,
	images,
	contentTop,
	contentBottom
}) => {
	const sources = uniqueObjArray(
		images.map(img => {
			return {
				id: img['@id'],
				path: getImageUrl(img.imagePath),
				alt: img.title || title
			};
		}),
		'path'
	);

	return (
		<div className="flex flex-wrap items-start lg:-mx-2.5">
			<div className="w-full pb-6 lg:w-4/12 lg:pl-2.5 lg:pr-8">
				<h1 className="mb-5 text-3xl font-bold xl:text-4xl">{title}</h1>
				{contentTop}
				<ImageSlider
					className="relative mb-7 overflow-visible sm:hidden"
					imagesArr={sources}
				/>
				{contentBottom}
			</div>
			<div className="hidden w-full sm:block lg:w-8/12 lg:pl-2.5 lg:pr-2.5">
				<GalleryGrid imagesArr={sources} />
			</div>
		</div>
	);
};

export const SingleHeroSkeleton: React.FC<{
	className?: string;
}> = ({ className = '' }) => {
	return (
		<div
			className={`${className} flex animate-pulse flex-wrap items-start lg:-mx-2.5`}
		>
			<div className="w-full pb-6 lg:w-4/12 lg:pl-2.5 lg:pr-8">
				<SkeletonText
					rows={3}
					style={{ lineHeight: '1.1' }}
					className="mb-5 font-title text-3xl font-bold xl:text-4xl"
				/>
				<div className="mb-5 flex items-center justify-between">
					<SkeletonText className="w-7/12" />
				</div>
				<ImageSlider className="relative mb-7 overflow-visible sm:hidden" />
				<div className="items-start sm:hidden lg:block">
					<SkeletonText rows={7} className="sm:my-4" />
					<PersonSkeleton className="order-first mt-5 flex-none items-center pr-10" />
				</div>
			</div>
			<div className="hidden w-full sm:block lg:w-8/12 lg:pl-2.5 lg:pr-2.5">
				<GalleryGrid />
			</div>
			<div className="mt-6 hidden w-full items-start sm:flex lg:hidden">
				<PersonSkeleton className="mt-3 w-3/8 flex-none items-center pr-2.5" />
				<SkeletonText rows={7} className="w-5/8" />
			</div>
		</div>
	);
};
