import { SvgPhoto } from 'litto-lib/assets';
import { Button, CloudinaryImage } from 'litto-lib/components/common';
import { isEmpty } from 'litto-lib/utils';
import React from 'react';

import { ScrollGallery } from 'components/shared';

export type GalleryArrayItem = {
	id: any;
	path: string;
	alt: string;
};

export interface IGalleryGridProps {
	imagesArr?: GalleryArrayItem[];
	className?: string;

	// @todo stavit skeleton boolean i imagesArr da je optional samo kad je skeleton true
}

export const GalleryGrid: React.FC<IGalleryGridProps> = ({
	className = '',
	imagesArr
}) => {
	const [isScrollGalleryOpened, setIsScrollGalleryOpened] =
		React.useState(false);
	const [pathToScrollTo, setPathToScrollTo] = React.useState('');
	if (imagesArr) {
		const jumpToImage = (path: string) => {
			setIsScrollGalleryOpened(true);
			setPathToScrollTo(path);
		};

		return (
			<>
				<div className={`${className} aspect-ratio`}>
					<div className={`flex w-auto overflow-hidden rounded`}>
						{imagesArr.length > 2 && (
							<div className="flex w-3/8 flex-col justify-between">
								<button
									className="transiton-filter hover:darken bg-pulse relative mb-2.5 flex h-1/2"
									onClick={() => {
										jumpToImage(imagesArr[1]?.path);
									}}
								>
									<CloudinaryImage
										layout="fill"
										objectFit="cover"
										src={`${imagesArr[1]?.path}`}
										alt={imagesArr[1]?.alt}
										sizes="(min-width: 1024px) 25vw, (min-width: 550px) 33vw, 100vw"
									/>
								</button>
								<button
									className="hover:darken bg-pulse relative h-1/2 transition-all"
									onClick={() => {
										jumpToImage(imagesArr[2]?.path);
									}}
								>
									<CloudinaryImage
										layout="fill"
										objectFit="cover"
										src={`${imagesArr[2]?.path}`}
										alt={imagesArr[2]?.alt}
										sizes="(min-width: 1024px) 25vw, (min-width: 550px) 33vw, 100vw"
									/>
								</button>
							</div>
						)}

						{!isEmpty(imagesArr) && (
							<div
								className={`flex ${
									imagesArr.length > 2 ? 'ml-2.5 w-5/8' : 'w-full'
								}`}
							>
								<button
									onClick={() => {
										jumpToImage(imagesArr[0]?.path);
									}}
									className="transiton-filter hover:darken bg-pulse relative w-full"
								>
									<CloudinaryImage
										// loading="eager"
										layout="fill"
										objectFit="cover"
										src={`${imagesArr[0]?.path}`}
										alt={imagesArr[0].alt}
										sizes="(min-width: 1200px) 50vw, (min-width: 1024px) 40vw, (min-width: 550px) 66vw, 100vw"
									/>
								</button>
							</div>
						)}
						{imagesArr.length > 1 && (
							<Button
								text={`Show all ${imagesArr.length} photos`}
								size="sm"
								iconLeft={<SvgPhoto className="h-2.5 w-2.5" />}
								color="brand"
								className="absolute bottom-6 right-5"
								onClick={() => {
									setIsScrollGalleryOpened(true);
								}}
							/>
						)}
					</div>
				</div>
				<ScrollGallery
					imagesArr={imagesArr}
					{...{
						isScrollGalleryOpened,
						setIsScrollGalleryOpened,
						pathToScrollTo,
						setPathToScrollTo
					}}
				/>
			</>
		);
	}

	return (
		<div className={`${className} aspect-ratio`}>
			<div className={`flex w-auto overflow-hidden rounded`}>
				<div className="flex w-3/8 flex-col justify-between">
					<div className="relative mb-2.5 flex h-1/2 bg-gray-300" />
					<div className="relative h-1/2 bg-gray-300" />
				</div>
				<div className="ml-2.5 flex w-5/8">
					<div className="relative w-full bg-gray-300" />
				</div>
			</div>
		</div>
	);
};
