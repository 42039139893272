import { getNonZeroQueries } from 'litto-lib/utils';

export const formatCheckOutDate = (
	startDate: moment.Moment,
	endDate: moment.Moment
) => {
	const isSameMonth = startDate.month() === endDate.month();
	return isSameMonth
		? `${startDate.format('MMM D')} - ${endDate.format('D')}`
		: `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`;
};

export const formatGuests = (
	adults: number | string,
	children: number | string,
	infants?: number | string
) => {
	return `${Number(adults) + Number(children)} guests${
		infants ? ` + ${infants}` : ''
	} `;
};

export const formatMoreFilters = (otherFilters: any) => {
	const nonZeroFilters: any = getNonZeroQueries(otherFilters);

	if ('geolocation' in nonZeroFilters) {
		delete nonZeroFilters.geolocation;
	}
	const values = Object.values(nonZeroFilters);
	const numberOfFilters = values.reduce(
		(accumulator: number, currentValue: any) => {
			if (typeof currentValue === 'string') {
				return accumulator + currentValue.split(',').length;
			}
			return accumulator + 1;
		},
		0
	);
	return numberOfFilters ? `More Filters ${numberOfFilters}` : 'More Filters';
};

export const channelCodeToName = (code: string) =>
	(code = code.charAt(3) + code.slice(4).toLowerCase());
