import { SvgCheckIcon } from 'litto-lib/assets';
import React, { useState } from 'react';

import { useCustomCheckboxGroupContext } from '.';

export interface ICustomCheckboxProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	name: string;
	labelClass?: string;
	value?: any;
	error?: string;
	label?: string;
	isInline?: boolean;
	variant?: 'checkbox' | 'toggler' | 'button';
}

export const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
	value,
	label,
	name,
	className = '',
	labelClass = '',
	error,
	onChange,
	isInline,
	variant = 'checkbox',
	...rest
}) => {
	const [singleChecked, setSingleChecked] = useState<boolean>(value || false);

	const [state, onGroupChange] = useCustomCheckboxGroupContext();
	const isChecked = state
		? state.some((item: any) => item === value)
		: singleChecked;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSingleChecked(e.target.checked);
		onChange && onChange(e);
		if (onGroupChange) {
			onGroupChange(value);
		}
	};

	return (
		<div
			className={`
			toggler
			toggler--input-checkbox
			toggler--variant-${variant}
			${isInline ? 'toggler--display-inline' : ''}
		`}
		>
			<input
				id={name}
				type="checkbox"
				name={name}
				value={isChecked}
				checked={isChecked}
				onChange={handleOnChange}
				{...rest}
			/>
			<label className={`${className} toggler__element`} htmlFor={name}>
				<span className={`toggler__icon`}>
					<SvgCheckIcon />
				</span>
				<span className={`${labelClass} toggler__label`}>{label}</span>
			</label>
			{error && <CheckboxFeedback error={error} />}
		</div>
	);
};

const CheckboxFeedback = ({ error }: { error: string }) =>
	error ? <p className="text-red mt-3 text-sm font-bold">{error}</p> : null;
