import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

export const Pagination: React.FC<ReactPaginateProps> = ({
	className,
	...props
}) => {
	if (props.pageCount === 0 || props.pageCount === 1) {
		return null;
	}
	return (
		<div className={className}>
			<ReactPaginate
				nextLabel={null}
				previousLabel={null}
				initialPage={1}
				containerClassName="flex list-none"
				pageLinkClassName="flex mx-1 transition-color text-gray-300 items-center border-2 border-transparent rounded-full hover:border-gray-300 h-10 px-5 md:px-7 text-sm outline-none font-bold"
				activeLinkClassName="text-primary-500 border-primary-500"
				breakLinkClassName="flex mx-1 transition-color items-center text-gray-300 border-2 border-transparent rounded-full hover:border-gray-300 h-10 px-5 md:px-7 pb-2 pt-1 text-sm outline-none font-bold"
				{...props}
			/>
		</div>
	);
};
