import { Modal } from 'litto-lib/components/common';
import { newLinesToBr } from 'litto-lib/utils';
import React from 'react';

export const ReadMore = ({
	limit,
	htmlContent
}: {
	limit: number;
	htmlContent: string;
}) => {
	const [modalMoreOpen, setModalMoreOpen] = React.useState(false);
	const trimRef = React.useRef<HTMLDivElement>(null);

	let removeCount = 0;
	let originalLength = 0;
	let elRemoveCount = 0;

	React.useEffect(() => {
		if (trimRef.current?.innerText) {
			originalLength = trimRef.current.innerText.length;

			const allNodes = trimRef.current?.querySelectorAll('*');

			let loopLimit = 0;

			while (trimRef.current.innerText.length > limit && loopLimit < 20) {
				loopLimit++;
				const toRemove = originalLength - removeCount - limit;
				const lastNode = allNodes?.[allNodes.length - 1 - elRemoveCount];
				const lastNodeLength = lastNode?.textContent?.length || 0;
				const canCutInElement = lastNodeLength > toRemove;

				if (canCutInElement) {
					lastNode.innerHTML = newLinesToBr(
						lastNode?.textContent?.slice(0, lastNodeLength - toRemove) + ' ...'
					);
					return;
				}

				removeCount += lastNode?.textContent?.length || 0;
				elRemoveCount++;
				lastNode?.parentElement?.removeChild(lastNode);
			}
		}
	}, []);

	return (
		<>
			<div className="relative">
				<div
					className="overflow-hidden"
					ref={trimRef}
					dangerouslySetInnerHTML={{
						__html: htmlContent
					}}
				/>
				{htmlContent.length > limit && (
					<div className="absolute top-auto inset-0 h-20 bg-gradient-to-t from-white to-transparent" />
				)}
			</div>
			{htmlContent.length > limit && (
				<>
					<button
						className="mt-6  inline border-none bg-transparent text-md font-bold text-primary-500 underline outline-none hover:text-primary-700 focus:text-primary-700"
						onClick={() => {
							setModalMoreOpen(true);
						}}
					>
						See more
					</button>
				</>
			)}
			<Modal
				isOpen={modalMoreOpen}
				onRequestClose={() => {
					setModalMoreOpen(false);
				}}
				hideFooter
			>
				{!!htmlContent && (
					<div
						className="prose-lg prose"
						dangerouslySetInnerHTML={{
							__html: newLinesToBr(htmlContent)
						}}
					/>
				)}
			</Modal>
		</>
	);
};
