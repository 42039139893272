import { createAction } from './actions/actions';
import { IOrderState } from '../context/OrderContext';

export enum OrderActions {
	OrderStateInitialize = '[OrderContext] Order State Initalize',
	OrderStateUpadateOrderKey = '[OrderContext] Order State Update Order Key'
}

export const orderReducer = (state: IOrderState, action: OrderActionTypes) => {
	switch (action.type) {
		case OrderActions.OrderStateInitialize: {
			return { ...state, orderKey: action.payload.orderKey };
		}
		case OrderActions.OrderStateUpadateOrderKey: {
			return { ...state, orderKey: action.payload };
		}
		default: {
			throw new Error('OrderReducer: This reducer action does not exist');
		}
	}
};

export type OrderActionTypes =
	| IOrderStateInitializeAction
	| IOrderStateUpdateOrderKeyAction;

interface IOrderStateInitializeAction {
	type: typeof OrderActions.OrderStateInitialize;
	payload: IOrderState;
}
export const orderStateInitializeAction = createAction<
	IOrderStateInitializeAction['payload']
>(OrderActions.OrderStateInitialize);

interface IOrderStateUpdateOrderKeyAction {
	type: typeof OrderActions.OrderStateUpadateOrderKey;
	payload: string;
}
export const orderStateUpdateOrderKeyAction = createAction<
	IOrderStateUpdateOrderKeyAction['payload']
>(OrderActions.OrderStateUpadateOrderKey);
