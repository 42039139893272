import React from 'react';
import { OrientationShape } from 'react-dates';

import { Button } from 'litto-lib/components/common';

import { SvgArrowIcon } from 'litto-lib/assets';
import styles from './/CustomCalendarNavNext.module.scss';

export interface ICustomCalendarNavNextProps {
	orientation?: OrientationShape;
}

export const CustomCalendarNavNext: React.FC<
	ICustomCalendarNavNextProps
> = () => {
	return (
		<>
			<Button color="dark" size="sm" className={styles.loadMore}>
				Load more
			</Button>
			<button
				className={`${styles.arrow} hidden h-12 w-12 items-center justify-center rounded-full bg-gray-100 transition hover:bg-gray-100  md:flex md:bg-transparent`}
			>
				<SvgArrowIcon className="svg-no-prefill" />
			</button>
		</>
	);
};
