/* eslint-disable no-nested-ternary */
import {
	CloudinaryImage,
	ICloudinaryImageProps
} from 'litto-lib/components/common';
import Link, { LinkProps } from 'next/link';
import React from 'react';

type IOverlayedCardProps = {
	imgSrc: string;
	title?: string | JSX.Element;
	meta?: string;
	slotBottom?: JSX.Element;
	aspectRatio?: IOverlayedCardAspectRatio;
	className?: string;
	href?: string;
	as?: string;
	onClick?: () => void;
} & Omit<LinkProps, 'href'> &
	Pick<ICloudinaryImageProps, 'cloudinaryOptions' | 'sizes'>;

type IOverlayedCardAspectRatio = '8-7' | '3-1';

const aspectClass = {
	'8-7': 'aspect-w-8 aspect-h-7',
	'3-1': 'aspect-w-1 aspect-h-1 md:aspect-w-3 md:aspect-h-1 '
};

export const OverlayedCard: React.FC<IOverlayedCardProps> = ({
	imgSrc,
	title,
	meta,
	slotBottom,
	className = '',
	href,
	as,
	onClick,
	aspectRatio = '8-7',
	// CloudinaryImage
	cloudinaryOptions,
	sizes
}) => {
	const wrapperClasses = React.useMemo(() => {
		return `
		${className}
		${aspectRatio == '3-1' ? 'sm:rounded-lg' : 'rounded-lg'}
		overflow-hidden block no-underline group
	`;
	}, [className, aspectRatio]);

	const content = React.useMemo(() => {
		return (
			<div className={`${aspectClass[aspectRatio]}`}>
				<CloudinaryImage
					className="group-hover:darken"
					src={imgSrc}
					layout="fill"
					objectFit="cover"
					objectPosition="center"
					alt={typeof title === 'string' ? title : ''}
					cloudinaryOptions={cloudinaryOptions}
					sizes={sizes}
				/>
				<div
					className={`
	flex flex-col items-center justify-center
	${aspectRatio == '3-1' ? 'p-5 md:p-24' : 'p-5'}`}
				>
					{typeof title == 'string' ? (
						<h3 className="text-center text-4xl text-white lg:text-5xl">
							{title}
						</h3>
					) : (
						title
					)}
					{meta && (
						<p className="text-center text-lg font-bold text-white">{meta}</p>
					)}
					{slotBottom && slotBottom}
				</div>
			</div>
		);
	}, [aspectRatio, imgSrc, meta, slotBottom, title]);

	return href ? (
		<Link as={as} href={href}>
			<a {...(onClick ? { onClick: onClick } : {})} className={wrapperClasses}>
				{content}
			</a>
		</Link>
	) : onClick ? (
		<button onClick={onClick} className={wrapperClasses}>
			{content}
		</button>
	) : (
		<div className={wrapperClasses}>{content}</div>
	);
};
