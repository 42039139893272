/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useToast } from 'litto-lib/hooks';

const baseUrl: string = process.env.API_URL_BASE || '';
const TIMEOUT = 60 * 1000;

export const axiosFunctions = axios.create();

export const configAxios = () => {
	axios.defaults.baseURL = baseUrl;
	axios.defaults.timeout = TIMEOUT;
	setupIntercepotrs();
};

export const setupIntercepotrs = () => {
	axios.defaults.params = {};
	axios.interceptors.request.use(onRequestFulfilled);
	axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

const onRequestFulfilled = (
	config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
	if (!config.headers) {
		config.headers = {};
	}
	config.headers['Content-Type'] = 'application/ld+json';
	if (config.method === 'patch') {
		config.headers['Content-Type'] = 'application/merge-patch+json';
	}
	return Promise.resolve(config);
};

const onResponseSuccess = (response: AxiosResponse<any>) => {
	// console.log('Interceptor response', response);
	return Promise.resolve(response);
};

const onResponseError = (responseError: AxiosError<any>) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const toast = useToast();
	const { response, config } = responseError;
	const data = response?.data;
	const status = response?.status;

	const isAddItems = /\/orders\/.*\/items/g.test(String(config?.url));
	const isTaxons = /\/taxons/g.test(String(config?.url));
	if ((status === 404 && isAddItems) || isTaxons) {
		// Ignore 404 on addItems this can happen if orderKey is expired (eg. completed or expirs)
		// in this case create new order will happen
		return Promise.reject(responseError);
	}

	if (data?.['violations']) {
		toast({
			headerText: data['hydra:title'] || '',
			message: data['hydra:description'] || '',
			type: 'error'
		});
		return Promise.reject(responseError);
	}

	if (data?.['message']) {
		toast({
			headerText: data['code'] || '',
			message: data['message'] || '',
			type: 'error'
		});
		return Promise.reject(responseError);
	}

	if (status === 500) {
		toast({
			headerText: data['code'] || '',
			message: data['message'] || '',
			type: 'error'
		});
		return Promise.reject(responseError);
	}
	return Promise.reject(responseError);
};
