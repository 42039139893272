import { Button, CustomCheckbox, Modal } from 'litto-lib/components/common';
import { useScrollLock } from 'litto-lib/hooks';
import Image from 'next/image';
import React from 'react';
import { useCookies } from 'react-cookie';

import {
	acceptAll,
	cookieLevelType,
	hideBanner,
	showBanner,
	toggleBannerExpanded,
	toggleGroup,
	useCookiesDispatchContext,
	useCookiesStateContext
} from '.';

const html = `<div id="primary" class="content-area">
<main id="main" class="site-main" role="main">

<article id="post-7" class="post-7 page type-page status-publish hentry entry">

<header class="entry-header alignwide">

<div class="entry-content">




<p>By taking care of our customers, we believe that we have a chance to provide high-quality service, which is a key to a pleasant journey. This is where we take the first step – take a few minutes and check out our new privacy policy below.&nbsp;</p>



<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information in pursuance of the General EU Data Protection Regulation 2016/679 / EC (hereinafter: the Regulation) and the Act Implementing the General Data Protection Regulation NN 42/2018.</p>



<p>When You register via our Website in order to search, browse data, publish or book holiday accommodation, we need to collect some personal information about You, such as first name, last name, phone number, postal address, email address, date of birth, and profile photo, some of which will depend on the features you use. This information is used, for example, to process your booking and travel or hotel arrangements. Some of this information is shared with our trusted partners such as travel agencies, accommodation providers, hotels, tour operators and airports to ensure that You arrive safely at Your destination.</p>



<p>Inquires and accommodation reservations can be made on the Website or via email, contact number, in writing or in person at any of the Agency’s offices as well as at the offices of our partner travel agencies. By confirming a reservation, You confirm that You are aware of the General Terms and conditions of the travel services and fully comprehend and accept these terms which are binding for both You as a traveller and us as the Agency.</p>



<p>We use Your Personal data to provide and improve Our website. By using the Litto website, You agree to the collection and use of information in accordance with this Privacy Policy.</p>



<h2 class="wp-block-heading"><strong>Interpretation</strong></h2>



<p>The following definitions shall have the same meaning regardless of whether they appear in singular or in the plural.</p>



<h2 class="wp-block-heading"><strong>Definitions</strong></h2>



<p>For the purposes of this Privacy Policy:</p>



<ul>
<li>Account means a unique account created for You to access our Website or its parts.</li>



<li>Company (referred to as either “the Company”, “Litto Agency”, “We”, “Us” or “Our” in this Agreement) refers to Litto d.o.o., Lovački put 7, 21000 Split.</li>



<li>Contractor (referred to as either “the Traveler”, “User” or “You” in this Agreement).</li>



<li>Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>



<li>Country refers to: Croatia</li>



<li>Device means any device that can access the Service such as a computer, a cell phone or a digital tablet.</li>



<li>Personal Data is any information that relates to an identified or identifiable individual.</li>



<li>Service refers to the Website.</li>



<li>Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>



<li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>



<li>Website refers to Litto, accessible from https://www.litto.co/</li>



<li>”You” means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
</ul>



<h1 class="wp-block-heading"><strong>Collecting and Using Your Personal Data</strong></h1>



<h2 class="wp-block-heading"><strong>Types of Data Collected</strong></h2>



<h3 class="wp-block-heading"><strong>Personal Data</strong></h3>



<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>



<ul>
<li>Email address</li>



<li>First name and last name</li>



<li>Date of birth</li>



<li>Phone number</li>



<li>Address, State, Province, ZIP/Postal code, City</li>



<li>Usage Data</li>



<li>Payment details</li>
</ul>



<h3 class="wp-block-heading"><strong>Usage Data</strong></h3>



<p>Usage Data is collected automatically while using the Website with no need for registration.</p>



<p>Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Website that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.&nbsp;</p>



<p>When You access the Website by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>



<p>We may also collect information that Your browser sends whenever You visit our Website or when You access the Website by or through a mobile device.</p>



<h3 class="wp-block-heading"><strong>Tracking Technologies and Cookies</strong></h3>



<p>We use Cookies and similar tracking technologies to track the activity on Our Website and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Website. More about cookies and other technologies you may find in our <a href="https://litto-prod.vercel.app/static/cookie-policy">Cookie Policy</a>.</p>



<h2 class="wp-block-heading"><strong>Use of Your Personal Data</strong></h2>



<p>The Company may use Personal Data for the following purposes:</p>



<p>The Company may use Personal Data for the following purposes:</p>



<ul>
<li>To provide and maintain our Website, including to monitor the usage of our Website.</li>



<li>To manage Your Account: to manage Your registration as a user of the Website. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>



<li>For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Website.</li>



<li>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>



<li>To provide You with news, special offers and general information about other goods, services and events which we offer that are </li>
</ul>



<ul>
<li>similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>



<li>To manage Your requests: To attend and manage Your requests to Us.</li>



<li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>



<li>For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Website, products, services, marketing and your experience.</li>
</ul>



<p>We may share Your personal information in the following situations:</p>



<ul>
<li>With Website Providers: We may share Your personal information with Website Providers to monitor and analyze the use of our Website, to contact You.</li>



<li>For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>



<li>With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>



<li>With business partners: We may share Your information with Our business partners (intermediaries, travel agencies, tour operators, etc.) to offer You certain products, services or promotions.</li>



<li>With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>



<li>With Your consent: We may disclose Your personal information for any other purpose with Your consent.</li>
</ul>



<h2 class="wp-block-heading"><strong>Retention of Your Personal Data</strong></h2>



<p>The Agency will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>



<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Website, or We are legally obligated to retain this data for longer time periods.</p>



<h3 class="wp-block-heading"><strong>Transfer of Your Personal Data</strong></h3>



<p>Your information, including Personal Data, is processed at the Agency’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.</p>



<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>



<p>The Agency will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>



<h2 class="wp-block-heading"><strong>Disclosure of Your Personal Data</strong></h2>



<h3 class="wp-block-heading"><strong>Business Transactions</strong></h3>



<p>If the Agency is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>



<h3 class="wp-block-heading"><strong>Law enforcement</strong></h3>



<p>Under certain circumstances, the Agency may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>



<h2 class="wp-block-heading"><strong>Other legal requirements</strong></h2>



<p>The Agency may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>



<ul>
<li>Comply with a legal obligation</li>



<li>Protect and defend the rights or property of the Company</li>



<li>Prevent or investigate possible wrongdoing in connection with the Service</li>



<li>Protect the personal safety of Users of the Service or the public</li>



<li>Protect against legal liability</li>
</ul>



<h2 class="wp-block-heading">Security of Your Personal Data</h2>



<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>



<h2 class="wp-block-heading">Children’s Privacy</h2>



<p>Our Website does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>



<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.</p>



<h2 class="wp-block-heading">Links to Other Websites</h2>



<p>Our Website may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.</p>



<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>



<h2 class="wp-block-heading">Changes to this Privacy Policy</h2>



<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>



<p>We will let You know via email and/or a prominent notice on Our Website, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.</p>



<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>



<h1 class="wp-block-heading"><strong>Your rights</strong></h1>



<p>We provide you with the conditions to decide on the processing of your data. First of all, we grant You the following rights:</p>



<h3 class="wp-block-heading"><strong>The right to access data</strong></h3>



<p>You have the right to request confirmation that we are processing Your data and if so, to gain access to that data. If we process a large amount of data relating to You, we may ask You to specify Your request to provide certain specific groups of data that we process about You.</p>



<h3 class="wp-block-heading"><strong>Right to correction</strong></h3>



<p>If You notice that we are processing inaccurate or incomplete data about You or You want to change them, You have the right to request the correction of the same or the addition of incomplete personal data. To ensure that we continuously process only accurate personal data about You, You need to notify Us without delay of any changes to them.</p>



<h3 class="wp-block-heading"><strong>Right to erase (“right to forget”)</strong></h3>



<p>Deleting Your personal data may be requested, for example, if You have withdrawn Your consent to the processing of Your data, when Your data is illegally processed or when Your information is no longer necessary for the purposes for which it was collected or processed in any way. However, please note that We will not be able to delete Your data if necessary to fulfil legal obligations, contractual obligations and other legal bases from the General Data Protection Regulation.</p>



<h3 class="wp-block-heading"><strong>The right to limit processing</strong></h3>



<p>You have the right to restrict the processing of Your personal data, which may be required, for example, if You have objected to the processing of data, doubt the accuracy of personal data processed or the legality of their processing, do not want this data deleted, or still need in order to meet legal requirements.</p>



<h3 class="wp-block-heading"><strong>The right to data portability</strong></h3>



<p>Suppose the processing is based on Your consent or is performed for the purpose of executing a contract concluded with You, and at the same time, is performed by automated means. In that case, You have the right to receive the personal data that we have received from You. If You request, we will transfer Your personal data directly to another controller, if technically feasible.</p>



<h3 class="wp-block-heading"><strong>The right to object</strong></h3>



<p>At any time, given Your particular situation, You are authorized to object to the processing of personal data relating to You, which is why we will limit their processing. We will also delete the above data and stop processing it unless we prove reasonable and justified legal grounds for retaining it. In addition, You have the right to object at any time to the processing of Your personal data for the purpose of direct marketing. After submitting a complaint, Your data will cease to be processed for the stated purpose.</p>



<h3 class="wp-block-heading"><strong>The right to complain to the supervisory authority</strong></h3>



<p>If you consider that the processing of Your personal data is contrary to the General Data Protection Regulation, You have the right to lodge a complaint with the competent supervisory authority.&nbsp;</p>



<h3 class="wp-block-heading"><strong>The right to withdraw consent</strong></h3>



<p>If the processing of your personal data is based on consent, you have the right to withdraw it at any time, without any consequences. You can request the fulfilment of the stated rights:</p>



<p>By registered letter (marked “Data protection”) or</p>



<p>By personal arrival at the address on weekdays 10:00 – 14:00 at Litto d.o.o., Lovački put 7, 21000 SPLIT.</p>



<p>You can obtain more information on fulfilling your rights electronically by email: gdpr@litto.co.</p>



<p>Please note that when enclosing a complaint, it is necessary to identify Yourself.</p>



<p>If you are sending us a request by letter, please enclose a legible copy of a valid ID card and, if necessary, a proper power of attorney.</p>



<p>If you are coming in person, please bring your ID card and, if necessary, a valid power of attorney.</p>



<p>Identification is required to protect you as the owner of your personal information.</p>



<h2 class="wp-block-heading"><strong>Contact Us</strong></h2>



<p>If you have any questions about this Privacy Policy, You can contact us:</p>



<ul>
<li>By email: gdpr@litto.co</li>
</ul>
</div><!-- .entry-content -->

</article><!-- #post-7 -->
</main><!-- #main -->
</div>`;

export const CookieConsent = () => {
	const cookieDispatch = useCookiesDispatchContext();
	const cookieState = useCookiesStateContext();
	const [privacyModalActive, setPrivacyModalActive] = React.useState(false);
	const [cookie, setCookie] = useCookies([
		'COOKIE_CONSENT',
		'COOKIE_CONSENT_NECESSARY',
		'COOKIE_CONSENT_ANALYTICS',
		'COOKIE_CONSENT_MARKETING'
	]);

	const { isShown } = useCookiesStateContext();

	const addAllCookies = () => {
		setCookie('COOKIE_CONSENT', 'accepted', { path: '/' });
		setCookie('COOKIE_CONSENT_NECESSARY', 'true', { path: '/' });
		setCookie('COOKIE_CONSENT_ANALYTICS', 'true', { path: '/' });
		setCookie('COOKIE_CONSENT_MARKETING', 'true', { path: '/' });
	};
	const addCookies = () => {
		setCookie('COOKIE_CONSENT', 'accepted', { path: '/' });
		Object.keys(cookieState.levels).map((c: any) =>
			setCookie(
				`COOKIE_CONSENT_${c.toUpperCase()}` as any,
				(cookieState as any).levels[c],
				{ path: '/' }
			)
		);
	};

	const options = [
		{
			label: 'Necessary cookies',
			name: 'necessary',
			description: `Necessary cookies enable core website features and they can only be disabled by changing your browser preferences.`,
			disabled: true
		},
		{
			label: 'Analytics cookies',
			name: 'analytics',
			description: `Analytics cookies help us to improve our website by collecting and reporting information on its usage.`
		},
		{
			label: 'Marketing cookies',
			name: 'marketing',
			description: `We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.`
		}
	];

	React.useEffect(() => {
		if (Object.keys(cookie).length != 0) {
			cookieDispatch(hideBanner());
		} else {
			cookieDispatch(showBanner());
		}
		if (cookie.COOKIE_CONSENT_ANALYTICS)
			cookieDispatch(toggleGroup('analytics'));
		else if (cookie.COOKIE_CONSENT_MARKETING)
			cookieDispatch(toggleGroup('marketing'));
	}, []);

	const cookieRef = React.useRef<HTMLDivElement>(null);
	useScrollLock<HTMLDivElement | unknown>(isShown, cookieRef);

	return (
		<>
			{cookieState.isShown && (
				<>
					<div
						className={`fixed inset-0 bg-black bg-opacity-50 ${
							isShown && 'backdrop-blur-sm backdrop-filter'
						}`}
						style={{ zIndex: 40 }}
						ref={cookieRef}
					/>
					<div
						style={{ zIndex: 40 }}
						className={`fixed rounded-lg md:max-w-[90vw] lg:max-w-screen-lg ${
							cookieState.isExpanded ? 'top-[50px] md:top-auto' : 'top-auto'
						} shadow-md bottom-0 right-0 left-0 z-50 overflow-y-auto bg-gray-100 p-4 sm:p-6 md:bottom-10 md:right-10 md:left-10 md:p-8 lg:left-auto lg:p-15`}
					>
						<div className="items-center justify-between lg:flex">
							<div className="flex flex-col lg:flex-row">
								<div className="flex w-full flex-col items-center justify-center pb-4 md:pb-0 lg:w-1/5">
									<Image
										src="/assets/images/domacica.png"
										alt="cookie-domacica"
										width="128px"
										height="78px"
									/>
								</div>
								<div className="mx-0 w-full md:mx-8 md:w-4/5">
									<p className="pb-4 text-sm">
										Cookies are essential if you want to enjoy the complete
										website experience with any hiccups.
										<button
											onClick={() => setPrivacyModalActive(true)}
											className="px-1 text-sm underline"
										>
											Click here
										</button>
										to read how we manage cookies or simply accept the terms to
										enjoy all the creative features we developed.
									</p>

									<button
										className="text-sm font-bold underline"
										onClick={() => cookieDispatch(toggleBannerExpanded())}
									>
										{cookieState.isExpanded
											? 'Close settings'
											: 'Change settings'}
									</button>
								</div>
							</div>
							<div className="mt-6 hidden w-full items-center justify-center md:flex lg:mt-0 lg:w-2/6 lg:flex-col">
								<Button
									size="sm"
									text="Accept all"
									onClick={() => {
										cookieDispatch(acceptAll());
										addAllCookies();
										cookieDispatch(hideBanner());
										cookieDispatch(toggleBannerExpanded());
									}}
								></Button>
								{cookieState.isExpanded && (
									<button
										className="ml-4 p-3 text-xs font-bold text-gray-500 underline md:ml-0 "
										onClick={() => {
											addCookies();
											cookieDispatch(hideBanner());
											cookieDispatch(toggleBannerExpanded());
										}}
									>
										Save Settings
									</button>
								)}
							</div>
						</div>
						<div
							className={`transition-all duration-300 ${
								cookieState.isExpanded ? 'h-auto' : 'h-0 overflow-hidden'
							} `}
						>
							<div className="flex flex-wrap items-center">
								<ul className="mt-10 list-none md:-mx-8 lg:flex">
									{options.map(
										({ label, name, description, disabled = false }) => (
											<li key={name} className="flex-1 md:mx-8">
												<CustomCheckbox
													variant="toggler"
													className="mb-4"
													{...((!disabled && {
														onChange: () => {
															cookieDispatch(toggleGroup(name as any));
															addCookies();
														}
													}) || { disabled: true })}
													name={name}
													value={cookieState.levels[name as cookieLevelType]}
												/>

												<p className="block pb-2 text-md font-bold group-hover:opacity-75">
													{label}
												</p>
												<p className="text-gray-750 mb-6 text-sm group-hover:opacity-75">
													{description}
												</p>
											</li>
										)
									)}
								</ul>
							</div>
						</div>
						<div className="mt-6 flex w-full items-center md:hidden lg:mt-0 lg:w-2/6 lg:flex-col">
							<Button
								size="sm"
								text="Accept all"
								onClick={() => {
									cookieDispatch(acceptAll());
									addAllCookies();
									cookieDispatch(hideBanner());
									cookieDispatch(toggleBannerExpanded());
								}}
							></Button>
							{cookieState.isExpanded && (
								<button
									className="ml-4 p-3 text-xs font-bold text-gray-500 underline md:ml-0 "
									onClick={() => {
										addCookies();
										cookieDispatch(hideBanner());
										cookieDispatch(toggleBannerExpanded());
									}}
								>
									Save Settings
								</button>
							)}
						</div>

						<Modal
							isOpen={privacyModalActive}
							onRequestClose={() => setPrivacyModalActive(false)}
							headerText="Privacy policy"
						>
							<div className="static-page">
								<div
									className={'html-content'}
									dangerouslySetInnerHTML={{
										__html: html
									}}
								/>
							</div>
						</Modal>
					</div>
				</>
			)}
		</>
	);
};
