/* eslint-disable @typescript-eslint/no-empty-function */
import { useReducerWithLogger } from 'litto-lib/hooks';
import React from 'react';
import { getUserState, setUserState } from 'services/auth';
import { ILocationRead } from 'services/shop/interfaces';

import {
	userReducer,
	userStateInitializeAction
} from '../reducers/userReducer';

export const initialUserState: IUserState = {
	selectedLocation: undefined,
	initContext: () => {}
};

export const UserStateContext = React.createContext<IUserState>(
	{ ...getUserState(), initContext: () => {} } || initialUserState
);
export const UserDispatchContext = React.createContext<any>({});

export const UserProvider: React.FC<any> = ({ children, mockState }) => {
	const [state, dispatch] = useReducerWithLogger(
		userReducer,
		initialUserState,
		false && process.env.NODE_ENV === 'development'
	);
	const isFirstRun = React.useRef(true);

	React.useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		setUserState(state);
	}, [state]);

	const initContext = React.useCallback(userDispatch => {
		const storageState = getUserState();
		userDispatch(userStateInitializeAction(storageState));
	}, []);

	return (
		<UserStateContext.Provider value={{ ...state, ...mockState, initContext }}>
			<UserDispatchContext.Provider value={dispatch}>
				{children}
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	);
};

export const useUserStateContext = () => {
	const context = React.useContext(UserStateContext);
	if (context === undefined) {
		throw new Error(
			'useUserStateContext must be used within a StoreStateProvider'
		);
	}
	return context;
};

export const useUserDispatchContext = () => {
	const context = React.useContext(UserDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useUserDispatchContext must be used within a UserDispatchContext'
		);
	}
	return context;
};

export interface IUserState {
	selectedLocation: ILocationRead | undefined;
	initContext: (dispatch: any) => void;
}
