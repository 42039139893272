import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { IOrderCheckout } from './interfaces';

export interface IUpdateOrderAddressData {
	email?: string;
	billingAddress?: IUpdateOrderAddressAddress;
	shippingAddress?: IUpdateOrderAddressAddress;
	couponCode?: string;
}

export interface IUpdateOrderAddressAddress {
	firstName: string;
	lastName: string;
	countryCode: string;
	street: string;
	city: string;
	postcode: string;
	phoneNumber: string;
}

export const updateOrderAddress = async (
	orderKey: string,
	data: IUpdateOrderAddressData
) => {
	return await axios
		.put<IOrderCheckout>(`api/v2/shop/orders/${orderKey}`, data)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating order address:`, error);
			return Promise.reject(error);
		});
};

export function useUpdateOrderAddressMutation(orderKey: string) {
	return useMutation((data: IUpdateOrderAddressData) => {
		return updateOrderAddress(orderKey, data);
	});
}
