import { SvgArrowBack, SvgLogo } from 'litto-lib/assets';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

export const PlainHeader = ({
	hideBackButton = false,
	slotRight
}: {
	hideBackButton?: boolean;
	slotRight?: React.ReactNode;
}) => {
	const router = useRouter();

	return (
		<header className="relative flex h-20 items-center justify-center border-0 border-b border-solid border-gray-100 bg-white">
			{!hideBackButton && (
				<button
					onClick={() => router.back()}
					type="button"
					className={`pin-y focus:bg-gray-100" absolute left-3.5 flex rounded-full p-2.5 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none`}
				>
					<SvgArrowBack className="mr-4" /> <span className="hidden">Back</span>
				</button>
			)}
			<Link href="/">
				<a className="">
					<SvgLogo className="text-primary-500" />
				</a>
			</Link>
			{slotRight && <div className="absolute right-10">{slotRight}</div>}
		</header>
	);
};
