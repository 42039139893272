import { Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import { FieldRange } from 'litto-lib/components/common';
import React from 'react';
import * as Yup from 'yup';

const PriceRangeFormSchema = Yup.object({
	range: Yup.object().required('This field is required.')
});
export interface IPriceRangeFormProps
	extends FormikConfig<IPriceRangeFormData> {
	formRef?: React.Ref<FormikProps<IPriceRangeFormData>> | null;
	onSubmit: (
		formData: IPriceRangeFormData,
		formikHelpers: FormikHelpers<IPriceRangeFormData>
	) => void;
	className?: string;
	minValue?: number;
	maxValue?: number;
	averagePrice?: number;
}
export interface IPriceRangeFormData {
	range: { min: number; max: number };
}

export const PriceRangeForm: React.FC<IPriceRangeFormProps> = ({
	className = '',
	formRef,
	minValue,
	maxValue,
	averagePrice,
	initialValues,
	onSubmit,
	...formikProps
}) => {
	const [maxValueSuffix, setMaxValueSuffix] = React.useState<string>('');

	const onPriceRangeChange = (value: any) => {
		if (value?.max === maxValue) {
			setMaxValueSuffix('+');
		} else {
			setMaxValueSuffix('');
		}
	};
	return (
		<Formik
			innerRef={formRef}
			initialValues={initialValues}
			validationSchema={PriceRangeFormSchema}
			onSubmit={(formikData, formikHelpers) => {
				onSubmit && onSubmit(formikData, formikHelpers);
			}}
			{...formikProps}
		>
			{() => {
				return (
					<>
						{averagePrice && (
							<p className="text-md leading-normal md:w-7/12">
								The average price of accommodation is {averagePrice}
								€.
							</p>
						)}

						<Form className={`${className} mb-6 flex flex-col`}>
							<FieldRange
								name="range"
								minValue={minValue || 5}
								maxValue={maxValue || 1000}
								maxValueSufix={maxValueSuffix}
								onChange={onPriceRangeChange}
							/>
						</Form>
					</>
				);
			}}
		</Formik>
	);
};
