import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryOptionsOmit } from 'litto-lib/types';

import { ILocation } from '../interfaces';
import { IHydraError } from '../interfaces/hydraError';
import { locationKeys } from './queryKeys';

export const getLocation = async (code: string) => {
	return await axios
		.get<ILocation>(`/api/v2/shop/locations/${code}`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting location: ${code}`, error);
			return Promise.reject(error);
		});
};

type UseGetLocationQueryOptions = QueryOptionsOmit<
	UseQueryOptions<ILocation, AxiosError<IHydraError>, ILocation>
>;

export function useGetLocationQuery(
	code: string,
	config?: UseGetLocationQueryOptions
) {
	return useQuery(
		locationKeys.detail(code),
		() => getLocation(code),
		config as any
	);
}
