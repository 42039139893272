/* eslint-disable no-nested-ternary */
import {
	CloudinaryImage,
	ICloudinaryImageProps,
	SkeletonText
} from 'litto-lib/components/common';
import Link, { LinkProps } from 'next/link';
import React from 'react';


export type ICardProps = {
	imgSrc: string;
	title: string;
	meta?: string;
	info?: string | JSX.Element;
	className?: string;
	aspectRatio?: '9x5' | '7x9';
	imageSize?: string;
	href?: string;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	slotBottom?: string | JSX.Element;
	target?: HTMLAnchorElement['target'];
	orientation?: 'vertical' | 'horizontal';
} & Omit<LinkProps, 'href'> &
	Pick<ICloudinaryImageProps, 'cloudinaryOptions' | 'sizes'>;

export const Card: React.FC<ICardProps> = ({
	imgSrc,
	title,
	meta,
	info,
	className,
	href,
	as,
	slotBottom,
	onClick,
	onMouseEnter,
	onMouseLeave,
	target = '_self',
	aspectRatio = '9x5',
	cloudinaryOptions,
	sizes,
	orientation = 'vertical'
}) => {
	const cardClasses = `
				card
				card--orientation-${orientation}
				card--aspect-ratio-${aspectRatio}
				${className}
				`;

	const content = (
		<>
			<picture className="card__image-wrap">
				<CloudinaryImage
					layout="fill"
					objectFit="cover"
					src={imgSrc}
					alt={title}
					cloudinaryOptions={cloudinaryOptions}
					sizes={sizes}
				/>
			</picture>
			<div className="card__content">
				{meta && <p className="card__meta">{meta}</p>}
				<h3 className="card__title">{title}</h3>
				{info && <p className="card__info">{info}</p>}
				<div className="card__slot-bottom">{slotBottom && slotBottom}</div>
			</div>
		</>
	);

	return href ? (
		<Link href={href} as={as} prefetch={false}>
			<a
				className={cardClasses}
				target={target}
				{...(onClick ? { onClick: () => onClick() } : {})}
				onMouseEnter={() => {
					onMouseEnter && onMouseEnter();
				}}
				onMouseLeave={() => {
					onMouseLeave && onMouseLeave();
				}}
			>
				{content}
			</a>
		</Link>
	) : onClick ? (
		<button
			className={cardClasses}
			onClick={() => onClick()}
			onMouseEnter={() => {
				onMouseEnter && onMouseEnter();
			}}
			onMouseLeave={() => {
				onMouseLeave && onMouseLeave();
			}}
		>
			{content}
		</button>
	) : (
		<div
			className={cardClasses}
			onMouseEnter={() => {
				onMouseEnter && onMouseEnter();
			}}
			onMouseLeave={() => {
				onMouseLeave && onMouseLeave();
			}}
		>
			{content}
		</div>
	);
};

export interface ISkeletonCardProps
	extends React.HTMLAttributes<HTMLDivElement> {
	aspectRatio?: '9x5' | '7x9';
	meta?: boolean;
	info?: boolean;
	orientation?: 'horizontal' | 'vertical';
}

export const SkeletonCard: React.FC<ISkeletonCardProps> = ({
	aspectRatio = '9x5',
	className = '',
	meta = true,
	info = true,
	orientation = 'vertical'
}) => {
	const cardClasses = `
				card
				card--orientation-${orientation}
				card--aspect-ratio-${aspectRatio}
				${className}
				animate-pulse
				`;

	return (
		<div className={cardClasses}>
			<div className="card__image-wrap bg-gray-200" />

			<div className="card__content">
				{meta && <SkeletonText rowSize={8} className="card__meta" />}
				<SkeletonText className="card__title0" />
				{info && <SkeletonText rowSize={3} className="card__inf0" />}
			</div>
		</div>
	);
};
