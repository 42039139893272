// not used at the moment, if needed should be refactored to get wp data from the homepage page

import { getImageUrl, range } from 'litto-lib/utils';
import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useContainerQueries } from 'use-container-queries';

import { Card, SkeletonCard } from 'components/shared/Card';

interface IBannerSliderProps {
	bannerContent: any[];
	className?: string;
}

SwiperCore.use([Pagination, Navigation]);

const settings: SwiperProps = {
	spaceBetween: 0,
	slidesPerView: 'auto',
	navigation: true,
	observeParents: true,
	pagination: {
		type: 'bullets',
		clickable: true
	}
};

export const BannerSlider: React.FC<IBannerSliderProps> = ({
	bannerContent,
	className = ''
}) => {
	const { active, ref } = useContainerQueries({
		breakpoints: {
			sm: [0, 1023],
			lg: [1024]
		}
	});

	const shouldShowSlider = React.useMemo(() => {
		return (
			(bannerContent ? bannerContent.length : 4) > (active == 'sm' ? 1 : 4)
		);
	}, [active, bannerContent]);

	const itemClasses = 'w-11/12 xs:w-10/12 sm:w-7/12 md:w-5/12 lg:w-1/4 p-2.5';

	const SwiperContent = ({ item }: { item: any }) =>
		bannerContent ? (
			<Card
				href={`${item.link}`}
				imgSrc={getImageUrl(item.image.url)?.replace(
					String(process.env.WORDPRESS_URL_BASE),
					'/'
				)}
				sizes="(min-width: 1200px) 25vw, (min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw"
				title={item.text}
				aspectRatio={'aspect-w-7 aspect-h-8' as any}
				className="w-full"
			/>
		) : (
			<SkeletonCard
				meta={false}
				info={false}
				className="w-full"
				aspectRatio={'aspect-w-7 aspect-h-8' as any}
			/>
		);

	return (
		<div ref={ref} className={`container overflow-x-hidden pb-12 ${className}`}>
			{shouldShowSlider ? (
				<Swiper
					className={`overflow-visible-arrows-only -mx-2.5`}
					{...settings}
				>
					{(bannerContent || range(0, 4)).map(bannerItem => (
						<SwiperSlide className={itemClasses} key={bannerItem.text}>
							<SwiperContent item={bannerItem} />
						</SwiperSlide>
					))}
				</Swiper>
			) : (
				<div className="-mx-2.5 flex">
					{(bannerContent || range(0, 4)).map(bannerItem => (
						<div key={bannerItem.text} className={itemClasses}>
							<SwiperContent item={bannerItem} />
						</div>
					))}
				</div>
			)}
		</div>
	);
};
