import { useScrollLock } from 'litto-lib/hooks';
import React, { useState } from 'react';
import {
	positionValues,
	ScrollbarProps,
	Scrollbars
} from 'react-custom-scrollbars';

interface IModalCustomScrollbars extends ScrollbarProps {
	hasFooter: boolean;
	noHeader: boolean;
	autoHeightMax?: string;
	autoHeightMin?: string;
	disableShadow?: boolean
}

export const ModalCustomScrollbars: React.FC<IModalCustomScrollbars> = ({
	children,
	autoHeightMin,
	autoHeightMax,
	disableShadow
}) => {
	const ref = React.createRef<Scrollbars>();
	useScrollLock<any | null>(true, ref);

	const [scrolledState, setScrolledState] = useState<
		'start' | 'middle' | 'end' | null
	>(null);

	const handleUpdate = (scrollInfo: positionValues) => {
		if (scrollInfo.scrollHeight == scrollInfo.clientHeight) return;
		setScrolledState(
			scrollInfo.scrollTop + 48 >=
				scrollInfo.scrollHeight - scrollInfo.clientHeight
				? 'end'
				: scrollInfo.scrollTop > 48
				? 'middle'
				: 'start'
		);
	};
	const handleScrollbarsMount = (scrollbars: any) => {
		if (scrollbars) {
			const viewElm = scrollbars.view;
			(ref as any).current = viewElm;
		}
	};

	return (
		<Scrollbars
			className={`
				${scrolledState ? 'md:modal-shadow' : ''} 
				${
					!disableShadow ? (scrolledState == 'end'
					? 'md:modal-shadow-t'
					: scrolledState == 'middle'
					? 'md:modal-shadow-y'
					: 'md:modal-shadow-b') : ''
				}
				`}
			ref={scroll => handleScrollbarsMount(scroll)}
			autoHide
			onUpdate={handleUpdate}
			autoHeight
			hideTracksWhenNotNeeded
			autoHeightMin={autoHeightMin || '20vh'}
			autoHeightMax={autoHeightMax || '80vh'}
			renderTrackVertical={props => (
				<div
					{...props}
					className="track-vertical inset-0 right-0 left-auto z-50 my-4 mr-4 w-1 rounded-lg bg-gray-100"
				/>
			)}
			renderThumbVertical={props => (
				<div {...props} className="thumb-vertical rounded bg-black" />
			)}
			renderView={props => <div {...props} className="view" />}
		>
			{children}
		</Scrollbars>
	);
};
