import { CloudinaryImage } from 'litto-lib/components/common';
import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { ScrollGallery } from 'components/shared';

type GalleryArrayItem = {
	id: any;
	path: string;
	alt: string;
};
interface IImageSliderProps {
	imagesArr?: GalleryArrayItem[];
	className?: string;
}

SwiperCore.use([Pagination]);

const settings: SwiperProps = {
	spaceBetween: 10
};

export const ImageSlider: React.FC<IImageSliderProps> = ({
	className,
	imagesArr
}) => {
	const [isScrollGalleryOpened, setIsScrollGalleryOpened] =
		React.useState(false);
	const [pathToScrollTo, setPathToScrollTo] = React.useState('');

	if (imagesArr) {
		const jumpToImage = (path: string) => {
			setIsScrollGalleryOpened(true);
			setPathToScrollTo(path);
		};

		return (
			<div className={`${className ? className : ''} relative rounded-lg`}>
				<Swiper pagination={{ type: 'fraction' }} {...settings}>
					{imagesArr.map(item => (
						<SwiperSlide key={item?.id}>
							<button
								className="bg-pulse aspect-w-6 aspect-h-5 w-full overflow-hidden rounded-lg border-none bg-transparent outline-none"
								onClick={() => {
									jumpToImage(item.path);
								}}
							>
								<CloudinaryImage
									layout="fill"
									objectFit="cover"
									src={`${item?.path}`}
									alt={item?.alt}
								/>
							</button>
						</SwiperSlide>
					))}
				</Swiper>
				<ScrollGallery
					imagesArr={imagesArr}
					{...{
						isScrollGalleryOpened,
						setIsScrollGalleryOpened,
						pathToScrollTo,
						setPathToScrollTo
					}}
				/>
			</div>
		);
	}
	return (
		<div className={`${className} relative`}>
			<div className="flex">
				<div className="aspect-w-6 aspect-h-5 w-full rounded-lg bg-gray-300" />
				<div className="aspect-w-6 aspect-h-5 ml-2.5 -mr-5 w-4 rounded-l bg-gray-300" />
			</div>
		</div>
	);
};
