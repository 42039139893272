import React, { ReactNode } from 'react';

import { HeaderLink } from '..';

export interface IHeaderStepsProps {
	className: string;
	steps: IHeaderStepsStep[];
}

export interface IHeaderStepsStep {
	label: string;
	icon: ReactNode;
	href: string;
	as?: string;
}

export const HeaderSteps: React.FC<IHeaderStepsProps> = ({
	steps,
	className = ''
}) => {
	return (
		<ul className={`${className} relative flex h-full w-60 list-none sm:w-106`}>
			<div className="absolute left-0 right-0 top-4 h-px translate-y-6 transform border-b border-gray-200 sm:translate-y-3"></div>
			{steps.map(({ label, icon, href, as }, i) => (
				<HeaderLink
					key={label}
					href={href}
					{...(as ? { as } : {})}
					className="pointer-events-none mx-4 w-1/3 md:mx-8"
				>
					<span className="isActive:text-primary-500 flex w-full flex-col items-center text-base text-gray-200">
						<span className="isActive:bg-primary-500 relative block h-6 w-6 rounded-full bg-gray-200 sm:mb-1">
							{icon && icon}
							{(steps.length - 1 == i || i == 0) && (
								<div
									className={`${
										i == 0 ? 'right-full' : 'left-full'
									} absolute top-3 h-1 w-12 bg-white sm:top-2.5  sm:w-16`}
								/>
							)}
						</span>
						<span className="hidden sm:block">{label}</span>
					</span>
				</HeaderLink>
			))}
		</ul>
	);
};
