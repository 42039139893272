import React from 'react';

export const CustomLabel: React.FC<React.HTMLProps<HTMLButtonElement>> = ({
	htmlFor,
	className,
	children
}) => (
	<label
		htmlFor={htmlFor}
		className={`
        ${className || ''} 
        text-md block font-bold
    `}
	>
		{children}
	</label>
);
