import React from 'react';
import { CalendarDayShape } from 'react-dates';

export type CustomDayModifierHandler = (
	day: moment.Moment
) =>
	| { name: 'violets-min-nights'; minimumNights: number }
	| { name: 'checkout-only' }
	| false;

export interface ICustomCalendarDayProps extends CalendarDayShape {
	onlyStartSelected?: boolean;
	setCustomModifier?: CustomDayModifierHandler;
}

export const CustomCalendarDay: React.FC<ICustomCalendarDayProps> = props => {
	const [showTooltip, setShowTooltip] = React.useState(false);

	const { modifiers, day, onlyStartSelected, setCustomModifier } = props;

	const customModifier = day && setCustomModifier && setCustomModifier(day);

	const isStart = modifiers && modifiers.has('selected-start');
	const isEnd = modifiers && modifiers.has('selected-end');
	const isSpan = modifiers && modifiers.has('selected-span');
	const isBlocked = modifiers && modifiers.has('blocked');

	const getDaySizeStyles = React.useMemo(() => {
		if (props.daySize == 40) {
			return {
				style: {
					width: 'min(54px, calc(100% / 7))',
					paddingBottom: 'min(54px, calc(100% / 7))'
				}
			};
		} else {
			return {
				style: {
					width: props.daySize,
					height: props.daySize
				}
			};
		}
	}, [props.daySize]);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (props.day) {
			if (customModifier) {
				setShowTooltip(true);
				setTimeout(() => {
					setShowTooltip(false);
				}, 1000);
				return;
			}
			props.onDayClick && props?.onDayClick(props.day, e as any);
		}
	};

	return (
		<td
			{...getDaySizeStyles}
			className={`
			group relative
			border-white
			transition-all
			${isStart || isEnd || isSpan ? 'bg-gray-100' : ''}
			${onlyStartSelected ? 'rounded-full' : isStart ? 'rounded-l-full' : ''}
			${isEnd ? 'rounded-r-full' : ''}
			`}
		>
			<>
				<hr className="absolute bottom-0 left-0 right-0 z-10 border border-white" />
				<button
					type="button"
					{...(isBlocked || !props.day ? { tabIndex: -1 } : {})}
					onClick={handleClick}
					style={{
						width: 'calc(100% - 2px)',
						height: 'calc(100% - 2px)'
					}}
					className={`
						${customModifier ? customModifier.name : ''}
						${props.modifiers && Array.from(props.modifiers).join(' ')} 
						${isBlocked || !props.day ? 'pointer-events-none' : ''}
						font-title
						text-md hover:border-primary-500
						focus:border-primary-500 active:bg-primary-700 group
						absolute
						inset-0
						z-10
						flex 
						items-center justify-center rounded-full 
						border
						border-transparent
						outline-none
						transition-all
						active:border-none
						active:text-white
						active:opacity-70
						md:text-lg
						${isSpan ? 'bg-gray-100' : ''}
						${
							isStart || isEnd
								? 'bg-primary-500 focus:bg-primary-700 group-hover:bg-primary-700 text-white focus:border-none'
								: ''
						}
					`}
				>
					{customModifier && showTooltip && (
						<span className="z-90 absolute bottom-full left-1/2 -translate-y-2 -translate-x-1/2 rounded border bg-white p-2 text-sm text-black">
							{customModifier.name === 'violets-min-nights' && (
								<>{`${customModifier.minimumNights}-nights minimum`}</>
							)}
							{customModifier.name === 'checkout-only' && <>Checkout Only</>}
						</span>
					)}
					{day?.format('D')}
				</button>
			</>
		</td>
	);
};
