import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
	HydraChannelQueryParam,
	HydraCollectionResponse,
	HydraPaginationQueryParams
} from '../interfaces';
import { IAccommodationRead } from './interfaces';
import { getQueryKeyFromParams } from 'litto-lib/utils';

interface IGetAccommodationsRequestConfig extends AxiosRequestConfig {
	params?: IGetAccommodationsQueryParams;
}

export type IGetAccommodationsQueryParams = HydraChannelQueryParam &
	HydraPaginationQueryParams & {
		type?: string;
		location?: string;
		tripDates?: string;
		amenities?: string;
		priceFrom?: string;
		priceTo?: string;
		geolocation?: string; // Geography area in rectangle shape, formatted as SW_lat,SW_long,NE_lat,NE_long. Same format is used by Google Maps JS API method google.maps.Rectangle.getBounds.toUrlValue.
		'numberOfGuests[between]'?: string;
		'numberOfGuests[gt]'?: string;
		'numberOfGuests[gte]'?: string;
		'numberOfGuests[lt]'?: string;
		'numberOfGuests[lte]'?: string;
		'numberOfInfants[between]'?: string;
		'numberOfInfants[gt]'?: string;
		'numberOfInfants[gte]'?: string;
		'numberOfInfants[lt]'?: string;
		'numberOfInfants[lte]'?: string;
		'numberOfBedrooms[between]'?: string;
		'numberOfBedrooms[gt]'?: string;
		'numberOfBedrooms[gte]'?: string;
		'numberOfBedrooms[lt]'?: string;
		'numberOfBedrooms[lte]'?: string;
		'numberOfBeds[between]'?: string;
		'numberOfBeds[gt]'?: string;
		'numberOfBeds[gte]'?: string;
		'numberOfBeds[lt]'?: string;
		'numberOfBeds[lte]'?: string;
		'numberOfBaths[between]'?: string;
		'numberOfBaths[gt]'?: string;
		'numberOfBaths[gte]'?: string;
		'numberOfBaths[lt]'?: string;
		'numberOfBaths[lte]'?: string;
	};

const defaultRequestConfig: IGetAccommodationsQueryParams = {};

/*
Get accommodations returns accommodations for selected
channel or default channel instead eg. croatia-split
*/

export const getAccommodations = async (
	config?: IGetAccommodationsRequestConfig
): Promise<HydraCollectionResponse<IAccommodationRead>> => {
	return await axios
		.get(`/api/v2/shop/accommodations`, { ...defaultRequestConfig, ...config })
		.then(response => {
			return Promise.resolve(
				response.data as HydraCollectionResponse<IAccommodationRead>
			);
		})
		.catch(error => {
			console.error('Error getting accommodations: ', error);
			return Promise.reject(error);
		});
};

const useGetAccommodationsDefaultOptions: UseQueryOptions<
	HydraCollectionResponse<IAccommodationRead>,
	unknown,
	HydraCollectionResponse<IAccommodationRead>
> = {
	refetchOnWindowFocus: false
};

export function useGetAccommodationsQuery(
	config?: IGetAccommodationsRequestConfig,
	options?: UseQueryOptions<
		HydraCollectionResponse<IAccommodationRead>,
		unknown,
		HydraCollectionResponse<IAccommodationRead>
	>
) {
	return useQuery(
		['accommodations', ...getQueryKeyFromParams(config?.params || {})],
		() => {
			return getAccommodations(config);
		},
		{ ...(useGetAccommodationsDefaultOptions as any), ...options }
	);
}
