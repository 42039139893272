import React from 'react';
import { orderReducer, orderStateInitializeAction } from 'reducers';
import { setOrderState, getOrderState } from 'services/shop/order';
import { useReducerWithLogger } from 'litto-lib/hooks';

export const initialOrderState: IOrderState = {
	orderKey: '',
	initOrderContext: () => {}
};

export const OrderStateContext = React.createContext<IOrderState>(
	{ ...getOrderState(), initOrderContext: () => {} } || initialOrderState
);
export const OrderDispatchContext = React.createContext<any>({});

export const OrderProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducerWithLogger(
		orderReducer,
		initialOrderState,
		true && process.env.NODE_ENV === 'development'
	);

	React.useEffect(() => {
		setOrderState(state);
	}, [state]);

	const initOrderContext = React.useCallback(async orderDispatch => {
		const orderStorageState = await getOrderState();
		orderDispatch(orderStateInitializeAction(orderStorageState));
	}, []);

	return (
		<OrderStateContext.Provider value={{ ...state, initOrderContext }}>
			<OrderDispatchContext.Provider value={dispatch}>
				{children}
			</OrderDispatchContext.Provider>
		</OrderStateContext.Provider>
	);
};

export const useOrderStateContext = () => {
	const context = React.useContext(OrderStateContext);
	if (context === undefined) {
		throw new Error(
			'useOrderStateContext must be used within a OrderStateProvider'
		);
	}
	return context;
};

export const useOrderDispatchContext = () => {
	const context = React.useContext(OrderDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useOrderDispatchContext must be used within a OrderDispatchContext'
		);
	}
	return context;
};

export interface IOrderState {
	orderKey: string;
	initOrderContext: (dispatch: any) => void;
}
