import React from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

export const UrlChangeListener: React.FC = () => {
	const router = useRouter();
	React.useEffect(() => {
		let interval: NodeJS.Timeout;
		const handleRouteChange = async () => {
			NProgress.start();
			interval = setInterval(() => {
				NProgress.inc();
			}, 50);
		};

		const handleComplete = () => {
			NProgress.done();
			clearInterval(interval);
		};

		router.events.on('routeChangeStart', handleRouteChange);
		router.events.on('routeChangeComplete', handleComplete);
		router.events.on('routeChangeError', handleComplete);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
			router.events.off('routeChangeComplete', handleComplete);
			router.events.off('routeChangeError', handleComplete);
		};
	}, []);
	return <React.Fragment></React.Fragment>;
};
