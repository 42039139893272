import { priceParser } from 'litto-lib/helpers';
import moment from 'moment';
import React from 'react';

import { IOrderCheckout } from 'services/shop/order/interfaces';

interface IModalPriceDetailsProps {
	itemsOriginalTotal: IOrderCheckout['itemsOriginalTotal'];
	items: IOrderCheckout['items'];
}
export const ModalPriceDetails: React.FC<IModalPriceDetailsProps> = ({
	items,
	itemsOriginalTotal
}) => {
	return (
		<div>
			{items.map(orderItem => {
				const { date, total, originalUnitPrice } = orderItem;
				return (
					<div key={date} className="flex justify-between py-2">
						<span>{moment(date).format('DD.MM.YYYY')}</span>
						<div className="flex gap-4">
							<span className="font-bold">
								{priceParser(originalUnitPrice || total)}
							</span>
						</div>
					</div>
				);
			})}
			<hr className="my-5" />
			<div className="flex justify-between py-2 font-bold">
				<span>Total</span>
				<span>{priceParser(itemsOriginalTotal)}</span>
			</div>
		</div>
	);
};
