import React from 'react';
import { range } from 'litto-lib/utils';
interface ISkeletonTextProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
	bgClass?: string;
	rows?: number;
	rowSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const SkeletonText = ({
	className = '',
	bgClass = '',
	rows = 1,
	rowSize,
	style
}: ISkeletonTextProps) => {
	const [randomWidth, setRandomWidth] = React.useState(0);

	React.useEffect(() => {
		setRandomWidth(rowSize ? rowSize : Math.ceil(Math.random() * 6 + 6));
	}, [randomWidth]);

	const rowsList = range(0, rows).map((row, i) => {
		return (
			<div
				key={i}
				className={`
							font-inherit leading-inherit
						relative
						bg-gray-200 text-transparent
						${randomWidth == 12 ? 'w-full' : `w-${randomWidth}/12`}
						select-none
					`}
			>
				{row}
				<hr
					className={`${
						bgClass ? bgClass : 'bg-white'
					} absolute bottom-0 h-0.5 w-full`}
				/>
			</div>
		);
	});

	return (
		<div style={style} className={`${className} w-full`}>
			{rowsList}
		</div>
	);
};
