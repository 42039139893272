import React from 'react';
import Link from 'next/link';
import ClipLoader from 'react-spinners/ClipLoader';

import { SvgArrowRight } from 'litto-lib/assets';

export type BtnVariants = 'fill' | 'outline' | 'link';
export type BtnColors = 'primary' | 'dark' | 'light' | 'transparent' | 'brand';
export type BtnSizes = 'sm' | 'md';
export type BtnRoundness = 'lg' | 'full';
export interface IButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	ref?: any;
	className?: string;
	href?: string;
	target?: HTMLAnchorElement['target'];
	text?: string;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
	loading?: boolean;
	withArrow?: boolean;
	variant?: BtnVariants;
	color?: BtnColors;
	size?: BtnSizes;
	rounded?: BtnRoundness;
}

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<any, IButtonProps>(
	(
		{
			href,
			children,
			loading,
			text,
			iconLeft,
			iconRight,
			withArrow,
			className = '',
			variant = 'fill',
			color = 'primary',
			size = 'md',
			rounded = 'lg',
			...btnProps
		},
		ref
	) => {
		const buttonClasses = `
			${className}
			button
			button--variant-${variant}
			button--color-${color}
			button--size-${size}
			button--rounded-${rounded}
			${loading ? 'button--loading' : ''}
		`;

		const btnContent = (
			<>
				{iconLeft && iconLeft}
				{(text || children) && <span>{text || children}</span>}
				{!loading && withArrow && <SvgArrowRight />}
				{loading && (
					<ClipLoader
						color={color}
						loading={loading}
						css={`
							margin-left: 8px;
						`}
						size={18}
					/>
				)}
				{iconRight && iconRight}
			</>
		);

		return href ? (
			<Link href={href}>
				<a ref={ref} className={buttonClasses} {...(btnProps as any)}>
					{btnContent}
				</a>
			</Link>
		) : (
			<button ref={ref} className={buttonClasses} {...btnProps}>
				{btnContent}
			</button>
		);
	}
);
