import { SvgCloseIcon } from 'litto-lib/assets';
import React from 'react';
import ReactModal from 'react-modal';
export interface IModalHeaderProps {
	className?: string;
	headerText?: string;
	onRequestClose?: ReactModal.Props['onRequestClose'];
}

export const ModalHeader: React.FC<IModalHeaderProps> = ({
	onRequestClose,
	headerText,
	className
}) => {
	return (
		<div
			className={`${
				className || ''
			}  flex items-center justify-between border border-b px-5 pt-4 pb-3 md:border-none md:px-12 md:pt-11 md:pb-0`}
		>
			<h3 className="text-lg font-bold md:text-xl">{headerText}</h3>
			<button
				className="flex h-10 w-10 items-center justify-center rounded-full transition-opacity hover:bg-gray-100"
				onClick={(e: any) => {
					onRequestClose && onRequestClose(e);
				}}
			>
				<SvgCloseIcon />
			</button>
		</div>
	);
};

export default ModalHeader;
