import { ICloudinaryConfig, IMOTransformationProps } from './interfaces';

let config: ICloudinaryConfig = {};

export const getConfig = () => Object.freeze(config);

export const setConfig = (options: ICloudinaryConfig) =>
	(config = {
		...config,
		...options
	});

export const getImageUrl = (path: string) => {
	return `https://${config?.cloudName}.mo.cloudinary.net${
		config?.cloudNamePrefix ? `/${config?.cloudNamePrefix}` : ''
	}${path}`;
};

export const getImageUrlFromSylius = (path: string) => {
	return `${process.env.API_URL_BASE}${path}`;
};

export const buildTransformationsUrl = (
	path: string,
	tx?: IMOTransformationProps
) => {
	const transformationsQuery = buildTransformationsQuery(tx);
	return `${getImageUrl(path)}${transformationsQuery}`;
};

export const buildTransformationsQuery = (tx: IMOTransformationProps = {}) => {
	const transformations = Object.entries(tx)
		.map(([key, value]) => {
			return `${key}_${value}`;
		})
		.join(',');
	if (!transformations) {
		return '';
	}
	return `?tx=${transformations}`;
};

export const getTransformationsFromUrl = (
	url: string
): IMOTransformationProps => {
	const transformations: IMOTransformationProps = {};
	const transformationsQuery = '?tx=';
	const transformationsStart =
		url.indexOf(transformationsQuery) + transformationsQuery.length;
	const transformationsString = url.substring(transformationsStart, url.length);
	transformationsString.split(',').forEach(val => {
		const keyVal = val.split('_');
		transformations[keyVal[0] as keyof IMOTransformationProps] =
			transformations[keyVal[1] as keyof IMOTransformationProps];
	});
	return transformations;
};

export * from './interfaces';
