import React from 'react';

import { Field, useField } from 'formik';
import { CustomCount, ICustomCountProps } from '../../';

export const FieldCount: React.FC<ICustomCountProps> = props => {
	const { name, ...rest } = props;
	const [field] = useField(props.name);
	return (
		<Field name={`${name}`}>
			{(props: any) => {
				const {
					field: { name, value, onChange },
					form: { setFieldValue }
				} = props;
				return (
					<CustomCount
						name={`${name}`}
						value={value}
						onChange={e => onChange(e)}
						onCountChange={e => {
							setFieldValue(`${name}`, e);
							field.onChange;
						}}
						{...rest}
					/>
				);
			}}
		</Field>
	);
};
