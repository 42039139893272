export const objValuesToString = (o: any) => {
	if (!o) {
		return;
	}
	Object.keys(o).forEach(k => {
		if (typeof o[k] === 'object') {
			return objValuesToString(o[k]);
		}

		o[k] = '' + o[k];
	});

	return o;
};
