import React, { Children } from 'react';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

export interface IActiveLinkProps extends LinkProps {
	activeClassName: string;
	inactiveClassName?: string;
}

export const ActiveLink: React.FC<IActiveLinkProps> = ({
	children,
	activeClassName,
	inactiveClassName,
	...props
}) => {
	const router = useRouter();
	const { asPath, pathname } = router;
	const child = Children.only(children);
	const childClassName = (child as any).props.className || '';

	const className =
		asPath === props.href || pathname === props.href || asPath === props.as
			? `${childClassName} ${activeClassName}`.trim()
			: `${childClassName} ${inactiveClassName || ''}`;

	return (
		<Link {...props}>
			{React.cloneElement(child as any, {
				className: className || null
			})}
		</Link>
	);
};
