import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GridHelper, UrlChangeListener } from 'litto-lib/components/common';
import { isEmpty, set } from 'litto-lib/utils';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import 'styles/index.scss';
// import 'swiper/scss';
import 'swiper/scss/pagination';
import 'react-phone-input-2/lib/high-res.css';
import 'react-dates/initialize';
import ReactGA4 from 'react-ga4';
import { ToastContainer } from 'react-toastify';

import { CookieConsent, CookiesProvider } from 'components/shared';
import { configs } from 'configs';
import {
	OrderProvider,
	useOrderDispatchContext,
	useOrderStateContext,
	UserProvider,
	useUserDispatchContext,
	useUserStateContext
} from 'context';
import { userSetLocationAction } from 'reducers';
import { useGetLocationsQuery } from 'services/shop/location';

const { client: queryClient } = configs();

const tagManagerArgs = {
	gtmId: process.env.NEXT_PUBLIC_GTM_KEY || ''
};

ReactGA4.initialize(tagManagerArgs.gtmId);

const MyApp = ({ Component, pageProps }: AppProps) => {
	const renderLayout = () => (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				></meta>

				<script
					type="text/javascript"
					dangerouslySetInnerHTML={{
						__html: `
						  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
						  (function(){
						  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
						  s1.async=true;
						  s1.src='https://embed.tawk.to/667c3873eaf3bd8d4d149af4/1i1ajp143';
						  s1.charset='UTF-8';
						  s1.setAttribute('crossorigin','*');
						  s0.parentNode.insertBefore(s1,s0);
						  })();
					`
					}}
				></script>
			</Head>
			<GridHelper />
			<CookieConsent />
			<Component {...pageProps} />
		</>
	);

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<Hydrate state={(pageProps as any).dehydratedState}>
					<UserProvider>
						<CookiesProvider>
							<OrderProvider>
								<AppWithState>{renderLayout()}</AppWithState>
								<UrlChangeListener />
								<ReactQueryDevtools initialIsOpen={false} />
								<ToastContainer newestOnTop limit={1} />
							</OrderProvider>
						</CookiesProvider>
					</UserProvider>
				</Hydrate>
			</QueryClientProvider>
		</>
	);
};

const AppWithState: React.FC<any> = ({ children }) => {
	const router = useRouter();
	const { query } = router;
	const { channel } = query;
	const userDispatch = useUserDispatchContext();
	const { initContext } = useUserStateContext();
	const orderDispatch = useOrderDispatchContext();
	const { initOrderContext } = useOrderStateContext();
	const { data: locations, refetch: getLocations } = useGetLocationsQuery();

	React.useEffect(() => {
		// Prefetch locations on mount
		getLocations();
	}, []);

	React.useEffect(() => {
		if (locations && !isEmpty(locations['hydra:member'])) {
			const selectedLocation = locations?.['hydra:member']?.find(
				loc => loc.code === channel
			);
			if (selectedLocation) {
				userDispatch(userSetLocationAction(selectedLocation));
			}
		}
	}, [locations, query]);

	React.useEffect(() => {
		initContext(userDispatch);
		initOrderContext(orderDispatch);
	}, []);

	React.useEffect(() => {
		if (query.coupon_code) {
			set({ key: 'stored_coupon_code', value: query.coupon_code });
		}
	}, [query]);

	return <React.Fragment>{children}</React.Fragment>;
};

export default MyApp;
