import { Form, Formik, FormikHelpers } from 'formik';
import {
	Button,
	FieldCheckboxGroup,
	FieldCount,
	IModalProps,
	Modal
} from 'litto-lib/components/common';
import { groupBy } from 'litto-lib/utils';
import { queryTypes, useQueryStates } from 'next-usequerystate';
import React from 'react';
import * as Yup from 'yup';

import { AmenityGroup } from './AmenityGroup/AmenityGroup';
import { IAccommodationAttributeRead } from 'services/shop/accommodation/interfaces';

const MoreFiltersFormSchema = Yup.object({
	numberOfBeds: Yup.number(),
	numberOfBedrooms: Yup.number(),
	numberOfBaths: Yup.number()
});

const emptyFormFilters = {
	numberOfBeds: 0,
	numberOfBedrooms: 0,
	numberOfBaths: 0,
	amenities: ''
};

const roomFilters = [
	{
		label: 'Beds',
		name: 'numberOfBeds'
	},
	{
		label: 'Bedrooms',
		name: 'numberOfBedrooms'
	},
	{
		label: 'Bathrooms',
		name: 'numberOfBaths'
	}
];

export interface IMoreFiltersFormData {
	numberOfBeds: number;
	numberOfBedrooms: number;
	numberOfBaths: number;
	amenities: string;
	type: string;
}

export interface IModalMoreFiltersProps extends IModalProps {
	attributes: IAccommodationAttributeRead[];
	onSubmit?: (
		formData: IMoreFiltersFormData,
		formikHelpers: FormikHelpers<IMoreFiltersFormData>
	) => void;
}

export const ModalMoreFilters: React.FC<IModalMoreFiltersProps> = ({
	attributes,
	onSubmit,
	...modalProps
}) => {
	const [queries] = useQueryStates({
		amenities: queryTypes.string,
		type: queryTypes.string,
		'numberOfBeds[gte]': queryTypes.integer,
		'numberOfBaths[gte]': queryTypes.integer,
		'numberOfBedrooms[gte]': queryTypes.integer
	});
	const {
		amenities: amenities,
		type: type,
		'numberOfBeds[gte]': numberOfBeds,
		'numberOfBaths[gte]': numberOfBaths,
		'numberOfBedrooms[gte]': numberOfBedrooms
	} = queries;
	const { onRequestClose, ...otherModalProps } = modalProps;
	const formRef = React.useRef<any>();
	const [groupedAttributes, setGrouppedAttribtes] =
		React.useState<Record<string, IAccommodationAttributeRead[]>>();

	React.useEffect(() => {
		if (attributes) {
			setGrouppedAttribtes(
				groupBy<IAccommodationAttributeRead, string>(
					attributes,
					a => a.category
				)
			);
		}
	}, [attributes]);

	const handleManualSubmit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	const handleClearFilters = () => {
		if (formRef.current) {
			formRef.current.setValues(emptyFormFilters);
		}
	};

	const handleSubmit = async (
		formData: IMoreFiltersFormData,
		formikHelpers: FormikHelpers<IMoreFiltersFormData>
	) => {
		onSubmit && onSubmit(formData, formikHelpers);
		onRequestClose && onRequestClose();
	};
	return (
		<Modal
			headerText="More filters"
			onRequestClose={onRequestClose}
			{...otherModalProps}
		>
			<Formik
				innerRef={formRef}
				initialValues={{
					numberOfBeds: numberOfBeds || 0,
					numberOfBedrooms: numberOfBedrooms || 0,
					numberOfBaths: numberOfBaths || 0,
					amenities: amenities || '',
					type: type || ''
				}}
				validationSchema={MoreFiltersFormSchema}
				onSubmit={handleSubmit}
			>
				{() => {
					return (
						<Form>
							<h2 className="mt-8 mb-6">Rooms and beds</h2>
							{roomFilters.map(roomFilter => {
								const { name, label } = roomFilter;
								return (
									<div
										key={name}
										className="mb-4 flex items-center justify-between"
									>
										<div>
											<p className="font-bold">{label}</p>
										</div>
										<FieldCount id={name} min={0} name={name} />
									</div>
								);
							})}
							<FieldCheckboxGroup<number>
								name="amenities"
								format={state => {
									return state.join(',');
								}}
								defaultValue={amenities?.split(',').map(val => Number(val))}
							>
								{groupedAttributes &&
									Object.keys(groupedAttributes).map(groupName => (
										<AmenityGroup
											key={groupName}
											groupName={groupName}
											groupedAttributes={groupedAttributes[groupName]}
										/>
									))}
							</FieldCheckboxGroup>
						</Form>
					);
				}}
			</Formik>
			<Modal.Footer key="ModalFooter">
				<div className="flex items-center justify-between">
					<Button
						color="primary"
						text="Search"
						type="submit"
						onClick={handleManualSubmit}
					/>
					<Button
						text="Clear filters"
						color="transparent"
						type="button"
						onClick={handleClearFilters}
					/>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
