import { SvgCloseIcon } from 'litto-lib/assets';
import { CloudinaryImage } from 'litto-lib/components/common';
import { useScrollLock } from 'litto-lib/hooks';
import React from 'react';

import { GalleryArrayItem } from 'components/shared';

export const ScrollGallery = ({
	isScrollGalleryOpened,
	setIsScrollGalleryOpened,
	pathToScrollTo,
	setPathToScrollTo,
	imagesArr
}: {
	isScrollGalleryOpened: boolean;
	setIsScrollGalleryOpened: React.Dispatch<React.SetStateAction<boolean>>;
	pathToScrollTo: string;
	setPathToScrollTo: React.Dispatch<React.SetStateAction<string>>;
	imagesArr: GalleryArrayItem[];
}) => {
	const dialogRef = React.useRef<HTMLDialogElement>(null);

	useScrollLock(isScrollGalleryOpened, dialogRef);

	React.useEffect(() => {
		const image = document.querySelector(
			`dialog img[src*="${pathToScrollTo}"]`
		);
		if (isScrollGalleryOpened && image) {
			image.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [isScrollGalleryOpened, pathToScrollTo]);

	return (
		<dialog
			ref={dialogRef}
			open={isScrollGalleryOpened}
			className={`
					  px-0 py-15 md:py-10 block overflow-y-auto fixed top-0 left-0 bg-white/80 z-[1000] w-full h-full transition-all
						${!isScrollGalleryOpened ? 'opacity-0 pointer-events-none scale-110' : ''}
					`}
			onClick={() => {
				setPathToScrollTo('');
				setIsScrollGalleryOpened(false);
			}}
		>
			<button className="transition-all fixed right-5 top-5 hover:scale-90 focus:scale-110 active:scale-110">
				<SvgCloseIcon />
			</button>
			<div className="container grid md:grid-cols-3 gap-2">
				{imagesArr.map((img, index) => (
					<div
						onClick={e => {
							e.stopPropagation();
						}}
						key={img.id}
						className={`
									  relative h-[320px] bg-gray-100
										${index % 4 === 0 ? 'md:col-span-3  md:h-[480px]' : ''}
									`}
					>
						<CloudinaryImage
							src={img.path}
							alt={img.alt}
							layout="fill"
							objectFit="cover"
						/>
					</div>
				))}
			</div>
		</dialog>
	);
};
