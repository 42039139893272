import { SvgArrowIcon } from 'litto-lib/assets';
import { FieldCheckbox } from 'litto-lib/components/common';
import { useMediaQuery } from 'litto-lib/hooks';
import { slugToWords } from 'litto-lib/utils';
import React from 'react';

import { IAccommodationAttributeRead } from 'services/shop/accommodation/interfaces';


interface IAmenityGroupProps {
	groupName: string;
	groupedAttributes: IAccommodationAttributeRead[];
}

export const AmenityGroup: React.FC<IAmenityGroupProps> = ({
	groupName,
	groupedAttributes
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	const [showMore, setShowMore] = React.useState(false);

	if (isMobile) {
		return (
			<>
				<div
					className="my-8 flex items-center justify-between border-0 border-t border-solid border-gray-100 pt-4"
					onClick={() => {
						setShowMore(prevState => !prevState);
					}}
				>
					<h2 className="capitalize">{slugToWords(groupName)}</h2>
					<span className="flex items-center pr-4">
						<span className="mr-2 underline">
							{showMore ? 'Show less' : 'Show more'}
						</span>
						<SvgArrowIcon
							className={`h-4 w-4 transform transition-transform duration-150  ${
								showMore ? 'rotate-180' : ''
							}`}
						/>
					</span>
				</div>
				{showMore && (
					<div className="flex flex-col sm:flex-row sm:flex-wrap">
						{groupedAttributes.map(({ id, translation }) => (
							<FieldCheckbox
								key={id}
								label={translation?.name}
								name={translation?.name}
								value={id}
								className="mb-4 sm:w-1/2 "
							/>
						))}
					</div>
				)}
			</>
		);
	}

	return (
		<>
			<h2 className="my-8 capitalize">{slugToWords(groupName)}</h2>
			<div className="flex flex-row flex-wrap">
				{groupedAttributes.map(({ id, translation }) => (
					<FieldCheckbox
						key={id}
						label={translation?.name}
						name={translation?.name}
						value={id}
						className="mb-4 w-1/2 "
					/>
				))}
			</div>
		</>
	);
};
