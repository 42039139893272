/* eslint-disable no-nested-ternary */
import { useMediaQuery } from 'litto-lib/hooks';
import { AvailableSvg, slugToSvg } from 'litto-lib/utils';
import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IPromoBanner } from 'services/wordpress/data/interface';

SwiperCore.use([Pagination]);
interface ITipsSliderProps {
	tipsArray: IPromoBanner[];
}

const SwiperSlideContent = ({ item }: { item: IPromoBanner }) => {
	const { title, icon_name, text } = item;
	const Svg = slugToSvg(icon_name as AvailableSvg);
	return (
		<div className="flex items-start">
			<Svg className="mr-6 h-12 w-12 flex-none text-primary-500" />
			<div className="flex-1">
				<h3 className="mb-3 text-xl font-bold text-primary-500">{title}</h3>
				<p className="text-base">{text}</p>
			</div>
		</div>
	);
};

export const TipsSlider: React.FC<ITipsSliderProps> = ({ tipsArray }) => {
	const isSm = useMediaQuery('(max-width: 767px)');
	const isMd = useMediaQuery('(max-width: 1023px)');

	const shouldUseSwiper = React.useMemo(
		() => isMd || tipsArray.length > 4,
		[isMd, tipsArray.length]
	);

	return (
		<div data-testid="promoBanner" className="promo-banner bg-brand pt-11">
			<div className="container">
				<h3 className="mb-8 text-2xl font-extrabold text-primary-500">
					Why book with Litto?
				</h3>
				<div className={`-mx-2.5`}>
					{shouldUseSwiper ? (
						<Swiper
							pagination={{ clickable: true }}
							slidesPerView={isMd ? (isSm ? 1.25 : 2.25) : 4}
						>
							{tipsArray.map((item, i) => (
								<SwiperSlide key={i} className="h-auto flex-col px-2.5">
									<SwiperSlideContent item={item} />
								</SwiperSlide>
							))}
						</Swiper>
					) : (
						<div className="flex pb-16">
							{tipsArray.map((item, i) => (
								<div key={i} className="h-auto w-1/4 flex-col px-2.5">
									<SwiperSlideContent item={item} />
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
