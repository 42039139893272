import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IStaticPage } from './interfaces';

export const getStaticPageSlugs = async () => {
	return await axios
		.get<IStaticPage[]>(`${process.env.WORDPRESS_URL}/pages`, {})
		.then(response => {
			return Promise.resolve(response.data as IStaticPage[]);
		})
		.catch(error => {
			console.error('Error loading static page: ', error);
			return Promise.reject(error);
		});
};

type IUseGetStaticPageSlugsOptions = UseQueryOptions<
	IStaticPage[],
	unknown,
	IStaticPage[]
>;
const useGetStaticPageSlugsDefaultOptions: IUseGetStaticPageSlugsOptions = {
	refetchOnWindowFocus: false
};

export function useGetStaticPageSlugsQuery(
	options?: IUseGetStaticPageSlugsOptions
) {
	return useQuery(['static-pages'], () => getStaticPageSlugs(), {
		...(useGetStaticPageSlugsDefaultOptions as any),
		...options
	});
}
