import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IStaticPage } from './interfaces';

export const getStaticPage = async (slug: string) => {
	return await axios
		.get<IStaticPage[]>(`${process.env.WORDPRESS_URL}/pages?slug=${slug}`, {})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error loading static page: ', error);
			return Promise.reject(error);
		});
};

export function useGetStaticPageQuery(slug: string) {
	return useQuery(['static', slug], () => getStaticPage(slug));
}
