import React, { useState } from 'react';
import InputRange, { InputRangeProps, Range } from 'react-input-range';

export interface ICustomRangeProps extends InputRangeProps {
	className?: string;
	name: string;
	minValue: number;
	minValuePrefix?: string;
	minValueSufix?: string;
	maxValue: number;
	maxValuePrefix?: string;
	maxValueSufix?: string;
}

export const CustomRange: React.FC<ICustomRangeProps> = ({
	minValue,
	minValueSufix,
	maxValue,
	maxValueSufix,
	value,
	onChange,
	className = '',
	...inputRangeProps
}) => {
	const [innerValue, setInnerValue] = useState<number | Range>(
		value || { min: minValue, max: maxValue }
	);

	React.useEffect(() => {
		setInnerValue(value || { min: minValue, max: maxValue });
	}, [minValue, maxValue]);

	const handleOnChange = (value: number | Range) => {
		setInnerValue(value);
		onChange && onChange(value);
	};

	React.useEffect(() => {
		handleOnChange(value);
	}, [value]);

	return (
		<div className={className}>
			<InputRange
				minValue={minValue}
				maxValue={maxValue}
				value={innerValue}
				onChange={handleOnChange}
				{...inputRangeProps}
			/>
			{!(typeof innerValue === 'number') && (
				<div className="flex justify-around">
					<span className="flex flex-col text-center text-xl font-bold">
						€ {innerValue.min} {minValueSufix || ''}
						<span className="text-right text-sm font-normal text-gray-300">
							Minimum price
						</span>
					</span>
					<span className="flex flex-col text-center text-xl font-bold">
						€ {innerValue.max} {maxValueSufix || ''}
						<span className="text-right text-sm font-normal text-gray-300">
							Maximum price
						</span>
					</span>
				</div>
			)}
		</div>
	);
};

export default CustomRange;
