import { useField, useFormikContext } from 'formik';
import { CustomPhoneInput, ICustomPhoneInputProps } from '../../';

export const FieldPhoneInput: React.FC<ICustomPhoneInputProps> = props => {
	const { name, onChange, ...rest } = props;
	const [field] = useField(name);
	const { setFieldValue } = useFormikContext();
	return (
		<CustomPhoneInput
			{...field}
			onChange={(e, ...restEvents) => {
				setFieldValue(name, e);
				onChange && onChange(e, ...restEvents);
			}}
			{...rest}
		/>
	);
};
