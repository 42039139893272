import axios from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IOrderStripePaymentTokenRead } from './interfaces';
import { ordersKeys } from './queryKeys';

export const getOrderStripePaymentToken = async (orderKey: string) => {
	return await axios
		.get<IOrderStripePaymentTokenRead>(
			`/api/v2/shop/orders/${orderKey}/stripe-payment-token`
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting order: ${orderKey}`, error);
			return Promise.reject(error);
		});
};

export type IGetOrderStripePaymentTokenQueryOptions = UseQueryOptions<
	IOrderStripePaymentTokenRead,
	any,
	IOrderStripePaymentTokenRead
>;

export function useGetOrderStripePaymentTokenQuery(
	orderKey: string,
	options?: IGetOrderStripePaymentTokenQueryOptions
) {
	return useQuery(
		ordersKeys.stripeToken(orderKey) as QueryKey,
		() => {
			return getOrderStripePaymentToken(orderKey);
		},
		{ refetchOnWindowFocus: false, cacheTime: 0, ...options }
	);
}
