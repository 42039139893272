import axios from 'axios';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { orderStateUpdateOrderKeyAction } from 'reducers';
import { IOrderCheckout } from './interfaces';
import { ordersKeys } from './queryKeys';

export const createOrder = async (orderDispatch: any) => {
	return await axios
		.post<IOrderCheckout>(`/api/v2/shop/orders`, {})
		.then(response => {
			orderDispatch(orderStateUpdateOrderKeyAction(response.data.tokenValue));
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error creating order:`, error);
			return Promise.reject(error);
		});
};

export function useCreateOrderMutation(
	queryClient: QueryClient,
	orderDispatch: any
) {
	return useMutation(
		() => {
			return createOrder(orderDispatch);
		},
		{
			onSuccess: order => {
				queryClient.setQueryData(ordersKeys.details(order.tokenValue), order);
			}
		}
	);
}
