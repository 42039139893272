export interface classValueMap {
	DEFAULT?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
}

export const generateClassNames = (
	classNames: string,
	classValues: classValueMap
): string => {
	return Object.entries(classValues)
		.map(([mediaPrefix, classValue]) => {
			return classNames
				.split(' ')
				.map(
					className =>
						`${
							mediaPrefix === 'DEFAULT' ? '' : mediaPrefix + ':'
						}${className}-${classValue}`
				)
				.join(' ');
		})
		.join(' ');
};
