import React from 'react';
import { OrientationShape } from 'react-dates';

import { SvgArrowIcon } from 'litto-lib/assets';

export interface ICustomCalendarNavPrevProps {
	orientation?: OrientationShape;
}

export const CustomCalendarNavPrev: React.FC<
	ICustomCalendarNavPrevProps
> = () => {
	return (
		<button className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 transition hover:bg-gray-100 md:bg-transparent">
			<SvgArrowIcon className="svg-no-prefill" />
		</button>
	);
};
