import {
	Form,
	Formik,
	FormikConfig,
	FormikHelpers,
	FormikProps,
	useFormikContext
} from 'formik';
import { FieldCount } from 'litto-lib/components/common';
import React from 'react';
import * as Yup from 'yup';

const GuestsFormSchema = Yup.object({
	adults: Yup.string()
		.min(1, 'Minimal number of guests is 1')
		.required('This field is required.'),
	children: Yup.string(),
	infants: Yup.string()
});

export interface IGuestFormProps
	extends Omit<FormikConfig<IGuestFormData>, 'onSubmit'> {
	onSubmit?: (
		formData: IGuestFormData,
		formikHelpers: FormikHelpers<IGuestFormData>
	) => void;
	onChange?: (formData: IGuestFormData) => void;
	formRef?: React.Ref<FormikProps<IGuestFormData>> | null;
	description?: string;
	minAdults?: number;
	maxGuests?: number;
	maxInfants?: number;
	className?: string;
}

export interface IGuestFormData {
	adults: number;
	children: number;
	infants: number;
}

export const GuestForm: React.FC<IGuestFormProps> = ({
	formRef,
	initialValues,
	className = '',
	onSubmit,
	onChange,
	description,
	minAdults,
	maxGuests = 30,
	maxInfants
}) => {
	const [isFirstChange, setIsFirstChange] = React.useState<boolean>(true);

	const onGuestFormSubmit = (
		formikData: IGuestFormData,
		formikHelpers: FormikHelpers<IGuestFormData>
	) => {
		onSubmit && onSubmit(formikData, formikHelpers);
	};

	const onGuestFormChange = (values: IGuestFormData) => {
		if (onChange && !isFirstChange) {
			onChange(values);
		}
		setIsFirstChange(false);
	};

	return (
		<Formik
			innerRef={formRef}
			initialValues={initialValues}
			validationSchema={GuestsFormSchema}
			enableReinitialize
			onSubmit={onGuestFormSubmit}
		>
			{props => {
				const { values } = props;
				return (
					<Form className={`${className} flex flex-col`}>
						<GuestFormEvents onChange={onGuestFormChange} />
						<GuestRow label="Adults" text="Ages 13 or above">
							<FieldCount
								id="adults"
								name="adults"
								min={minAdults || minAdults === 0 ? minAdults : 1}
								max={maxGuests}
								maxDisabled={
									maxGuests === Number(values.adults) + Number(values.children)
								}
							/>
						</GuestRow>
						<GuestRow label="Children" text="Ages 2–12">
							<FieldCount
								id="children"
								name="children"
								min={0}
								max={maxGuests}
								maxDisabled={
									maxGuests === Number(values.adults) + Number(values.children)
								}
							/>
						</GuestRow>
						<GuestRow label="Infants" text="Under 2">
							<FieldCount
								id="infants"
								name="infants"
								min={0}
								max={maxInfants}
							/>
						</GuestRow>
						{description && (
							<p className="my-5 text-md text-gray-300">{description}</p>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};

export type IGuestsFormEventsProps = Pick<IGuestFormProps, 'onChange'>;

const GuestFormEvents: React.FC<IGuestsFormEventsProps> = ({ onChange }) => {
	const { values } = useFormikContext<IGuestFormData>();

	React.useEffect(() => {
		if (onChange) {
			onChange(values);
		}
	}, [values]);

	return <></>;
};

export interface IGuestRowProps {
	text: string;
	label: string;
}

export const GuestRow: React.FC<IGuestRowProps> = ({
	text,
	label,
	children
}) => {
	return (
		<div className="mb-4 flex items-center justify-between">
			<div className="mr-6">
				<p className="text-lg font-bold">{label}</p>
				<p className="text-sm">{text}</p>
			</div>
			{children}
		</div>
	);
};
