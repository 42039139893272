import { Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import { FieldCheckbox, FieldCheckboxGroup } from 'litto-lib/components/common';
import { queryTypes, useQueryStates } from 'next-usequerystate';
import React from 'react';

import { ITaxon } from 'services/shop/taxon/interfaces';

export interface ITypeFormData {
	types: string;
}

export interface ITypeFormProps
	extends Omit<FormikConfig<ITypeFormData>, 'onSubmit'> {
	accommodationTypes: ITaxon[];
	onSubmit?: (
		formData: ITypeFormData,
		formikHelpers: FormikHelpers<ITypeFormData>
	) => void;
	formRef?: React.Ref<FormikProps<ITypeFormData>> | null;
}

export const TypeForm: React.FC<ITypeFormProps> = ({
	accommodationTypes,
	initialValues,
	onSubmit,
	formRef
}) => {
	const [queries] = useQueryStates({
		type: queryTypes.string
	});
	const { type } = queries;

	const onTypeFormSubmit = (
		formikData: ITypeFormData,
		formikHelpers: FormikHelpers<ITypeFormData>
	) => {
		onSubmit && onSubmit(formikData, formikHelpers);
	};

	return (
		<Formik
			enableReinitialize
			innerRef={formRef}
			initialValues={initialValues}
			onSubmit={onTypeFormSubmit}
		>
			{() => {
				return (
					<Form>
						<FieldCheckboxGroup
							name="types"
							format={state => {
								return state.join(',');
							}}
							defaultValue={type?.split(',')}
						>
							<div className="column-count-2">
								{accommodationTypes?.map(({ code, translation }) => (
									<FieldCheckbox
										key={code}
										label={translation?.name}
										name={code}
										value={code}
										className="mb-4"
									/>
								))}
							</div>
						</FieldCheckboxGroup>
					</Form>
				);
			}}
		</Formik>
	);
};
