import axios, { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { IOrderCheckout } from './interfaces';

export interface IUpdateOrderItemData {
	productCode: string;
	dateFrom: string;
	dateTo: string;
	adults: number;
	children: number;
	infants: number;
}

export const updateOrderItem = async (
	orderKey: string,
	data: IUpdateOrderItemData
) => {
	return await axios
		.patch<IOrderCheckout>(`/api/v2/shop/orders/${orderKey}/items`, data)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating order item:`, error);
			return Promise.reject(error);
		});
};

export function useUpdateOrderItemMutation() {
	return useMutation<
		IOrderCheckout,
		AxiosError<any>,
		{ orderKey: string; data: IUpdateOrderItemData },
		unknown
	>(({ orderKey, data }: { orderKey: string; data: IUpdateOrderItemData }) => {
		return updateOrderItem(orderKey, data);
	});
}
