import {
	SvgAirConditioning,
	SvgBabyBath,
	SvgBabyMonitor,
	SvgBabySafetyGates,
	SvgBabysitterRecommendations,
	SvgBakingSheet,
	SvgBarbecueUtensils,
	SvgBathtub,
	SvgBeachEssentials,
	SvgBeachFront,
	SvgBedLinens,
	SvgBidet,
	SvgBikes,
	SvgBoardGames,
	SvgBoatSlip,
	SvgBodySoap,
	SvgBreakfast,
	SvgBuildingStaff,
	SvgCableTV,
	SvgCancellationPolicy,
	SvgCarbonMonoxideAlarm,
	SvgCeilingFan,
	SvgChangingTable,
	SvgCheckinTime,
	SvgCheckoutTime,
	SvgChildrenBooksAndToys,
	SvgChildrenDinerware,
	SvgCleaningBeforeCheckout,
	SvgCleaningProducts,
	SvgClothingStorage,
	SvgCoffeeMaker,
	SvgConditioner,
	SvgCookingBasics,
	SvgCrib,
	SvgDedicatedWorkspace,
	SvgDiningTable,
	SvgDishesAndSilverware,
	SvgDishwasher,
	SvgDryer,
	SvgDryingRackForClothing,
	SvgElevator,
	SvgEntireHome,
	SvgEssentials,
	SvgEthernetConnection,
	SvgEvCharger,
	SvgExtraPillowAndBlankets,
	SvgFireExtinguisher,
	SvgFirePit,
	SvgFireplaceGuards,
	SvgFirstAidKit,
	SvgFreeParkingOnPremises,
	SvgFreeStreetParking,
	SvgFreezer,
	SvgGameConsole,
	SvgGardenOrBackyard,
	SvgGym,
	SvgHairDryer,
	SvgHangers,
	SvgHeating,
	SvgHighChair,
	SvgHotTub,
	SvgHotWater,
	SvgHouseRules,
	SvgIndoorFireplace,
	SvgIron,
	SvgKayak,
	SvgKeurigCoffeeMachine,
	SvgKey,
	SvgKitchen,
	SvgLakeAccess,
	SvgLaundromatNearby,
	SvgLifebuoy,
	SvgLockbox,
	SvgLockPad,
	SvgLongStay,
	SvgLuggageDropOff,
	SvgMicrowave,
	SvgMiniFridge,
	SvgMissingIcon,
	SvgMosquitoNet,
	SvgNespresso,
	SvgNumberOfBaths,
	SvgNumberOfBedrooms,
	SvgNumberOfBeds,
	SvgNumberOfGuests,
	SvgOutdoorDiningArea,
	SvgOutdoorFurniture,
	SvgOutdoorShower,
	SvgOutletCovers,
	SvgOven,
	SvgPackAndPlayTravelCrib,
	SvgPaidParkingoffPremises,
	SvgPaidParkingOnPremises,
	SvgPartiesAllowed,
	SvgPatioOrBalcony,
	SvgPetsAllowed,
	SvgPiano,
	SvgPingPongTable,
	SvgPlane,
	SvgPocketWifi,
	SvgPool,
	SvgPoolTable,
	SvgPortableFans,
	SvgPourOverCoffee,
	SvgPrivateEntrance,
	SvgPropertyType,
	SvgRecordPlayer,
	SvgRefrigerator,
	SvgRiceMaker,
	SvgRoomDarkeningShades,
	SvgSafe,
	SvgSauna,
	SvgSelfCheckingPossible,
	SvgShampoo,
	SvgShowerGel,
	SvgSingleLevelCode,
	SvgSkiInSkiOut,
	SvgSmokeAlarm,
	SvgSmokingAllowed,
	SvgSoundSystem,
	SvgStove,
	SvgTableCornerGuards,
	SvgToaster,
	SvgTransferOut,
	SvgTrashCompactor,
	SvgTV,
	SvgUserCheck,
	SvgUsers2,
	SvgWallet2,
	SvgWasher,
	SvgWaterfront,
	SvgWaterKettle,
	SvgWifi,
	SvgWindowGuards,
	SvgWineGlasses
} from 'litto-lib/assets';

const svgMapper = {
	air_conditioning: SvgAirConditioning,
	baby_bath: SvgBabyBath,
	baby_monitor: SvgBabyMonitor,
	baby_safety_gates: SvgBabySafetyGates,
	babysitter_recommendations: SvgBabysitterRecommendations,
	baking_sheet: SvgBakingSheet,
	barbecue_utensils: SvgBarbecueUtensils,
	bbq_grill: SvgBarbecueUtensils,
	bathtub: SvgBathtub,
	beach_essentials: SvgBeachEssentials,
	//essentials
	beachfront: SvgBeachFront,
	bed_linens: SvgBedLinens,
	bidet: SvgBidet,
	bikes: SvgBikes,
	board_games: SvgBoardGames,
	boat_slip: SvgBoatSlip,
	body_soap: SvgBodySoap,
	breakfast: SvgBreakfast,
	//bread_maker
	cable_tv: SvgCableTV,
	carbon_monoxide_alarm: SvgCarbonMonoxideAlarm,
	ceiling_fan: SvgCeilingFan,
	changing_table: SvgChangingTable,
	checkin_time: SvgCheckinTime,
	checkout_time: SvgCheckoutTime,
	children_books_and_toys: SvgChildrenBooksAndToys,
	//air_conditioning: SvgChildrenDinerware1,
	children_dinerware: SvgChildrenDinerware,
	cleaning_before_checkout: SvgCleaningBeforeCheckout,
	cleaning_products: SvgCleaningProducts,
	clothing_storage: SvgClothingStorage,
	coffee_maker: SvgCoffeeMaker,
	conditioner: SvgConditioner,
	cooking_basics: SvgCookingBasics,
	crib: SvgCrib,
	dedicated_workspace: SvgDedicatedWorkspace,
	dining_table: SvgDiningTable,
	dishes_and_silverware: SvgDishesAndSilverware,
	dishwasher: SvgDishwasher,
	dryer: SvgDryer,
	drying_rack_for_clothing: SvgDryingRackForClothing,
	ethernet_connection: SvgEthernetConnection,
	ev_charger: SvgEvCharger,
	extra_pillow_and_blankets: SvgExtraPillowAndBlankets,
	fire_extinguisher: SvgFireExtinguisher,
	fire_pit: SvgFirePit,
	fireplace_guards: SvgFireplaceGuards,
	first_aid_kit: SvgFirstAidKit,
	//air_conditioning: SvgFrame,
	free_parking_on_premises: SvgFreeParkingOnPremises,
	free_street_parking: SvgFreeStreetParking,
	freezer: SvgFreezer,
	game_console: SvgGameConsole,
	garden_or_backyard: SvgGardenOrBackyard,
	gym: SvgGym,
	hair_dryer: SvgHairDryer,
	hangers: SvgHangers,
	heating: SvgHeating,
	high_chair: SvgHighChair,
	hot_tub: SvgHotTub,
	hot_water_kettle: SvgWaterKettle,
	hot_water: SvgHotWater,
	indoor_fireplace: SvgIndoorFireplace,
	iron: SvgIron,
	kayak: SvgKayak,
	keurig_coffee_machine: SvgKeurigCoffeeMachine,
	kitchen: SvgKitchen,
	lake_access: SvgLakeAccess,
	laundromat_nearby: SvgLaundromatNearby,
	microwave: SvgMicrowave,
	mini_fridge: SvgMiniFridge,
	mosquito_net: SvgMosquitoNet,
	nespresso_machine: SvgNespresso,
	number_of_baths: SvgNumberOfBaths,
	number_of_bedrooms: SvgNumberOfBedrooms,
	number_of_beds: SvgNumberOfBeds,
	number_of_guests: SvgNumberOfGuests,
	outdoor_dining_area: SvgOutdoorDiningArea,
	outdoor_furniture: SvgOutdoorFurniture,
	outdoor_shower: SvgOutdoorShower,
	outlet_covers: SvgOutletCovers,
	oven: SvgOven,
	pack_and_play_travel_crib: SvgPackAndPlayTravelCrib,
	paid_parking_off_premises: SvgPaidParkingoffPremises,
	paid_parking_on_premises: SvgPaidParkingOnPremises,
	parties_allowed: SvgPartiesAllowed,
	patio_or_balcony: SvgPatioOrBalcony,
	pets_allowed: SvgPetsAllowed,
	piano: SvgPiano,
	ping_pong_table: SvgPingPongTable,
	pocket_wifi: SvgPocketWifi,
	pool_table: SvgPoolTable,
	pool: SvgPool,
	portable_fans: SvgPortableFans,
	pour_over_coffee: SvgPourOverCoffee,
	private_entrace: SvgPrivateEntrance,
	record_player: SvgRecordPlayer,
	refrigerator: SvgRefrigerator,
	rice_maker: SvgRiceMaker,
	room_darkening_shades: SvgRoomDarkeningShades,
	safe: SvgSafe,
	sauna: SvgSauna,
	self_checkin_possible: SvgSelfCheckingPossible,
	shampoo: SvgShampoo,
	shower_gel: SvgShowerGel,
	single_level_home: SvgSingleLevelCode,
	ski_in_ski_out: SvgSkiInSkiOut,
	smoke_alarm: SvgSmokeAlarm,
	smoking_allowed: SvgSmokingAllowed,
	sound_system: SvgSoundSystem,
	stove: SvgStove,
	table_corner_guards: SvgTableCornerGuards,
	toaster: SvgToaster,
	trash_compactor: SvgTrashCompactor,
	tv: SvgTV,
	washer: SvgWasher,
	waterfront: SvgWaterfront,
	wifi: SvgWifi,
	window_guards: SvgWindowGuards,
	wine_glasses: SvgWineGlasses,
	cancellation_policy: SvgCancellationPolicy,
	property_type: SvgPropertyType,
	entire_home: SvgEntireHome,
	house_rules: SvgHouseRules,
	lifebuoy: SvgLifebuoy,
	wallet: SvgWallet2,
	wallet_2: SvgWallet2,
	users: SvgUsers2,
	plane: SvgPlane,
	backyard: SvgTransferOut,
	building_staff: SvgBuildingStaff,
	elevator: SvgElevator,
	essentials: SvgEssentials,
	lock_on_bedroom: SvgKey,
	lockbox: SvgLockbox,
	keypad: SvgLockPad,
	long_term_stays_allowed: SvgLongStay,
	luggage_dropoff_allowed: SvgLuggageDropOff,
	host_greets_you: SvgUserCheck
};

export type AvailableSvg = keyof typeof svgMapper;

export const slugToSvg = (slug: AvailableSvg) => {
	return svgMapper[slug] || SvgMissingIcon;
};
