import { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';
import { CustomLabel } from '../CustomLabel/CustomLabel';
export interface ICustomSelectOption {
	label: string;
	value: any;
	disabled?: boolean;
}
export interface ICustomSelectProps extends SelectComponentsProps {
	options?: ICustomSelectOption[];
	labelText?: string;
	showLabel?: boolean;
	className?: string;
	info?: string;
	placeholder?: string;
}

export const CustomSelect: React.FC<ICustomSelectProps> = props => {
	const {
		label,
		id,
		name,
		info,
		errors,
		placeholder,
		className,
		value,
		onChange,
		...rest
	} = props;

	const [innerValue, setInnerValue] = useState<ICustomSelectOption | null>(
		null
	);

	useEffect(() => {
		setInnerValue(value);
	}, [value]);

	const handleOnChange = (value: ICustomSelectOption) => {
		setInnerValue(value);
		onChange && onChange(value);
	};

	return (
		<div className={`${className ? className : ''}`}>
			{label && (
				<CustomLabel className="mb-2" htmlFor={name}>
					{label}
				</CustomLabel>
			)}
			{info && <p className="mb-3">{info}</p>}
			<ReactSelect
				instanceId={id}
				placeholder={placeholder}
				textFieldProps={{
					label: 'Label'
				}}
				value={innerValue}
				// @Todo fix any
				onChange={handleOnChange as any}
				{...rest}
				className="react-select-container"
				classNamePrefix="react-select"
				theme={theme => ({
					...theme,
					colors: {
						...theme.colors,
						primary: 'primary'
					}
				})}
				components={{
					IndicatorSeparator: () => null
				}}
				errors={errors}
				// styles={customStyles}
			/>

			{errors && <CustomSelectFeedback error={errors} />}
		</div>
	);
};

const CustomSelectFeedback = ({ error }: { error: string }) =>
	error ? <p className="text-red mt-3">{error}</p> : null;
