import { Field } from 'formik';
import { CustomInput, ICustomInputProps } from '../../';

export const FieldInput: React.FC<ICustomInputProps> = props => {
	const { name, ...rest } = props;
	return (
		<Field name={name}>
			{(props: any) => {
				const {
					field: { name, value, onChange }
				} = props;
				return (
					<CustomInput
						name={name}
						value={value}
						onChange={e => onChange(e)}
						{...rest}
					/>
				);
			}}
		</Field>
	);
};
