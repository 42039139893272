export const ordersKeys = {
	all: () => ['orders'] as const,
	lists: () => [...ordersKeys.all(), 'list'] as const,
	list: (filters: any) => [...ordersKeys.lists(), { ...filters }] as const,
	details: (orderToken: string) =>
		[...ordersKeys.all(), 'detail', orderToken] as const,
	preview: (orderToken: string, filters: any) =>
		[...ordersKeys.all(), 'preview', orderToken, { ...filters }] as const,
	stripeToken: (orderToken: string) =>
		[...ordersKeys.all(), 'stripe', orderToken] as const
};
