import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
	HydraChannelQueryParam,
	HydraPaginationQueryParams
} from '../interfaces';
import { IPriceRangeRead } from './interfaces/priceRangeRead';

interface IGetPriceRangeRequestConfig extends AxiosRequestConfig {
	params?: IGetPriceRangeQueryParams;
}

export type IGetPriceRangeQueryParams = HydraChannelQueryParam &
	HydraPaginationQueryParams & {
		type?: string;
		location?: string;
		tripDates?: string;
		amenities?: string;
		priceFrom?: string;
		priceTo?: string;
		geolocation?: string; // Geography area in rectangle shape, formatted as SW_lat,SW_long,NE_lat,NE_long. Same format is used by Google Maps JS API method google.maps.Rectangle.getBounds.toUrlValue.
		'numberOfGuests[between]'?: string;
		'numberOfGuests[gt]'?: string;
		'numberOfGuests[gte]'?: string;
		'numberOfGuests[lt]'?: string;
		'numberOfGuests[lte]'?: string;
		'numberOfInfants[between]'?: string;
		'numberOfInfants[gt]'?: string;
		'numberOfInfants[gte]'?: string;
		'numberOfInfants[lt]'?: string;
		'numberOfInfants[lte]'?: string;
		'numberOfBedrooms[between]'?: string;
		'numberOfBedrooms[gt]'?: string;
		'numberOfBedrooms[gte]'?: string;
		'numberOfBedrooms[lt]'?: string;
		'numberOfBedrooms[lte]'?: string;
		'numberOfBeds[between]'?: string;
		'numberOfBeds[gt]'?: string;
		'numberOfBeds[gte]'?: string;
		'numberOfBeds[lt]'?: string;
		'numberOfBeds[lte]'?: string;
		'numberOfBaths[between]'?: string;
		'numberOfBaths[gt]'?: string;
		'numberOfBaths[gte]'?: string;
		'numberOfBaths[lt]'?: string;
		'numberOfBaths[lte]'?: string;
	};

export const getPriceRange = async (config?: IGetPriceRangeRequestConfig) => {
	return await axios
		.get<IPriceRangeRead>(`/api/v2/shop/accommodations/price-range`, config)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting price-range:`, error);
			return Promise.reject(error);
		});
};

export function useGetPriceRangeQuery(
	config?: IGetPriceRangeRequestConfig,
	options?: IGetPriceRangeQueryOptions
) {
	return useQuery(
		['accommodations-price-range', ...Object.values(config?.params || {})],
		() => {
			return getPriceRange(config);
		},
		{ ...(defaultOptions as any), ...options }
	);
}

export type IGetPriceRangeQueryOptions = UseQueryOptions<
	IPriceRangeRead,
	any,
	IPriceRangeRead
>;

const defaultOptions: IGetPriceRangeQueryOptions = {
	refetchOnWindowFocus: false
};
