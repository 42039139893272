import * as Sentry from '@sentry/nextjs';
import { NextApiRequest } from 'next';
import { IncomingMessage } from 'http';

import {
	convertRequestBodyToJSONbject,
	isNextApiRequest
} from 'litto-lib/utils';
import { GenericObject } from 'litto-lib/types';

/**
 * Configures the Sentry scope by extracting useful tags and context from the given request.
 *
 * https://www.npmjs.com/package/@sentry/nextjs
 */
export const configureReq = (
	req: NextApiRequest | IncomingMessage,
	tags?: { [key: string]: string },
	contexts?: { [key: string]: any }
): void => {
	let parsedBody: GenericObject = {};
	try {
		if (isNextApiRequest(req)) {
			parsedBody = convertRequestBodyToJSONbject(req);
		}
	} catch (e) {
		// eslint-disable-next-line no-console
		// console.error(e);
	} // Do nothing, as "body" is not necessarily supposed to contain valid stringified JSON

	Sentry.configureScope(scope => {
		scope.setTag('host', req?.headers?.host);
		scope.setTag('url', req?.url);
		scope.setTag('method', req?.method);
		scope.setContext('headers', req?.headers);
		scope.setContext('parsedBody', parsedBody);

		if (isNextApiRequest(req)) {
			scope.setExtra('query', req?.query);
			scope.setExtra('body', req?.body);
			scope.setExtra('cookies', req?.cookies);
		}

		Object.entries(tags || {}).map(([tag, value]) => {
			scope.setTag(tag, value);
		});

		Object.entries(contexts || {}).map(([context, value]) => {
			scope.setContext(context, value);
		});
	});
};
