import {
	Button,
	CloudinaryImage,
	SkeletonText
} from 'litto-lib/components/common';
import { getInitials, truncateText } from 'litto-lib/utils';
import React from 'react';

import { ILanguage } from 'services/shop/accommodation/interfaces';

export interface IPersonProps {
	imgSrc?: string | null;
	name: string;
	title?: string;
	info?: string | null;
	languages?: ILanguage[];
	className?: string;
	size?: 'md' | 'lg';
}

const sizes = {
	md: 'w-12.5 h-12.5',
	lg: 'w-25 h-25'
};

export const PersonDisplay: React.FC<IPersonProps> = ({
	imgSrc,
	name,
	title,
	info,
	className,
	languages,
	size = 'md'
}) => {
	const [showMore, setShowMore] = React.useState(false);

	return (
		<div className={`${className ? className : ''} flex`}>
			<div
				className={`${sizes[size]} ${
					imgSrc ? 'bg-pulse' : ''
				} relative flex flex-none items-center justify-center overflow-hidden rounded-full bg-brand`}
			>
				{imgSrc && (
					<CloudinaryImage
						src={imgSrc}
						alt={name}
						layout="fill"
						objectFit="cover"
					/>
				)}
				<span
					className={`${
						size === 'md' ? 'text-md' : 'text-3xl'
					} font-bold text-primary-700`}
				>
					{getInitials(name)}
				</span>
			</div>
			<div className="ml-5 w-full">
				<p className="mb-1 font-extrabold">{name}</p>
				{title && <p className="text-base text-gray-300">{title}</p>}
				{languages && (
					<span className="-mt-1 block text-sm text-gray-400">
						Languages: {languages.map(i => i?.name).join(', ')}
					</span>
				)}
				{info && (
					<p className="mt-6 whitespace-pre-wrap text-md">
						{showMore ? info : truncateText(info, 200)}
					</p>
				)}
				{info && info?.length > 200 && (
					<Button
						text={`${showMore ? 'Show Less' : 'Show More'}`}
						size="sm"
						className="mt-4"
						color="primary"
						onClick={() => {
							setShowMore(prevState => !prevState);
						}}
					/>
				)}
			</div>
		</div>
	);
};

interface IPersonSkeletonProps {
	className?: string;
	size?: 'md' | 'lg';
}

export const PersonSkeleton = ({
	className,
	size = 'md'
}: IPersonSkeletonProps) => (
	<div className={`${className ? className : ''} flex animate-pulse`}>
		<div
			className={`${sizes[size]} bg-pulse relative flex-none overflow-hidden rounded-full bg-gray-300`}
		/>
		<div className="ml-5 w-full">
			<SkeletonText className="mb-1 font-extrabold" />
			<SkeletonText className="text-base" />
		</div>
	</div>
);
