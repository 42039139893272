import { ActiveLink } from 'litto-lib/components/common';
import { LinkProps } from 'next/link';
import React from 'react';

import styles from './HeaderLink.module.scss';

export type IHeaderLinkProps = IHeaderLinkBaseProps &
	(IHeaderLinkButtonProps | IHeaderLinkLinkProps);
interface IHeaderLinkBaseProps {
	className?: string;
	linkColor?: IHeaderLinkColor;
	children: React.ReactNode;
	indicatorPosition?: IHeaderLinkIndicatorPosition;
	contentClassName?: string;
	isActive?: boolean;
}

type IHeaderLinkButtonProps = {
	onClick?: () => void;
};

type IHeaderLinkLinkProps = LinkProps;

export type IHeaderLinkIndicatorPosition = 'top' | 'bottom';
export type IHeaderLinkColor = 'white' | 'default';

const colors: { [key in IHeaderLinkColor]: Record<string, string> } = {
	default: {
		default: 'text-black hover:text-gray-500',
		active: 'text-primary-500 hover:primary-700'
	},
	white: {
		default: 'text-white hover:text-gray-100',
		active: 'text-primary-500 hover:primary-700'
	}
};

export const HeaderLink: React.FC<IHeaderLinkProps> = ({
	className = '',
	linkColor = 'default',
	indicatorPosition = 'bottom',
	children,
	isActive,
	contentClassName,
	...otherProps
}) => {
	const content = (
		<>
			<div className="h-1" />
			<span
				className={`${colors[linkColor][isActive ? 'active' : 'default']} ${
					contentClassName || ''
				}`}
			>
				{children}
			</span>
			<div
				className={`${
					styles.activeIndicator
				} lg:h-0.5 lg:w-full h-10 w-1 rounded-r-full lg:rounded-t-full bg-brand transition-opacity
			${!isActive ? 'opacity-0 group-hover:opacity-100' : ''}
            `}
			/>
		</>
	);

	const classes = `
			${indicatorPosition == 'top' ? 'lg:flex-col-reverse' : 'lg:flex-col'}
			group
			flex-row-reverse
			flex items-center justify-between
			text-lg lg:text-sm text-gray-500 font-bold
			cursor-pointer
			h-full no-underline
		`;

	if ('onClick' in otherProps) {
		const { onClick } = otherProps;
		return (
			<li className={className || ''}>
				<button className={classes} {...(onClick && { onClick: onClick })}>
					{content}
				</button>
			</li>
		);
	} else if ('href' in otherProps) {
		const { href, as } = otherProps;
		return (
			<li className={className || ''}>
				<ActiveLink
					href={href}
					as={as}
					activeClassName={styles.isActive + ' pointer-events-none'}
				>
					<a className={classes}>{content}</a>
				</ActiveLink>
			</li>
		);
	} else {
		return (
			<li className={className || ''}>
				<span className={classes}>{content}</span>
			</li>
		);
	}
};
