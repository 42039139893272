/* eslint-disable no-mixed-spaces-and-tabs */
import React, { ChangeEvent, PropsWithChildren } from 'react';
import replaceDiacritics from 'replace-diacritics';

import { CustomSearch, ICustomSearchProps } from '..';
import { SvgArrowIcon } from 'litto-lib/assets';

import { useOnClickOutside } from 'litto-lib/hooks';
import {
	classValueMap,
	generateClassNames,
	getValueFromObjectKeyString
} from 'litto-lib/utils';

export type ICustomAutoCompleteSelectProps<T> =
	React.HTMLAttributes<HTMLDivElement> & {
		options: T[];
		defaultOptions?: T[];
		isOpen?: boolean;
		setIsOpen?: (arg0: boolean) => void;
		customSearchElement?: React.FC<
			ICustomSearchProps & {
				selected?: boolean;
				onClear: () => void;
				// setIsOpen?: (arg0: boolean) => void;
			}
		>;
		searchElementProps: ICustomSearchProps;
		setSearchTerm: (arg0: string) => void;
		onSelectOption: (arg0: T) => void;
		keyToSearchBy: string;
		optionDisplayValueKey: string;
		paddingSize?: classValueMap;
	} & Pick<IResultItemProps, 'data-options'>;

export const CustomAutoCompleteSelect = <T,>({
	options,
	defaultOptions,
	className,
	keyToSearchBy,
	customSearchElement,
	optionDisplayValueKey,
	onSelectOption,
	searchElementProps,
	isOpen,
	setIsOpen,
	paddingSize = { DEFAULT: 5, md: 12, lg: 6 },
	setSearchTerm,
	'data-options': dataOptions
}: PropsWithChildren<ICustomAutoCompleteSelectProps<T>>) => {
	const clickOutsideRef = React.useRef<HTMLDivElement>(null);
	useOnClickOutside(clickOutsideRef, () => setIsOpen && setIsOpen(false));
	const CustomSearchElement = customSearchElement;

	const searchRef = React.useRef<HTMLInputElement>(null);
	const resultsRef = React.useRef<HTMLUListElement>(null);

	React.useEffect(() => {
		isOpen && searchRef.current && searchRef.current?.focus();

		!customSearchElement && setSearchTerm('');
	}, [isOpen]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
		setSearchTerm(e.target.value);

	const handleClear = () => {
		setSearchTerm('');
		setTimeout(() => searchRef.current?.focus());
	};

	const handleFocus = () => {
		setIsOpen && setIsOpen(true);
	};

	const handleBlur = () => {
		setIsOpen && setIsOpen(false);
	};

	const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			(resultsRef.current?.firstChild?.firstChild as HTMLElement)?.focus();
		}
	};

	const handleOptionKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
			e.preventDefault();

			const nextElementToFocus = (e.target as HTMLElement).parentElement?.[
				`${e.key === 'ArrowDown' ? 'next' : 'previous'}ElementSibling`
			]?.firstChild as HTMLElement;

			if (nextElementToFocus) {
				nextElementToFocus.focus();
			} else {
				searchRef.current?.focus();
			}
		}
	};

	return (
		<div
			ref={clickOutsideRef}
			className={`relative z-10 flex h-full w-full flex-col md:h-auto xl:w-auto ${className}`}
		>
			{CustomSearchElement ? (
				<CustomSearchElement
					innerRef={searchRef}
					onKeyDown={handleSearchKeyDown}
					onClear={handleClear}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					selected={isOpen}
					{...searchElementProps}
				/>
			) : (
				<div className={generateClassNames('px', paddingSize)}>
					<CustomSearch
						innerRef={searchRef}
						inputBorder={false}
						{...searchElementProps}
						onChange={handleChange}
						onKeyDown={handleSearchKeyDown}
						onClear={handleClear}
					/>
				</div>
			)}
			{(isOpen || !customSearchElement) && (
				<div
					className={`custom-scroll-styles z-10 h-full w-full overflow-y-auto overflow-x-hidden py-4 md:h-[262px] xl:h-auto xl:max-h-[262px] xl:w-auto ${
						customSearchElement
							? 'absolute top-full left-0 mt-1 rounded-lg bg-white shadow'
							: ''
					}  `}
				>
					{!searchElementProps.value && (
						<p className="px-6 py-2 mb-1 text-xs text-gray-300 uppercase md:px-12 lg:px-6">
							Suggestions
						</p>
					)}
					<ul
						tabIndex={-1}
						ref={resultsRef}
						className={`xl:w-64 ${generateClassNames('px', paddingSize)}`}
					>
						{filterSearchResults(
							searchElementProps.value || '',
							options,
							keyToSearchBy,
							optionDisplayValueKey,
							defaultOptions ? defaultOptions : [],
							(option: T) => {
								onSelectOption(option);
							},
							handleOptionKeyDown,
							paddingSize,
							dataOptions
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

const filterSearchResults = <T,>(
	searchTerm: string,
	options: T[],
	optionDisplayValueKey: string,
	keyToSearchBy: string,
	defaultOptions: T[],
	onSelectOption: (arg0: T) => void,
	handleOptionKeyDown: (arg0: React.KeyboardEvent<HTMLElement>) => void,
	paddingSize: classValueMap,
	dataOptions?: IResultItemProps['data-options']
) => {
	const filteredResults = options
		.filter(option =>
			replaceDiacritics(getValueFromObjectKeyString(option, keyToSearchBy))
				.toLowerCase()
				.includes(replaceDiacritics(searchTerm).toLowerCase())
		)
		.slice(0, 5);

	const optionsToDisplay = defaultOptions.length
		? defaultOptions.map(defaultOption =>
				options.find(option => {
					return (
						getValueFromObjectKeyString(option, keyToSearchBy) ==
						getValueFromObjectKeyString(defaultOption, keyToSearchBy)
					);
				})
		  )
		: options;

	const foundItems = (
		searchTerm.length > 0 ? filteredResults : optionsToDisplay
	).map(option => {
		const value = option
			? getValueFromObjectKeyString(option, optionDisplayValueKey)
			: '';

		return (
			<ResultItem
				key={value}
				className={generateClassNames('-mx px', paddingSize)}
				text={value}
				onKeyDown={handleOptionKeyDown}
				onClick={() => {
					option && onSelectOption(option);
				}}
				data-options={dataOptions}
			/>
		);
	});

	return foundItems.length ? (
		foundItems
	) : (
		<p className="text-sm text-gray-500">Sorry, no results</p>
	);
};

export interface IResultItemProps
	extends React.HTMLAttributes<HTMLButtonElement> {
	key?: any;
	icon?: React.ReactNode;
	text: string;
	arrow?: boolean;
	'data-options'?: any;
}

const ResultItem: React.FC<IResultItemProps> = ({
	className,
	icon,
	text,
	arrow,
	onClick,
	onKeyDown,
	'data-options': dataOptions
}) => {
	return (
		<li
			className={`${
				className || ''
			} group  flex items-center text-base focus-within:bg-gray-50 focus-within:text-gray-500 hover:bg-gray-50 hover:text-gray-500`}
			{...dataOptions}
		>
			<button
				tabIndex={-1}
				onClick={onClick}
				{...(onKeyDown ? { onKeyDown: onKeyDown } : {})}
				className="flex w-full px-0 py-2 outline-none inherit-all"
				{...dataOptions}
			>
				{icon && icon}
				<span
					className="transition transform group-hover:text-primary-500 group-hover:underline"
					{...dataOptions}
				>
					{text}
				</span>
				{arrow && (
					<SvgArrowIcon
						className="ml-auto -rotate-90 transform transition delay-75 group-hover:translate-x-0.5"
						{...dataOptions}
					/>
				)}
			</button>
		</li>
	);
};
