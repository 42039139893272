import React, { useState, useEffect } from 'react';
import { CustomLabel } from '../';
import { SvgClear, SvgSearchIcon } from 'litto-lib/assets';

export interface ICustomSearchProps
	extends React.HTMLAttributes<HTMLInputElement> {
	id: string;
	name: string;
	value?: string;
	label?: string;
	placeholder?: string;
	className?: string;
	inputBorder?: boolean;
	error?: string;
	info?: string;
	rounded?: 'default' | 'lg' | 'full';
	innerRef?: React.RefObject<HTMLInputElement>;
	onClear?: () => void;
}

export const CustomSearch: React.FC<ICustomSearchProps> = props => {
	const {
		id,
		name,
		className,
		inputBorder = true,
		label,
		value,
		placeholder = 'search',
		onChange,
		onBlur,
		onFocus,
		error,
		rounded = 'default',
		info,
		innerRef,
		onKeyDown,
		onClear
	} = props;
	const [innerValue, setInnerValue] = React.useState<string | undefined>('');
	const [isFocused, setIsFocused] = useState<boolean>(false);

	useEffect(() => {
		setInnerValue(value);
	}, [value]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInnerValue(e.target.value);
		onChange && onChange(e);
	};

	const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true);
		onFocus && onFocus(e);
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(false);
		onBlur && onBlur(e);
	};

	const handleClear = () => {
		innerRef?.current?.focus();
		setInnerValue('');
		onClear && onClear();
	};

	const roundedClasses: any = {
		default: 'rounded',
		lg: 'rounded-lg',
		full: 'rounded-full'
	};

	return (
		<div className={`relative ${className ? className : ''}`}>
			{label && (
				<CustomLabel className="mb-2" htmlFor={` ${name}`}>
					{' '}
					{label}
				</CustomLabel>
			)}
			{info && <p className="mb-3 text-base">{info}</p>}

			<input
				ref={innerRef}
				autoComplete="off"
				className={`hide-clear w-full pl-5 pr-10 ${
					inputBorder
						? 'border border-solid border-gray-300 placeholder-gray-400'
						: 'border-none'
				} h-12 bg-gray-50 text-base placeholder-gray-300 outline-none ${
					roundedClasses[rounded]
				}`}
				id={id}
				type="search"
				name={` ${name}`}
				value={innerValue}
				onChange={handleOnChange}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
			/>
			<button
				{...(!innerValue ? { tabIndex: -1 } : {})}
				onClick={handleClear}
				type="button"
				className="pin-r absolute mr-5 flex text-black outline-none transition-opacity focus-visible:opacity-50"
			>
				{innerValue ? (
					<SvgClear className="svg-no-prefill" />
				) : (
					<SvgSearchIcon />
				)}
			</button>
			{error && <InputFeedback error={error} />}
		</div>
	);
};

const InputFeedback = ({ error }: { error: string }) =>
	error ? <p className="text-red mt-3 text-sm font-bold">{error}</p> : null;
