import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

import { locationKeys } from './queryKeys';
import {
	HydraCollectionResponse,
	HydraPaginationQueryParams,
	ILocationRead
} from '../interfaces';

interface IGetLocationsConfig extends AxiosRequestConfig {
	params?: IGetLocationsQueryParams;
}

export type IGetLocationsQueryParams = HydraPaginationQueryParams & {
	homepageHighlighted?: boolean;
};

// temporary hardcoding the itemsPerPage and sorting the locations by priority

export const getLocations = async (
	config?: IGetLocationsConfig
): Promise<HydraCollectionResponse<ILocationRead>> => {
	return await axios
		.get(`/api/v2/shop/locations`, addDefaultsToConfig(config))
		.then(response => {
			const preparedResponse =
				response.data as HydraCollectionResponse<ILocationRead>;

			preparedResponse['hydra:member'] = sortLocationsByPriority(
				preparedResponse['hydra:member']
			);
			return Promise.resolve(preparedResponse);
		})
		.catch(error => {
			console.error('Error getting locations: ', error);
			return Promise.reject(error);
		});
};

const defaultOptions: IUseGetLocationsQueryOptions = {
	refetchOnWindowFocus: false
};

type IUseGetLocationsQueryOptions = UseQueryOptions<
	HydraCollectionResponse<ILocationRead>,
	unknown,
	HydraCollectionResponse<ILocationRead>
>;

export function useGetLocationsQuery(
	config?: IGetLocationsConfig,
	options?: IUseGetLocationsQueryOptions
) {
	return useQuery(
		locationKeys.list(config?.params),
		() => {
			return getLocations(config);
		},
		{
			...(defaultOptions as any),
			...options
		}
	);
}

function addDefaultsToConfig(config?: IGetLocationsConfig) {
	return {
		...(config || {}),
		params: addDefaultsToParams(config?.params)
	};
}

export function addDefaultsToParams(params?: IGetLocationsQueryParams) {
	return {
		itemsPerPage: 100,
		...params
	};
}

function sortLocationsByPriority(locations: ILocationRead[]) {
	return locations.sort((a, b) => {
		const tempCustomOrder: Record<string, number> = {
			Zagreb: 1,
			Split: 2,
			'Hvar Island': 3
		};
		return (
			(tempCustomOrder[a.translation.name] || 999) -
			(tempCustomOrder[b.translation.name] || 999)
		);
	});
}
