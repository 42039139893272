/* eslint-disable no-console */
import React, { Reducer } from 'react';

export function useReducerWithLogger<R extends Reducer<any, any>>(
	reducerFn: R,
	initialState: any,
	enableLogger: boolean
) {
	const [state, dispatch] = React.useReducer(reducerFn, initialState);

	const preState: any = React.useRef();

	const dispatchWithLogging: any = React.useCallback((action: any) => {
		if (typeof action === 'function') {
			return action(dispatchWithLogging, () => preState.state);
		}
		const actionType = typeof action === 'object' ? action.type : action;

		preState.current = { ...preState.current, actionType, action };

		dispatch(action);
	}, []);

	const customDispatch = enableLogger ? dispatchWithLogging : dispatch;

	React.useMemo(
		function logStateAfterChange() {
			if (!enableLogger || !preState.current) {
				return;
			}

			const { actionType, state: previousState, action } = preState.current;
			console.groupCollapsed(`${actionType}`);
			console.log('%c Prevous State', 'color: red;', previousState);
			console.log('%c Action', 'color: blue;', action);
			console.log('%c Current State', 'color: green;', state);
			console.groupEnd();
		},
		[state, enableLogger]
	);

	preState.current = { ...preState.current, state };

	return [state, customDispatch];
}
