import { Field, FieldProps } from 'formik';
import { CustomRange, ICustomRangeProps } from '../../';

export interface IFieldRangeProps
	extends Omit<ICustomRangeProps, 'value' | 'onChange'> {
	value?: number | Range;
	onChange?: (value: number | Range) => void;
}

export const FieldRange: React.FC<IFieldRangeProps> = props => {
	const { name, onChange, ...rest } = props;

	return (
		<Field name={name}>
			{(props: FieldProps) => {
				const {
					field: { name, value },
					form: { setFieldValue }
				} = props;
				return (
					<CustomRange
						name={name}
						value={value}
						onChange={e => {
							onChange && onChange(e as any);
						}}
						onChangeComplete={e => {
							setFieldValue(name, e);
						}}
						{...rest}
					/>
				);
			}}
		</Field>
	);
};
