import { set, get } from 'litto-lib/utils';
import { initialOrderState, IOrderState } from 'context';

export function setOrderState(orderState: IOrderState): void {
	set({
		key: 'orderState',
		value: orderState
	});
}

export function getOrderState(): IOrderState {
	try {
		const storedUser: IOrderState = get({ key: 'orderState' });
		return storedUser || initialOrderState;
	} catch (err) {
		return initialOrderState;
	}
}
