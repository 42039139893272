import { useState, useEffect } from 'react';

export function useMediaQuery(query: string) {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const callback = () => {
			setMatches(media.matches);
		};
		media.addEventListener('change', callback);
		return () => media.removeEventListener('change', callback);
	}, [matches, query]);

	return matches;
}
