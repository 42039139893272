import { useEffect, useRef } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

type RefType<T> = {
	current: T;
};

export function useScrollLock<T>(
	enabled = true,
	ref: RefType<T>,
	selector?: string
) {
	const disabledRef = useRef(false);

	useEffect(() => {
		if (enabled && !disabledRef.current) {
			disablePageScroll(
				ref
					? selector
						? (ref as any).current?.querySelector(selector)
						: (ref as any).current
					: undefined
			);
			disabledRef.current = true;
		}

		return () => {
			if (disabledRef.current) {
				enablePageScroll(ref ? (ref as any).current : undefined);
				disabledRef.current = false;
			}
		};
	}, [enabled, ref, disabledRef]);
}
