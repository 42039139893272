import SvgAmericanExpress from './american_express_card.svg';
import SvgArrowIcon from './arrow-icon.svg';
import SvgArrowBack from './arrow_back.svg';
import SvgArrowRight from './arrow_right.svg';
import BlockedIcon from './blocked.svg';
import SvgCalendar from './calendar.svg';
import SvgCheckIcon from './check-icon.svg';
import SvgClear from './clear.svg';
import SvgCloseIcon from './close-icon.svg';
import SvgDinersClub from './diners_club_card.svg';
import SvgDiscover from './discover_card.svg';
import SvgDotsIcon from './dots-icon.svg';
import SvgEdit from './edit.svg';
import SvgExternalLink2 from './external-link-2.svg';
import SvgExternalLink from './external-link.svg';
import SvgFacebook from './facebook.svg';
import SvgFeatureIcon from './feature-item-icon.svg';
import SvgFilters from './filters.svg';
import ICalIcon from './ical.svg';
import SvgInstagram from './instagram.svg';
import SvgLifebuoy from './lifebuoy.svg';
import SvgLinkedin from './linkedin.svg';
import SvgLock from './lock.svg';
import SvgMaestroCard from './maestro_card.svg';
import SvgMapMarker from './marker-map.svg';
import SvgMasterCard from './master_card.svg';
import SvgMenuBtn from './menu-btn.svg';
import SvgNumberOfBaths from './number_of_baths.svg';
import SvgNumberOfBedrooms from './number_of_bedrooms.svg';
import SvgNumberOfBeds from './number_of_beds.svg';
import SvgNumberOfGuests from './number_of_guests.svg';
import SvgPhoto from './photo-icon.svg';
import SvgPin from './pin.svg';
import SvgPlane from './plane.svg';
import SvgPoweredByStripe from './powered_by_stripe.svg';
import SvgSaveIcon from './save-icon.svg';
import SvgSearchIcon from './search-icon.svg';
import SvgSelectArrow from './select_arrows.svg';
import SvgShareIcon from './share-icon.svg';
import SvgStripe from './stripe.svg';
import SvgUnionPay from './union_pay_card.svg';
import SvgUsers from './users.svg';
import SvgUsers2 from './users2.svg';
import SvgVisa from './visa_card.svg';
import SvgWallet from './wallet.svg';
import SvgWallet2 from './wallet2.svg';
import SvgYoutube from './youtube.svg';
import SvgLogo from './litto-logo.svg';

export {
	SvgArrowBack,
	SvgArrowRight,
	SvgSelectArrow,
	SvgFeatureIcon,
	SvgCalendar,
	SvgDotsIcon,
	SvgShareIcon,
	SvgSaveIcon,
	SvgSearchIcon,
	SvgEdit,
	SvgCloseIcon,
	SvgClear,
	SvgMenuBtn,
	SvgCheckIcon,
	SvgArrowIcon,
	SvgNumberOfBaths,
	SvgNumberOfBedrooms,
	SvgNumberOfBeds,
	SvgNumberOfGuests,
	SvgUsers,
	SvgWallet,
	SvgFilters,
	SvgPin,
	SvgExternalLink2,
	SvgExternalLink,
	SvgFacebook,
	SvgInstagram,
	SvgYoutube,
	SvgLinkedin,
	SvgMapMarker,
	SvgPhoto,
	SvgLock,
	BlockedIcon,
	ICalIcon,
	SvgLifebuoy,
	SvgWallet2,
	SvgUsers2,
	SvgPlane,
	SvgStripe,
	SvgVisa,
	SvgUnionPay,
	SvgMasterCard,
	SvgAmericanExpress,
	SvgDiscover,
	SvgDinersClub,
	SvgMaestroCard,
	SvgPoweredByStripe,
	SvgLogo
};
