import axios from 'axios';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { IOrderCheckout } from './interfaces';
import { ordersKeys } from './queryKeys';

export interface ICreateOrderPreviewData {
	productCode: string;
	dateFrom: string | null;
	dateTo: string | null;
	adults: number;
	children: number;
	infants: number;
}

export const createOrderPreview = async (data: ICreateOrderPreviewData) => {
	return await axios
		.post<IOrderCheckout>(`/api/v2/shop/orders/preview`, data)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error creating order preview:`, error);
			return Promise.reject(error);
		});
};

export function useCreateOrderPreviewMutation(queryClient: QueryClient) {
	return useMutation(createOrderPreview, {
		onSuccess: (order, bodyData) => {
			queryClient.setQueryData(
				ordersKeys.preview(order.tokenValue, { ...bodyData }),
				order
			);
		}
	});
}
