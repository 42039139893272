export function get<T>({
	key,
	defaultValue
}: {
	key: string;
	defaultValue?: any;
}): any {
	try {
		const value = window.localStorage.getItem(key);

		if (value === null) {
			return defaultValue;
		}

		return JSON.parse(value) as T;
	} catch (err) {
		return defaultValue;
	}
}

export function set({ key, value }: { key: string; value: any }): boolean {
	try {
		if (value === null || value === undefined) {
			window.localStorage.removeItem(key);
		} else {
			window.localStorage.setItem(key, JSON.stringify(value));
		}

		return true;
	} catch (err) {
		return false;
	}
}
