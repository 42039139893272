import {
	SvgAmericanExpress,
	SvgArrowIcon,
	SvgDinersClub,
	SvgDiscover,
	SvgFacebook,
	SvgInstagram,
	SvgLinkedin,
	SvgLock,
	SvgMaestroCard,
	SvgMasterCard,
	SvgPoweredByStripe,
	SvgUnionPay,
	SvgVisa,
	SvgYoutube
} from 'litto-lib/assets';
import { stripHtml } from 'litto-lib/utils';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import prevalStaticPages from 'preval/staticPages.preval';
import { useGetLocationsQuery } from 'services/shop/location';
import { useGetStaticPageSlugsQuery } from 'services/wordpress/static-page';

interface IFooterProps {
	isMini?: boolean;
}

export const Footer: React.FC<IFooterProps> = ({ isMini = false }) => {
	const { data: cities } = useGetLocationsQuery({
		params: {
			homepageHighlighted: true
		}
	});

	const { data: staticPages } = useGetStaticPageSlugsQuery();

	const footerLittoLinks = React.useMemo(
		() =>
			(prevalStaticPages || staticPages).filter(page => {
				return page?.acf?.static_type === 'litto';
			}),
		[staticPages]
	);

	const footerSupportLinks = React.useMemo(
		() =>
			(prevalStaticPages || staticPages).filter(page => {
				return page?.acf?.static_type === 'support';
			}),
		[staticPages]
	);

	return (
		<footer
			data-testid="footer"
			className={`${isMini ? 'py-7' : 'pt-4 pb-14'} bg-gray-50`}
		>
			<div className="container">
				{isMini == false && (
					<>
						<div className="m-4 mb-12 flex flex-wrap items-center justify-center">
							<a
								aria-label="Facebook"
								data-testid="facebookLink"
								href="https://www.facebook.com/litto.co/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-black no-underline transition-opacity hover:text-primary-500 hover:opacity-75"
							>
								<SvgFacebook className="m-4 h-6 w-6" />
							</a>
							<a
								aria-label="Instagram"
								data-testid="instagramLink"
								href="https://www.instagram.com/litto.co/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-black no-underline transition-opacity hover:text-primary-500 hover:opacity-75"
							>
								<SvgInstagram className="m-4 h-6 w-6" />
							</a>
							<a
								aria-label="Linkedin"
								data-testid="linkedinLink"
								href="https://www.linkedin.com/company/litto-agency/?originalSubdomain=hr"
								target="_blank"
								rel="noopener noreferrer"
								className="text-black no-underline transition-opacity hover:text-primary-500 hover:opacity-75"
							>
								<SvgLinkedin className="m-4 h-6 w-6" />
							</a>
							<a
								aria-label="Youtube"
								data-testid="youtubeLink"
								href="https://www.youtube.com/channel/UC8VW5x1xvPvw2s4pBE7KOcg"
								target="_blank"
								rel="noopener noreferrer"
								className="text-black no-underline transition-opacity hover:text-primary-500 hover:opacity-75"
							>
								<SvgYoutube className="m-4 h-6 w-6" />
							</a>
							<strong className="m-4 font-title text-lg">#litto</strong>
						</div>
						<div className="mb-5 border-b border-b-gray-100 pb-5 lg:hidden">
							<p className="mb-6 text-lg font-bold">Locations</p>
							<div className="sm:columns-2">
								{cities &&
									cities['hydra:member'].map(city => (
										<Link key={city.code} href={`/${city.code}` || '/'}>
											<a className="block text-base leading-7 text-black no-underline hover:underline">
												{city.translation.name}
											</a>
										</Link>
									))}
								<Link href="/locations">
									<a className="styled-link styled-link--gray">
										See all locations
									</a>
								</Link>
							</div>
						</div>
						<div className="flex-wrap sm:flex md:mb-10 lg:space-x-5">
							{footerLittoLinks && (
								<FooterDropdown
									className="flex-3"
									data-testid="litto-links"
									title="Litto"
								>
									{footerLittoLinks.map(page => (
										<FooterDropdownItem
											key={page?.slug}
											href={`/help/${page?.slug}` || '/'}
										>
											{stripHtml(page?.title?.rendered)}
										</FooterDropdownItem>
									))}
								</FooterDropdown>
							)}
							{footerSupportLinks && (
								<FooterDropdown
									className="flex-3"
									data-testid="support-links"
									title="Support"
								>
									{footerSupportLinks.map(page => (
										<FooterDropdownItem
											key={page?.slug}
											href={`/help/${page?.slug}` || '/'}
										>
											{stripHtml(page?.title?.rendered)}
										</FooterDropdownItem>
									))}
								</FooterDropdown>
							)}
							<FooterDropdown
								data-testid="locations-links"
								title="Locations"
								className="flex-2 hidden lg:block"
							>
								{cities &&
									cities['hydra:member'].map(city => (
										<FooterDropdownItem
											key={city.code}
											href={`/${city.code}` || '/'}
										>
											{city.translation.name}
										</FooterDropdownItem>
									))}
								<FooterDropdownItem href="/locations">
									<span className="styled-link styled-link--gray">
										See all locations
									</span>
								</FooterDropdownItem>
							</FooterDropdown>
							<div className="lg:flex-4 mx-auto mt-10 justify-end lg:mt-0 lg:flex">
								<div className="max-w-none flex-none space-y-4">
									<div className="flex text-gray-400">
										<SvgLock />
										<p>
											Guaranteed <strong>safe & secure</strong> checkout
										</p>
									</div>
									<div className="flex space-x-2">
										<SvgMaestroCard className="svg-no-prefill" />
										<SvgMasterCard className="svg-no-prefill" />
										<SvgVisa className="svg-no-prefill" />
										<SvgAmericanExpress className="svg-no-prefill" />
										<SvgDinersClub className="svg-no-prefill" />
										<SvgDiscover className="svg-no-prefill" />
										<SvgUnionPay className="svg-no-prefill" />
									</div>
									<SvgPoweredByStripe className="svg-no-prefill" />
								</div>
							</div>
						</div>
					</>
				)}
				<div className="mt-6 items-center justify-between md:mt-0 md:flex">
					<p className="text-sm text-gray-300">
						&copy; {new Date().getFullYear()}. Litto d.o.o.
					</p>
					<p className="mt-4 text-base md:mt-0">
						Made in Split, Croatia <span className="text-xl text-red">♥</span>
						<a
							data-testid="littoLink"
							className="text-black no-underline hover:text-primary-500 hover:underline"
							target="_blank"
							href="https://locastic.com"
							rel="noreferrer"
						>
							Locastic
						</a>
					</p>
				</div>
			</div>
		</footer>
	);
};
interface IFooterDropdownprops {
	title: string;
	'data-testid'?: string;
	className?: string;
}
const FooterDropdown: React.FC<IFooterDropdownprops> = ({
	title,
	'data-testid': dataTestid,
	children,
	className = ''
}) => {
	const mainRef = React.useRef<HTMLDivElement>(null);
	const [isHidden, setIsHidden] = React.useState<boolean>(true);

	return (
		<div
			data-testid={dataTestid}
			ref={mainRef}
			className={`${className} relative mb-4 border-0 border-b border-solid border-gray-100 pb-4 last:border-none sm:w-1/2 lg:mb-0 lg:flex-1 lg:border-none lg:pb-0`}
		>
			<button
				className="w-full cursor-default text-left"
				onClick={() => setIsHidden(!isHidden)}
			>
				<p className="text-lg font-bold md:mb-3">{title}</p>
			</button>
			<SvgArrowIcon
				className={`svg-no-prefill pointer-events-none absolute top-2 right-5 transform transition-all duration-150 md:hidden ${
					!isHidden ? 'rotate-180' : ''
				}`}
			/>
			<ul
				className={`my-4 md:mt-2.5 md:mb-0 ${
					isHidden ? 'hidden' : ''
				} list-none md:block`}
			>
				{children}
			</ul>
		</div>
	);
};

interface IFooterDropdownItem extends Omit<LinkProps, 'href'> {
	href?: string;
}
const FooterDropdownItem: React.FC<IFooterDropdownItem> = ({
	as,
	href,
	shallow,
	children
}) => {
	return (
		<li>
			{href ? (
				<Link as={as} href={href} shallow={shallow}>
					<a className="block text-base leading-7 text-black no-underline hover:underline">
						{children}
					</a>
				</Link>
			) : (
				<span className="block text-base leading-7 text-black">{children}</span>
			)}
		</li>
	);
};
