/* eslint-disable react/display-name */
import {
	Form,
	Formik,
	FormikConfig,
	FormikHelpers,
	FormikProps,
	useFormikContext
} from 'formik';
import { SvgArrowIcon } from 'litto-lib/assets';
import {
	Button,
	CustomDayModifierHandler,
	CustomDayPickerRangeController,
	FieldCount,
	PopperButton
} from 'litto-lib/components/common';
import {
	dontFlipPopper,
	filterDateFormat,
	formatGuests
} from 'litto-lib/helpers';
import { useMediaQuery } from 'litto-lib/hooks';
import { isEmpty } from 'litto-lib/utils';
import moment, { Moment } from 'moment';
import React from 'react';
import { FocusedInputShape } from 'react-dates';

import { GuestRow, IGuestFormProps } from 'components/shared';
import { useGetAccommodationVariantsQuery } from 'services/shop/accommodation';
import { IAccommodationProductVariantRead } from 'services/shop/accommodation/interfaces';

export interface IReservationFormProps
	extends FormikConfig<IReservationFormData>,
		Pick<IGuestFormProps, 'maxGuests' | 'maxInfants'> {
	className?: string;
	accommodationCode: string;
	checkInDate: moment.Moment | null;
	checkOutDate: moment.Moment | null;
	onChange?: (formData: IReservationFormData) => void;
	calendarRef?: any;
	guestsRef?: any;
	formRef?: React.Ref<FormikProps<IReservationFormData>> | null;
	isLoading?: boolean;
}

const calendarOptions = {
	loadMonthsStep: 12,
	// Indicates when to load next {loadMonthsStep}
	// 2 means load next 6 months 2 months before last loaded month
	// eg. if last loaded month is 30-11-2021 load next variants when Semptember is visible
	loadNextMonths: 2
};

export interface IReservationFormData {
	productCode: string;
	dateFrom: string;
	dateTo: string;
	adults: number;
	children: number;
	infants: number;
}

export const ReservationForm: React.FC<IReservationFormProps> = ({
	className,
	initialValues,
	accommodationCode,
	checkInDate,
	checkOutDate,
	onSubmit,
	onChange,
	calendarRef,
	formRef,
	guestsRef,
	maxGuests,
	maxInfants,
	isLoading
}) => {
	const isMobile = useMediaQuery('(max-width: 820px)');
	const [defaultValues] = React.useState(initialValues);
	const [isFirstChange, setIsFirstChange] = React.useState<boolean>(true);

	const [focusedInput, setFocusedInput] =
		React.useState<FocusedInputShape | null>(null);
	const { adults, children, infants } = initialValues;
	const [variantsMap, setVariantsMap] =
		React.useState<Map<string, IAccommodationProductVariantRead>>();
	const [filterDates, setFilterDates] = React.useState<{
		dateAfter: moment.Moment;
		dateBefore: moment.Moment;
	}>({
		dateAfter: moment().clone().startOf('month'),
		dateBefore: moment()
			.add(calendarOptions.loadMonthsStep, 'M')
			.clone()
			.endOf('month')
	});
	const { data: accommodationVariants, refetch: getaccommodationVariants } =
		useGetAccommodationVariantsQuery(
			accommodationCode,
			{
				params: {
					'date[after]': filterDates.dateAfter.format(filterDateFormat),
					'date[before]': filterDates.dateBefore.format(filterDateFormat)
				}
			},
			{
				enabled: false
			}
		);

	React.useEffect(() => {
		if (accommodationCode) {
			getaccommodationVariants();
		}
	}, [filterDates]);

	const minimumNights = React.useMemo(() => {
		if (!variantsMap || !checkInDate) {
			return 0;
		}
		const checkinDateFormatted = checkInDate?.format('YYYY-MM-DD');
		return variantsMap?.get(checkinDateFormatted || '')?.minStay || 0;
	}, [variantsMap, checkInDate]);

	React.useEffect(() => {
		if (
			accommodationVariants &&
			!isEmpty(accommodationVariants?.['hydra:member'])
		) {
			if (!variantsMap) {
				const map = new Map(
					accommodationVariants['hydra:member'].map(variant => [
						variant.formattedDate,
						{ ...variant }
					])
				);
				setVariantsMap(map);
			} else {
				accommodationVariants['hydra:member'].forEach(variant => {
					if (!variantsMap.has(variant.formattedDate)) {
						variantsMap.set(variant.formattedDate, variant);
					}
				});
			}
		}
	}, [accommodationVariants]);

	const onDatesChange = (
		{
			startDate,
			endDate
		}: { startDate: moment.Moment; endDate: moment.Moment },
		formData: IReservationFormData,
		formikHelpers: FormikHelpers<IReservationFormData>
	) => {
		const { setFieldValue } = formikHelpers;

		if (formData.dateFrom && formData.dateTo && focusedInput === 'startDate') {
			calendarRef?.current?.clearEndDate();
			setFieldValue('dateFrom', startDate?.format('YYYY-MM-DD'));
			setFieldValue('dateTo', '');
		} else {
			setFieldValue('dateFrom', startDate?.format('YYYY-MM-DD'));
			setFieldValue('dateTo', endDate?.format('YYYY-MM-DD'));
			if (startDate && endDate) {
				closeCalendar();
			}
		}
	};

	const onFocusChange = (focusedInput: FocusedInputShape | null) => {
		setFocusedInput(focusedInput);
	};

	const clearDates = () => {
		calendarRef?.current?.clearDates();
	};

	const closeCalendar = () => {
		calendarRef?.current?.closeCalendar();
	};

	const setCustomModifier = (
		day: Moment,
		from: string
	): ReturnType<CustomDayModifierHandler> => {
		const currentDay = day?.format('YYYY-MM-DD');
		const variant = variantsMap?.get(currentDay);

		if (!variant) {
			return false;
		}
		if (focusedInput === 'endDate' && from) {
			if (!minimumNights) {
				return false;
			}
			const diff = day.diff(moment(from), 'days');
			return diff < minimumNights && diff !== 0
				? { name: 'violets-min-nights', minimumNights }
				: false;
		}

		const { checkInAvailable, checkOutAvailable, available } = variant;
		if (checkOutAvailable && !available && focusedInput === 'startDate') {
			return { name: 'checkout-only' };
		}
		if (minimumNights && !checkInAvailable && available) {
			return { name: 'violets-min-nights', minimumNights };
		}
		return false;
	};

	const onNextMonthClick = (newCurrentMonth: moment.Moment) => {
		if (
			moment(newCurrentMonth)
				.add(calendarOptions.loadNextMonths, 'M')
				.endOf('month')
				.clone()
				.isSameOrAfter(filterDates.dateBefore)
		) {
			setFilterDates({
				dateAfter: moment(filterDates.dateBefore)
					.add(1, 'M')
					.startOf('month')
					.clone(),
				dateBefore: moment(filterDates.dateBefore)
					.add(calendarOptions.loadMonthsStep, 'M')
					.clone()
					.endOf('month')
			});
		}
	};

	const onReservationFormSubmit = (
		formData: IReservationFormData,
		formikHelpers: FormikHelpers<IReservationFormData>
	) => {
		if (onSubmit) {
			onSubmit(formData, formikHelpers);
		}
	};

	const onReservationFormChange = (values: IReservationFormData) => {
		if (onChange && !isFirstChange) {
			onChange(values);
		}
		setIsFirstChange(false);
	};

	return (
		<Formik
			innerRef={formRef}
			initialValues={defaultValues}
			enableReinitialize
			onSubmit={onReservationFormSubmit}
		>
			{props => {
				const { values } = props;

				// @TODO refactor
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const isDayBlocked = React.useMemo(() => {
					return (day: Moment) => {
						const currentDay = day.format('YYYY-MM-DD');
						const variant = variantsMap?.get(currentDay);
						if (!variant) {
							return true;
						}
						if (focusedInput === 'startDate' || !focusedInput) {
							return (
								(!variant.checkInAvailable &&
									!variant.checkOutAvailable &&
									!variant.available) ||
								day.isBefore(moment().add(-1, 'days'))
							);
						}
						if (focusedInput === 'endDate') {
							const firstUnavaialble =
								variantsMap !== undefined &&
								Array.from(variantsMap.values())?.find(
									variant =>
										moment(variant.formattedDate).isAfter(values.dateFrom) &&
										variant.checkOutAvailable === false &&
										variant.available === false
								);
							if (day.isBefore(moment(values.dateFrom))) {
								return true;
							} else {
								return (
									firstUnavaialble !== undefined &&
									firstUnavaialble &&
									day.isSameOrAfter(moment(firstUnavaialble.formattedDate))
								);
							}
						}
						return false;
					};
				}, [variantsMap, values.dateFrom, values.dateTo, focusedInput]);

				return (
					<Form className={`${className || ''}`}>
						<ReservationFormEvents onChange={onReservationFormChange} />
						<div>
							<CustomDayPickerRangeController
								autoFocusEndDate={!!checkInDate}
								innerRef={calendarRef}
								initialStartDate={checkInDate}
								initialEndDate={checkOutDate}
								offset={[0, 15]}
								placement="bottom"
								initialVisibleMonth={() => {
									return values.dateFrom ? moment(values.dateFrom) : moment();
								}}
								minDate={moment().add(-1, 'days')}
								maxDate={moment().add(2, 'year')}
								closeOnOutsideClick={!isMobile}
								minimumNights={minimumNights}
								setCustomModifier={date =>
									setCustomModifier(date, values.dateFrom)
								}
								isDayBlocked={isDayBlocked}
								showInputs
								onDatesChange={dates => {
									onDatesChange(dates as any, values, props);
								}}
								onFocusChange={onFocusChange}
								onNextMonthClick={onNextMonthClick}
								customStartDateInput={props => {
									return (
										<CustomPopperTrigger
											className="flex-1 overflow-hidden rounded-tl border-t border-l border-r border-gray-300"
											label="Check in"
											placeholder="Add date"
											onClick={props.onClick}
											value={props.value}
											isActive={props.isActive}
										/>
									);
								}}
								customEndDateInput={props => {
									return (
										<CustomPopperTrigger
											className="flex-1 overflow-hidden rounded-tr border-t border-r border-gray-300"
											label="Check out"
											placeholder="End date"
											disabled={!values.dateFrom}
											onClick={props.onClick}
											value={props.value}
											isActive={props.isActive}
										/>
									);
								}}
								slotBottom={
									<div className="flex justify-end">
										<Button
											className="-ml-4"
											type="button"
											text="Clear"
											onClick={clearDates}
											color="transparent"
										/>
										<Button
											onClick={closeCalendar}
											type="button"
											text="Close"
											color="primary"
											data-popper-close
										/>
									</div>
								}
							/>
							{/* <hr className="border-gray-300" /> */}
							<PopperButton
								innerRef={guestsRef}
								offset={[-10, 48]}
								modifiers={[dontFlipPopper('bottom-start')]}
								customToggle={
									<CustomPopperTrigger
										className="flex-1 overflow-hidden rounded-b border border-gray-300"
										dropdown
										label="Guests"
										placeholder="Add date"
										value={
											adults + children + infants >= 1
												? `${formatGuests(adults, children, infants)}`
												: ''
										}
									/>
								}
							>
								<div className="max-w-sm">
									<GuestRow label="Adults" text="Ages 13 or above">
										<FieldCount
											id="adults"
											name="adults"
											min={1}
											max={maxGuests}
											maxDisabled={
												maxGuests ===
												Number(values.adults) + Number(values.children)
											}
										/>
									</GuestRow>
									<GuestRow label="Children" text="Ages 2–12">
										<FieldCount
											id="children"
											name="children"
											min={0}
											max={maxGuests}
											maxDisabled={
												maxGuests ===
												Number(values.adults) + Number(values.children)
											}
										/>
									</GuestRow>
									{!!maxInfants && (
										<GuestRow label="Infants" text="Under 2">
											<FieldCount
												id="infants"
												name="infants"
												min={0}
												max={maxInfants}
											/>
										</GuestRow>
									)}

									<p className="my-5 text-md text-gray-300">{`${maxGuests} guests maximum. Infants don’t count toward the number of guests.`}</p>
								</div>
							</PopperButton>
						</div>
						<Button
							className="mt-4 w-full"
							text={
								values.dateFrom && values.dateTo ? 'Book now' : 'Select dates'
							}
							disabled={isLoading}
							loading={isLoading}
							withArrow
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export type IReservationFormEventsProps = Pick<
	IReservationFormProps,
	'onChange'
>;

const ReservationFormEvents: React.FC<IReservationFormEventsProps> = ({
	onChange
}) => {
	const { values } = useFormikContext<IReservationFormData>();

	React.useEffect(() => {
		if (onChange) {
			onChange(values);
		}
	}, [values]);

	return <></>;
};

interface ICustomPopperTriggerProps {
	className?: string;
	label: string;
	placeholder: string;
	value?: string | null;
	isActive?: boolean;
	disabled?: boolean;
	dropdown?: boolean;
	onClick?: () => void;
}

const CustomPopperTrigger = ({
	className,
	label,
	placeholder,
	value,
	isActive,
	disabled,
	dropdown,
	onClick
}: ICustomPopperTriggerProps) => {
	const [innerValue, setInnerValue] = React.useState<string | null>();
	React.useEffect(() => {
		setInnerValue(value);
	}, [value]);
	return (
		<button
			type="button"
			onClick={onClick}
			className={`${
				className ? className : ''
			} relative w-full p-3 text-left text-base outline-none focus:bg-white focus:shadow-lg active:text-primary-500 ${
				isActive ? 'bg-white shadow-lg' : ''
			} ${disabled ? 'pointer-events-none' : ''} hover:opacity-75 ${
				innerValue ? '' : 'bg-gray-50'
			}`}
		>
			<p
				className={`text-xs font-bold uppercase ${
					disabled ? 'text-gray-400' : 'text-primary-500'
				} font-title`}
			>
				{label}
			</p>
			<p className={`text-md ${innerValue ? '' : 'text-gray-300'}`}>
				{innerValue ? innerValue : placeholder}
			</p>
			{dropdown && (
				<SvgArrowIcon className="pin-r svg-no-prefill trasnform mr-4" />
			)}
		</button>
	);
};
