import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
	HydraChannelQueryParam,
	HydraCollectionResponse,
	HydraPaginationQueryParams
} from '../interfaces';

import { IAccommodationAssociationRead } from './interfaces';

export const getAccommodationAssociations = async (
	code: string,
	config?: IGetAccommodationAssociationsRequestConfig
): Promise<HydraCollectionResponse<IAccommodationAssociationRead>> => {
	return await axios
		.get<HydraCollectionResponse<IAccommodationAssociationRead>>(
			`/api/v2/shop/accommodations/${code}/associations`,
			{ ...config }
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error getting accommodations: ', error);
			return Promise.reject(error);
		});
};

interface IGetAccommodationAssociationsRequestConfig
	extends AxiosRequestConfig {
	params?: HydraChannelQueryParam & HydraPaginationQueryParams;
}

type IGetAccommodationAssociationsQueryConfig = UseQueryOptions<
	HydraCollectionResponse<IAccommodationAssociationRead>,
	unknown,
	HydraCollectionResponse<IAccommodationAssociationRead>
>;

const defaultQueryOptions: IGetAccommodationAssociationsQueryConfig = {
	refetchOnWindowFocus: false
};

export function useGetAccommodationAssociationsQuery(
	code: string,
	config?: IGetAccommodationAssociationsRequestConfig,
	options?: IGetAccommodationAssociationsQueryConfig
) {
	return useQuery(
		['accommodation_associations', code],
		() => {
			return getAccommodationAssociations(code, config);
		},
		{ ...(defaultQueryOptions as any), ...options }
	);
}
