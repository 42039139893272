import React, { useImperativeHandle } from 'react';
import {
	DayPickerRangeController,
	DayPickerRangeControllerShape,
	FocusedInputShape,
	isInclusivelyAfterDay
} from 'react-dates';
import moment from 'moment';

import {
	CustomCalendarDay,
	CustomCalendarNavNext,
	CustomCalendarNavPrev,
	CustomDayModifierHandler
} from '../components';
import {
	Modal,
	IPopperButtonProps,
	PopperButton
} from 'litto-lib/components/common';
import { useMediaQuery } from 'litto-lib/hooks';
import { dontFlipPopper } from 'litto-lib/helpers';

import { SvgArrowIcon } from 'litto-lib/assets';

export type ICustomDayPickerRangeControllerProps = Omit<
	DayPickerRangeControllerShape,
	| 'renderMonthText'
	| 'focusedInput'
	| 'startDate'
	| 'endDate'
	| 'onDatesChange'
	| 'onFocusChange'
> &
	Pick<IPopperButtonProps, 'offset' | 'placement' | 'imperativeRefCallers'> & {
		className?: string;
		innerRef?: ICustomDayPickerRangeRef;
		initialStartDate?: moment.Moment | null;
		initialEndDate?: moment.Moment | null;
		customStartDateInput?: ICustomDayPickerInputProps;
		customEndDateInput?: ICustomDayPickerInputProps;
		displayFormat?: string;
		slotBottom?: React.ReactNode;
		onDatesChange?: (arg: {
			startDate: moment.Moment | null;
			endDate: moment.Moment | null;
		}) => void;
		onFocusChange?: (arg: FocusedInputShape | null) => void;
		autoFocusEndDate?: boolean;
		showInputs?: boolean;
		closeOnOutsideClick?: boolean;
		setCustomModifier?: CustomDayModifierHandler;
	};

export type ICustomDayPickerRangeRef = React.Ref<{
	toggleShowCalendar(): void;
	openCalendar(): void;
	closeCalendar(): void;
	clearStartDate(): void;
	clearEndDate(): void;
	clearDates(): void;
	submitDates(
		cb: (final: {
			startDate: moment.Moment | null;
			endDate: moment.Moment | null;
		}) => void
	): void;
}>;

export type ICustomDayPickerInputProps = (props: {
	date: moment.Moment | null;
	onClick: () => void;
	value: string | null;
	isActive: boolean;
}) => React.ReactNode;

export const CustomDayPickerRangeController: React.FC<
	ICustomDayPickerRangeControllerProps
> = props => {
	const {
		className = '',
		innerRef,
		initialStartDate = null,
		initialEndDate = null,
		customStartDateInput: CustomStartDateInput,
		customEndDateInput: CustomEndDateInput,
		// renderCalendarInfo: renderCalendarInfoProp,
		slotBottom,
		offset,
		placement,
		imperativeRefCallers,
		displayFormat,
		orientation,
		showInputs,
		onDatesChange: onDatesChangeProp,
		onFocusChange: onFocusChangeProp,
		autoFocusEndDate,
		closeOnOutsideClick = false,
		onOutsideClick,
		setCustomModifier,
		// onClose,
		...calendarProps
	} = props;
	const [showDatePicker, setShowDatePicker] = React.useState(false);

	const [startDate, setStartDate] = React.useState(initialStartDate);
	const [endDate, setEndDate] = React.useState(initialEndDate);
	const [focusedInput, setFocusedInput] =
		React.useState<FocusedInputShape | null>(null);
	const [errorMessage, setErrorMessage] = React.useState('');
	const isMobile = useMediaQuery('(max-width: 819px)');

	const popperRef = React.useRef<any>(null);
	const calendarContainerRef = React.useRef<any>(null);

	const toggleCalendar = () => {
		setShowDatePicker(prevState => !prevState);
	};
	const openCalendar = () => {
		setFocusedInput(autoFocusEndDate ? 'endDate' : 'startDate');
		setShowDatePicker(true);
	};
	const closeCalendar = () => {
		setFocusedInput(null);
		setShowDatePicker(false);
	};
	useImperativeHandle(innerRef, () => ({
		toggleShowCalendar: () => {
			toggleCalendar();
		},
		openCalendar: () => {
			openCalendar();
		},
		closeCalendar: () => {
			closeCalendar();
		},
		clearStartDate: () => {
			setStartDate(null);
			// if (onDatesChangeProp) {
			// 	onDatesChangeProp({
			// 		startDate: null,
			// 		endDate
			// 	});
			// }
		},
		clearEndDate: () => {
			setEndDate(null);
			// if (onDatesChangeProp) {
			// 	onDatesChangeProp({
			// 		startDate: startDate,
			// 		endDate: null
			// 	});
			// }
		},
		clearDates: () => {
			setStartDate(null);
			setEndDate(null);
			setFocusedInput('startDate');
			if (onDatesChangeProp) {
				onDatesChangeProp({
					startDate: null,
					endDate: null
				});
			}
		},
		submitDates: cb => {
			cb({ startDate, endDate });
		}
	}));

	React.useEffect(() => {
		if (showDatePicker) {
			popperRef?.current?.openPopper();
		} else {
			popperRef?.current?.closePopper();
		}
	}, [showDatePicker]);

	React.useEffect(() => {
		setStartDate(initialStartDate);
	}, [initialStartDate]);

	React.useEffect(() => {
		setEndDate(initialEndDate);
	}, [initialEndDate]);

	const onDatesChange = ({
		startDate,
		endDate
	}: {
		startDate: moment.Moment | null;
		endDate: moment.Moment | null;
	}) => {
		const { daysViolatingMinNightsCanBeClicked, minimumNights } = calendarProps;
		let doesNotMeetMinNights = false;
		if (
			daysViolatingMinNightsCanBeClicked &&
			startDate &&
			endDate &&
			minimumNights
		) {
			const dayDiff = endDate.diff(
				startDate.clone().startOf('day').hour(12),
				'days'
			);
			doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
		}
		setStartDate(startDate);
		setEndDate(doesNotMeetMinNights ? null : endDate);
		setErrorMessage(
			doesNotMeetMinNights ? ` Minimum stay is ${minimumNights} nights ` : ''
		);
		if (onDatesChangeProp) {
			onDatesChangeProp({
				startDate,
				endDate: doesNotMeetMinNights ? null : endDate
			});
		}
	};

	const onFocusChange = (focusedInput: FocusedInputShape | null) => {
		setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
	};

	React.useEffect(() => {
		if (onFocusChangeProp) {
			onFocusChangeProp(!focusedInput ? 'startDate' : focusedInput);
		}
	}, [focusedInput]);

	const onStartDateFocus = () => {
		setShowDatePicker(true);
		setFocusedInput('startDate');
		popperRef?.current?.openPopper();
		if (showDatePicker && focusedInput === 'startDate') {
			closeCalendar();
		}
	};

	const onEndDateFocus = () => {
		setShowDatePicker(true);
		setFocusedInput('endDate');
		popperRef?.current?.openPopper();
		if (showDatePicker && focusedInput === 'endDate') {
			closeCalendar();
		}
	};

	const startDateString = startDate && startDate.format(displayFormat);
	const endDateString = endDate && endDate.format(displayFormat);

	const renderCalendar = () => {
		return (
			<DayPickerRangeController
				startDate={startDate}
				endDate={endDate}
				focusedInput={focusedInput}
				onDatesChange={onDatesChange}
				onFocusChange={onFocusChange}
				daySize={calendarProps.daySize || isMobile ? 40 : 48}
				orientation={
					orientation || (!isMobile ? 'horizontal' : 'verticalScrollable')
				}
				onOutsideClick={e => {
					onOutsideClick && onOutsideClick(e);
				}}
				navPosition={
					orientation == 'horizontal' ? 'navPositionTop' : 'navPositionBottom'
				}
				{...calendarProps}
				numberOfMonths={isMobile ? 6 : 2}
				renderCalendarDay={props => (
					<CustomCalendarDay
						{...props}
						setCustomModifier={setCustomModifier}
						onlyStartSelected={!!startDate && !endDate}
					/>
				)}
			/>
		);
	};

	const renderInputs = () => {
		return (
			<div className="button-group--horizontal flex w-full">
				{(!CustomStartDateInput && (
					<ICustomCalendarInput
						className="w-1/2"
						label="Check in"
						selected={showDatePicker && focusedInput == 'startDate'}
						onClick={onStartDateFocus}
						placeholder="Add dates"
						value={startDateString}
					/>
				)) ||
					(CustomStartDateInput &&
						CustomStartDateInput({
							date: startDate,
							onClick: onStartDateFocus,
							value: startDateString,
							isActive: focusedInput == 'startDate'
						}))}

				{(!CustomEndDateInput && (
					<ICustomCalendarInput
						className="w-1/2"
						selected={showDatePicker && focusedInput == 'endDate'}
						label="Check out"
						onClick={onEndDateFocus}
						placeholder="Add dates"
						value={endDateString}
					/>
				)) ||
					(CustomEndDateInput &&
						CustomEndDateInput({
							date: endDate,
							onClick: onEndDateFocus,
							value: endDateString,
							isActive: focusedInput == 'endDate'
						}))}
			</div>
		);
	};

	useOnCalendarClickOutside(
		calendarContainerRef,
		() => {
			if (closeOnOutsideClick) {
				closeCalendar();
			}
		},
		[showDatePicker, closeOnOutsideClick]
	);

	const wrapErrorMessage = (children: React.ReactNode, className = '') => (
		<p
			className={`${className} text-primary-500 xs:text-sm text-xs font-bold md:text-base`}
		>
			{children}
		</p>
	);

	return (
		<div ref={calendarContainerRef} className={className}>
			{!isMobile && showInputs && (
				<PopperButton
					className="w-full xl:w-auto"
					innerRef={popperRef}
					placement={placement || 'bottom'}
					imperativeRefCallers={imperativeRefCallers}
					offset={offset || [0, 12]}
					closeOnSelect={false}
					closeOnOutsideClick={false}
					modifiers={[dontFlipPopper(placement || 'bottom')]}
					customToggle={renderInputs()}
					onPopperClick={() => {
						closeCalendar();
					}}
					disableToggle
				>
					{showDatePicker && (
						<React.Fragment>
							{renderCalendar()}
							<div className="-mx-6 flex items-center border-t px-6 pt-3">
								{errorMessage && wrapErrorMessage(errorMessage)}
								{slotBottom && <div className="ml-auto">{slotBottom}</div>}
							</div>
						</React.Fragment>
					)}
				</PopperButton>
			)}

			{isMobile && showInputs && (
				<React.Fragment>
					{renderInputs()}
					<Modal
						noPadding
						innerScrollSelector=".DayPicker_transitionContainer"
						headerText={
							focusedInput == 'startDate' ? 'Check-in date' : 'Check-out date'
						}
						isOpen={focusedInput !== null}
						onRequestClose={closeCalendar}
					>
						{renderCalendar()}
						{slotBottom && (
							<Modal.Footer key="ModalFooter">
								<div className="flex items-center">
									{errorMessage && wrapErrorMessage(errorMessage)}
									<div className="ml-auto">{slotBottom}</div>
								</div>
							</Modal.Footer>
						)}
					</Modal>
				</React.Fragment>
			)}

			{!showInputs && (
				<React.Fragment>
					{renderCalendar()}
					{slotBottom && <>{slotBottom}</>}
				</React.Fragment>
			)}
		</div>
	);
};

// const RenderMonthElement: RenderMonthProps['renderMonthElement'] = props => (
// 	<span className="font-bold text-md font-title">
// 		{props.month.format('MMMM')}
// 	</span>
// );

export const useOnCalendarClickOutside = (
	ref: any,
	handler: () => void,
	deps?: any[]
) => {
	const escapeListener = React.useCallback((e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			handler?.();
		}
	}, []);
	const clickListener = React.useCallback(
		(e: MouseEvent) => {
			const skipClose =
				(e.target as any).getAttribute('data-calendar-skip-close') !== null ||
				((e.target as any)?.parentNode?.getAttribute &&
					(e.target as any)?.parentNode?.getAttribute(
						'data-calendar-skip-close'
					) !== null);
			if (ref && ref.current) {
				if (!skipClose && !ref.current.contains(e.target)) {
					handler?.();
				}
			}
		},
		[ref, ...(deps ? deps : [])]
	);
	React.useEffect(() => {
		document.removeEventListener('click', clickListener);
		document.removeEventListener('keyup', escapeListener);
		document.addEventListener('click', clickListener);
		document.addEventListener('keyup', escapeListener);
		return () => {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
		};
	}, [...(deps ? deps : [])]);
	return ref;
};

interface IICustomCalendarInputProps {
	selected?: boolean;
	className?: string;
	label: string;
	value: string | null;
	placeholder: string;
	onClick?: () => void;
}

const ICustomCalendarInput = ({
	className,
	selected,
	label,
	value,
	placeholder,
	onClick
}: IICustomCalendarInputProps) => (
	<button
		onClick={onClick}
		className={`${className || ''} ${
			selected ? ' shadow-lg ' : ''
		}relative rounded-xl  px-6 py-5 text-left text-base outline-none hover:opacity-75 focus:shadow-lg`}
	>
		<p
			className={`${
				selected ? 'text-primary-500' : ''
			} font-title whitespace-nowrap pr-4 font-bold`}
		>
			{label}
		</p>
		<p
			className={`text-md mt-1 whitespace-nowrap pr-8 xl:mt-0 xl:mb-0 ${
				value ? '' : 'text-gray-300'
			}`}
		>
			{value ? value : placeholder}
		</p>
		<SvgArrowIcon className="pin-r svg-no-prefill mr-8" />
	</button>
);

const defaultProps: ICustomDayPickerRangeControllerProps = {
	// example props
	autoFocusEndDate: false,
	initialStartDate: undefined,
	initialEndDate: undefined,
	startDateOffset: undefined,
	endDateOffset: undefined,
	showInputs: false,
	minDate: null,
	maxDate: null,
	displayFormat: 'MMM DD',
	firstDayOfWeek: 1,

	// day presentation and interaction related props
	// eslint-disable-next-line react/display-name
	renderCalendarDay: props => {
		return <CustomCalendarDay {...props} />;
	},
	renderDayContents: null,
	minimumNights: 1,
	isDayBlocked: () => false,
	isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
	isDayHighlighted: () => false,
	getMinNightsForHoverDate: day => {
		return day.date();
	},
	enableOutsideDays: false,
	daysViolatingMinNightsCanBeClicked: true,

	// calendar presentation and interaction related props
	orientation: undefined,
	verticalHeight: undefined,
	withPortal: false,
	initialVisibleMonth: null,
	onOutsideClick() {},
	keepOpenOnDateSelect: false,
	renderCalendarInfo: null,
	isRTL: false,
	// renderMonthText: null,
	renderMonthElement: null,
	renderKeyboardShortcutsButton: undefined,
	renderKeyboardShortcutsPanel: undefined,

	// navigation related props
	navPrev: <CustomCalendarNavPrev />,
	navNext: <CustomCalendarNavNext />,
	renderNavPrevButton: null,
	renderNavNextButton: null,
	onPrevMonthClick() {},
	onNextMonthClick() {},

	// internationalization
	monthFormat: 'MMMM YYYY'
};

CustomDayPickerRangeController.defaultProps = { ...defaultProps };
