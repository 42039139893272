import { getImageUrl, isEmpty, range } from 'litto-lib/utils';
import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useContainerQueries } from 'use-container-queries';

import { OverlayedCard } from 'components/shared';
import { Card, SkeletonCard } from 'components/shared/Card';
import { ILocationRead } from 'services/shop/interfaces';

SwiperCore.use([Pagination, Navigation]);

const settings: SwiperProps = {
	spaceBetween: 0,
	slidesPerView: 'auto',
	navigation: true,
	observeParents: true,
	pagination: {
		type: 'bullets',
		clickable: true
	}
};
interface ILocationSliderProps {
	className?: string;
	cities?: ILocationRead[];
	itemComponent?: 'OverlayedCard' | 'Card';
}

export const LocationSlider: React.FC<ILocationSliderProps> = ({
	className = '',
	cities,
	itemComponent = 'card'
}) => {
	const { active, ref } = useContainerQueries({
		breakpoints: {
			sm: [0, 1023],
			lg: [1024]
		}
	});

	const shouldShowSlider = React.useMemo(() => {
		return (cities ? cities.length : 4) > (active == 'sm' ? 1 : 4);
	}, [active, cities?.length]);

	const itemClasses =
		itemComponent == 'card'
			? 'w-11/12 xs:w-10/12 sm:w-7/12 md:w-5/12 lg:w-1/4 p-2.5'
			: 'w-11/12 sm:w-7/12 md:w-5/12 lg:w-1/3 p-2.5';

	const SwiperContent = React.useCallback(
		({ city }) => {
			if (isEmpty(cities)) {
				if (itemComponent == 'card') {
					return (
						<SkeletonCard
							meta={false}
							info={false}
							className="w-full"
							aspectRatio="7x9"
						/>
					);
				} else {
					return (
						<div className="aspect-w-7 aspect-h-8 animate-pulse bg-gray-200" />
					);
				}
			} else {
				if (itemComponent == 'card') {
					return (
						<Card
							className="w-full"
							aspectRatio="7x9"
							title={city.translation.name}
							href={`/${city.code}`}
							imgSrc={getImageUrl(city.mainImage?.imagePath)}
							sizes="(min-width: 1200px) 25vw, (min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw"
						/>
					);
				} else {
					return (
						<OverlayedCard
							href={`/${city.code}`}
							className="w-full"
							title={city.translation.name}
							meta={city.translation.title}
							imgSrc={getImageUrl(city.mainImage?.imagePath)}
							sizes="(min-width: 1200px) 25vw, (min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw"
						/>
					);
				}
			}
		},
		[cities?.length, itemComponent]
	);

	return (
		<div
			ref={ref}
			data-testid="locationSlider"
			className={`container overflow-x-hidden pb-12 ${className}`}
		>
			{shouldShowSlider ? (
				<Swiper
					className={`overflow-visible-arrows-only -mx-2.5`}
					{...settings}
				>
					{(cities || range(0, 4)).map(city => (
						<SwiperSlide
							key={typeof city == 'number' ? city : city.translation.name}
							className={itemClasses}
						>
							<SwiperContent city={city} />
						</SwiperSlide>
					))}
				</Swiper>
			) : (
				<div className="-mx-2.5 flex">
					{(cities || range(0, 4)).map(city => (
						<div
							key={typeof city == 'number' ? city : city.translation.name}
							className={itemClasses}
						>
							<SwiperContent city={city} />
						</div>
					))}
				</div>
			)}
		</div>
	);
};
