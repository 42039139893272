import { ICookieState } from './cookieContext';
import { createAction } from 'reducers';

export enum CookieActions {
	show_banner = '[CookieContext] show banner',
	hide_banner = '[CookieContext] hide banner',
	toggle_banner = '[CookieContext] toggle banner',
	toggle_banner_expanded = '[CookieContext] toggle banner expanded',
	accept_all = '[CookieContext] accept all cookies',
	toggle_group = '[CookieContext] toggle cookie group'
}

export type CookieActionTypes =
	| IShowBanner
	| IHideBanner
	| IToggleBanner
	| IToggleBannerExpanded
	| IAcceptAll
	| IToggleGroup;

export const CookieReducer = (
	state: ICookieState,
	action: CookieActionTypes
) => {
	switch (action.type) {
		case CookieActions.show_banner: {
			return {
				...state,
				isShown: true
			};
		}
		case CookieActions.hide_banner: {
			return {
				...state,
				isShown: false
			};
		}
		case CookieActions.toggle_banner: {
			return {
				...state,
				isShown: !state.isShown
			};
		}
		case CookieActions.toggle_banner_expanded: {
			return {
				...state,
				isExpanded: !state.isExpanded
			};
		}
		case CookieActions.accept_all: {
			return {
				isShown: false,
				isExpanded: false,
				levels: {
					necessary: true,
					analytics: true,
					marketing: true
				}
			};
		}
		case CookieActions.toggle_group: {
			const updatedLevel = {} as any;
			updatedLevel[action.payload] = !state.levels[action.payload];

			return {
				...state,
				levels: {
					...state.levels,
					...updatedLevel
				}
			};
		}

		default: {
			throw new Error('Cookie reducer: This reducer action does not exist');
		}
	}
};

interface IShowBanner {
	type: typeof CookieActions.show_banner;
	payload: boolean;
}
export const showBanner = createAction<IShowBanner['payload']>(
	CookieActions.show_banner
);

interface IHideBanner {
	type: typeof CookieActions.hide_banner;
	payload: boolean;
}
export const hideBanner = createAction<IHideBanner['payload']>(
	CookieActions.hide_banner
);

interface IToggleBanner {
	type: typeof CookieActions.toggle_banner;
}
export const toggleBanner = createAction<IToggleBanner>(
	CookieActions.toggle_banner
);
interface IToggleBannerExpanded {
	type: typeof CookieActions.toggle_banner_expanded;
}
export const toggleBannerExpanded = createAction<IToggleBannerExpanded>(
	CookieActions.toggle_banner_expanded
);

interface IAcceptAll {
	type: typeof CookieActions.accept_all;
}
export const acceptAll = createAction<IAcceptAll>(CookieActions.accept_all);
interface IToggleGroup {
	type: typeof CookieActions.toggle_group;
	payload: 'analytics' | 'marketing';
}
export const toggleGroup = createAction<IToggleGroup['payload']>(
	CookieActions.toggle_group
);
