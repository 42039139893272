import React from 'react';

export interface IFeatureIconProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	title?: string;
	text: string;
	icon: JSX.Element;
	description?: string;
	orientation?: 'vertical' | 'horizontal';
	withBg?: boolean;
	primary?: boolean;
	className?: string;
	size?: 'lg' | 'sm';
}

export const FeatureItem: React.FC<IFeatureIconProps> = ({
	title,
	text,
	description,
	icon,
	className = '',
	withBg,
	primary,
	orientation = 'horizontal',
	size = 'lg'
}) => {
	return (
		<div
			className={`
				featureItem
				featureItem--orientation-${orientation}
				featureItem--size-${size}
				${withBg ? 'featureItem--with-circle' : ''}
				${primary ? 'featureItem--primary' : ''}
				${className}
			`}
		>
			<span className="featureItem__icon">{icon}</span>
			<div className="featureItem__content">
				{title && <p className="featureItem__title">{title}</p>}
				<p className="featureItem__text">{text}</p>
				{description && (
					<p className="featureItem__description">{description}</p>
				)}
			</div>
		</div>
	);
};
