import { Placement } from '@popperjs/core';

export const checkoutDateFormat = 'DD.MM.YYYY';
export const filterDateFormat = 'DD-MM-YYYY';

export const dontFlipPopper = (placement: Placement) => {
	return {
		name: 'flip',
		options: {
			fallbackPlacements: [placement],
			allowedAutoPlacements: [placement], // by default, all the placements are allowed
			flipVariations: false // true by default
		}
	};
};

export const accInfoText = {
	parties_allowed: ['Parties allowed', 'No parties'],
	smoking_allowed: ['Smoking allowed', 'No smoking'],
	self_checkin_possible: [
		'Self check-in is possible',
		'Self check-in is not possible'
	],
	pets_allowed: ['Pets allowed', 'No pets'],
	entire_home: [
		'You will have entire place for yourself',
		'You might share place'
	]
};

export const CROATIA_VAT = 0.25;
