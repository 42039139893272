/* eslint-disable no-nested-ternary */
import { SvgBabyBath } from 'litto-lib/assets';
import { useMediaQuery } from 'litto-lib/hooks';
import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// import styles from './promoBannerSlider.module.scss';

SwiperCore.use([Pagination]);

const bannerContent = [
	{
		icon: SvgBabyBath,
		text: 'Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split. Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split.'
	},
	{
		icon: SvgBabyBath,
		text: 'Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split. Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split.'
	},
	{
		icon: SvgBabyBath,
		text: 'Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split. Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split.'
	},
	{
		icon: SvgBabyBath,
		text: 'Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split. Discover the breathtaking beauty of Krka National Park on an 8-hour scenic tour from Split.'
	}
];

export const PromoBanner = () => {
	const isSm = useMediaQuery('(max-width: 767px)');
	const isMd = useMediaQuery('(max-width: 1023px)');

	return (
		<div className=" promo-banner bg-brand pt-11">
			<div className="container">
				<h3 className="mb-8 text-2xl font-extrabold text-primary-500">
					Why book with Litto?
				</h3>
				<div className={`-mx-2.5`}>
					<Swiper
						pagination={{ clickable: true }}
						allowSlideNext={isSm || isMd}
						allowSlidePrev={isSm || isMd}
						slidesPerView={isMd ? (isSm ? 1.25 : 2.25) : 4}
					>
						{bannerContent.map((item, i) => (
							<SwiperSlide key={i} className="flex-col px-2.5">
								{/* <div className="w-1/4 px-2.5"> */}
								<item.icon className="mb-2 h-12 w-12 text-primary-500" />
								<p className="text-base">{item.text}</p>
								{/* </div> */}
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
};
