import {
	CustomAutoCompleteSelect,
	ICustomAutoCompleteSelectProps
} from 'litto-lib/components/common';
import React from 'react';

import { ILocationRead } from 'services/shop/interfaces';
import { useGetLocationsQuery } from 'services/shop/location';

type ILocationAutoCompleteSelectProps = {
	withDefaults?: boolean;
	onSelectOption: (location: ILocationRead) => void;
} & Omit<
	ICustomAutoCompleteSelectProps<ILocationRead>,
	| 'onSelectOption'
	| 'options'
	| 'keyToSearchBy'
	| 'optionDisplayValueKey'
	| 'defaultOptions'
>;

export const LocationAutoCompleteSelect: React.FC<
	ILocationAutoCompleteSelectProps
> = ({ withDefaults = false, setSearchTerm, onSelectOption, ...props }) => {
	const { data: cities } = useGetLocationsQuery();

	const { data: highlighted } = useGetLocationsQuery(
		{
			params: {
				homepageHighlighted: true
			}
		},
		{
			enabled: withDefaults
		}
	);

	return (
		<CustomAutoCompleteSelect
			options={cities?.['hydra:member'] || []}
			{...(withDefaults
				? { defaultOptions: highlighted?.['hydra:member'] }
				: {})}
			keyToSearchBy="translation.name"
			optionDisplayValueKey="translation.name"
			data-options={{ 'data-calendar-skip-close': true }}
			setSearchTerm={setSearchTerm}
			onSelectOption={onSelectOption}
			{...props}
		/>
	);
};

export default LocationAutoCompleteSelect;
