import { IOrderState } from 'context';
import { createOrder } from './';

export const ensureOrderKey = async (
	orderState: IOrderState,
	orderDispatch: any
): Promise<string> => {
	if (!orderState.orderKey) {
		const order = await createOrder(orderDispatch);
		return Promise.resolve(order.tokenValue);
	} else {
		return Promise.resolve(orderState.orderKey);
	}
};
