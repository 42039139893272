import { Field, FieldProps } from 'formik';
import React from 'react';

import { ITextAreaProps, TextArea } from '../../CustomTextarea/CustomTextarea';

export interface IFieldTextAreaProps extends ITextAreaProps {
	customComponent?: React.ElementType;
}

export const FieldTextArea: React.FC<IFieldTextAreaProps> = props => {
	const { className, name, ref, customComponent, ...rest } = props;

	return (
		<Field name={name}>
			{(props: FieldProps) => {
				const {
					field: { name, value, onChange },
					form: { submitCount },
					meta: { error }
				} = props;

				if (customComponent) {
					const CustomComponent = customComponent;

					return <CustomComponent />;
				}

				return (
					<TextArea
						className={className}
						ref={ref as any}
						name={name}
						value={value}
						state={(submitCount && (error ? 'invalid' : 'valid')) || 'default'}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
							onChange(e)
						}
						{...rest}
					/>
				);
			}}
		</Field>
	);
};
