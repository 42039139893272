import { ILocationRead } from 'services/shop/interfaces';
import { IUserState } from '../context/UserContext';
import { createAction } from './actions/actions';

export enum UserActions {
	UserStateInitialize = '[UserContext] UserStateInitalize',
	UserSetLocation = '[UserContext] User Set Location'
}

export type UserActionTypes =
	| IUserStateInitializeAction
	| IUserSetLocationAction;

export const userReducer = (state: IUserState, action: UserActionTypes) => {
	switch (action.type) {
		case UserActions.UserStateInitialize: {
			return {
				...state
			};
		}
		case UserActions.UserSetLocation: {
			return {
				...state,
				selectedLocation: action.payload
			};
		}
		default: {
			throw new Error('UserReducet: This reducer action does not exist');
		}
	}
};

interface IUserStateInitializeAction {
	type: typeof UserActions.UserStateInitialize;
	payload: IUserState;
}
export const userStateInitializeAction = createAction<
	IUserStateInitializeAction['payload']
>(UserActions.UserStateInitialize);

interface IUserSetLocationAction {
	type: typeof UserActions.UserSetLocation;
	payload: ILocationRead | undefined;
}
export const userSetLocationAction = createAction<
	IUserSetLocationAction['payload']
>(UserActions.UserSetLocation);
