import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

import { IAccommodationProductVariantRead } from './interfaces';
import {
	HydraCollectionResponse,
	HydraPaginationQueryParams
} from '../interfaces';

export const getAccommodationVariants = async (
	slug: string,
	config?: IGetAccommodationVariantsRequestConfig
): Promise<HydraCollectionResponse<IAccommodationProductVariantRead>> => {
	return await axios
		.get<HydraCollectionResponse<IAccommodationProductVariantRead>>(
			`/api/v2/shop/accommodations/${slug}/variants`,
			{
				...config
			}
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting accommodation variants: ${slug}`, error);
			return Promise.reject(error);
		});
};

interface IGetAccommodationVariantsRequestConfig extends AxiosRequestConfig {
	params?: HydraPaginationQueryParams & {
		'date[before]'?: string; //yyyy-mm-dd
		'date[strictly_before]'?: string; //yyyy-mm-dd
		'date[after]'?: string; //yyyy-mm-dd
		'date[strictly_after]'?: string; //yyyy-mm-dd
	};
}

type IGetaccommodationVariantsQueryConfig = UseQueryOptions<
	HydraCollectionResponse<IAccommodationProductVariantRead>,
	unknown,
	HydraCollectionResponse<IAccommodationProductVariantRead>
>;

const defaultConfig: IGetaccommodationVariantsQueryConfig = {
	refetchOnWindowFocus: true
};

export function useGetAccommodationVariantsQuery(
	slug: string,
	config?: IGetAccommodationVariantsRequestConfig,
	options?: IGetaccommodationVariantsQueryConfig
) {
	return useQuery(
		['accommodation_variants', slug, ...Object.values(config?.params || [])],
		() => {
			return getAccommodationVariants(slug, config);
		},
		{ ...(defaultConfig as any), ...options }
	);
}
