import axios from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IOrderCheckout } from './interfaces';
import { ordersKeys } from './queryKeys';

export const getOrder = async (orderKey: string) => {
	return await axios
		.get<IOrderCheckout>(`/api/v2/shop/orders/${orderKey}`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting order: ${orderKey}`, error);
			return Promise.reject(error);
		});
};

export type IGetOrderQueryOptions = UseQueryOptions<
	IOrderCheckout,
	any,
	IOrderCheckout
>;

export function useGetOrderQuery(
	orderKey: string,
	options?: IGetOrderQueryOptions
) {
	return useQuery(
		ordersKeys.details(orderKey) as QueryKey,
		() => {
			return getOrder(orderKey);
		},
		{ refetchOnWindowFocus: false, ...options }
	);
}
