import { SvgArrowIcon, SvgClear, SvgSearchIcon } from 'litto-lib/assets/';
import {
	Button,
	CustomDayPickerRangeController,
	Modal,
	PopperButton
} from 'litto-lib/components/common';
import { checkoutDateFormat, formatGuests } from 'litto-lib/helpers';
import { useMediaQuery } from 'litto-lib/hooks';
import { getNonZeroQueries, getQueryString } from 'litto-lib/utils';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { FocusEventHandler, useEffect } from 'react';

import {
	GuestForm,
	IGuestFormData,
	LocationAutoCompleteSelect
} from 'components/shared';
import { ILocation, ILocationRead } from 'services/shop/interfaces';

interface IFormData {
	channel: string;
	adults: number;
	children: number;
	infants: number;
	checkin: string;
	checkout: string;
}

interface IMasterSearchProps {
	className?: string;
	selectedLocation?: ILocation | ILocationRead;
}

export const MasterSearch: React.FC<IMasterSearchProps> = ({
	className = '',
	selectedLocation
}) => {
	const router = useRouter();
	const isMobile = useMediaQuery('(max-width: 819px)');
	const isXl = useMediaQuery('(max-width: 1199px)');
	const [formValues, setFormValues] = React.useState<IFormData>({
		channel: '',
		adults: 0,
		children: 0,
		infants: 0,
		checkin: '',
		checkout: ''
	});
	const { channel, adults, children, infants } = formValues;
	const [searchTerm, setSearchTerm] = React.useState('');
	const [showLocationStep, setShowLocationStep] = React.useState(false);
	const [shouldShowGuestStep, setShouldShowGuestsStep] = React.useState(false);
	const calendarRef = React.useRef<any>(null);
	const guestsRef = React.useRef<any>(null);

	useEffect(() => {
		if (selectedLocation) {
			setSearchTerm(selectedLocation.translation.name);
			setFormValues({
				...formValues,
				channel: selectedLocation.code
			});
		}
	}, []);

	const showGuestStep = React.useCallback(() => {
		setShouldShowGuestsStep(true);
		setTimeout(() => {
			guestsRef?.current?.openPopper();
		}, 1);
	}, []);

	const hideGuestStep = React.useCallback(() => {
		setShouldShowGuestsStep(false);
		setTimeout(() => {
			guestsRef?.current?.closePopper();
		}, 1);
	}, []);

	const onSelectLocation = (location: ILocationRead) => {
		const { code, translation } = location;
		setSearchTerm(translation.name);
		setFormValues({
			...formValues,
			channel: code
		});
		calendarRef?.current?.openCalendar();
		setShowLocationStep(false);
	};

	const onDatesChange = ({
		startDate,
		endDate
	}: {
		startDate: moment.Moment;
		endDate: moment.Moment;
	}) => {
		setFormValues({
			...formValues,
			checkin: startDate ? startDate.format(checkoutDateFormat) : '',
			checkout: endDate ? endDate.format(checkoutDateFormat) : ''
		});
	};

	const onDatesSubmit = () => {
		calendarRef?.current?.closeCalendar();
		if (!channel) {
			setShowLocationStep(true);
		} else {
			showGuestStep();
		}
	};

	const onGuestsFormChange = (guestsFormData: IGuestFormData) => {
		setFormValues({ ...formValues, ...guestsFormData });
	};

	const onGuestsSubmit = () => {
		if (!channel) {
			hideGuestStep();
			setShowLocationStep(true);
		} else {
			onSearchSubmit();
		}
	};

	const onSearchSubmit = () => {
		hideGuestStep();
		setShowLocationStep(false);
		calendarRef?.current?.closeCalendar();
		const { channel, ...otherFormValues } = formValues;
		if (!channel) {
			setShowLocationStep(true);
			return;
		}
		const nonEmptyQueryParams = getNonZeroQueries(otherFormValues);
		const searchParams = getQueryString(nonEmptyQueryParams);
		router.push(
			`/${channel}/accommodations`,
			`/${channel}/accommodations${searchParams ? '?' + searchParams : ''}`,
			{}
		);
	};

	return (
		<div
			data-testid="masterSearch"
			className={`${className} w-full flex-wrap items-center rounded-xl bg-white pt-3 sm:inline-flex xl:flex-nowrap xl:pt-0`}
			style={{ maxWidth: '980px' }}
		>
			{/* CITY SELECT  */}
			{!isMobile ? (
				<LocationAutoCompleteSelect
					customSearchElement={CustomPopperInputTrigger as any}
					searchElementProps={{
						className: 'z-10 w-full xl:w-64',
						label: 'Location',
						placeholder: 'Select location',
						id: 'city',
						name: 'city',
						value: searchTerm
					}}
					setSearchTerm={setSearchTerm}
					isOpen={showLocationStep}
					setIsOpen={setShowLocationStep}
					onSelectOption={onSelectLocation}
				/>
			) : (
				<React.Fragment>
					<CustomPopperTrigger
						onClick={() => {
							setShowLocationStep(true);
						}}
						selected={showLocationStep}
						className="w-full"
						label="Location"
						value={searchTerm}
						placeholder="Select destination"
					/>
					<Modal
						noPadding
						headerText="Destination"
						fullHeight
						innerScrollSelector=".overflow-y-auto"
						isOpen={showLocationStep}
						onRequestClose={() => setShowLocationStep(false)}
					>
						<LocationAutoCompleteSelect
							className="pt-6 md:pt-0 lg:pt-6"
							searchElementProps={{
								placeholder: 'Search for a location',
								id: 'city',
								name: 'city',
								onChange: e =>
									setSearchTerm((e.target as HTMLInputElement).value),
								value: searchTerm
							}}
							setSearchTerm={setSearchTerm}
							onSelectOption={onSelectLocation}
							isOpen={showLocationStep}
							setIsOpen={setShowLocationStep}
						/>
					</Modal>
				</React.Fragment>
			)}
			<hr className="w-full xl:hidden" />
			{/* DATES SELECT */}
			<CustomDayPickerRangeController
				className="w-full"
				offset={[0, 6]}
				innerRef={calendarRef}
				placement={isXl ? 'bottom-end' : 'bottom'}
				onDatesChange={onDatesChange as any}
				initialVisibleMonth={() => moment()}
				minDate={moment().add(-1, 'days')}
				isDayBlocked={day => day.isBefore(moment().add(-1, 'days'))}
				closeOnOutsideClick={!isMobile}
				slotBottom={
					<div className="flex justify-end">
						<Button onClick={onDatesSubmit} color="primary">
							{!channel ? 'Select' : 'Next'}
						</Button>
					</div>
				}
				showInputs
			/>
			<hr className="w-full xl:hidden" />
			{/* GUESTS SELECT */}
			{!isMobile && (
				<PopperButton
					size="lg"
					innerRef={guestsRef}
					offset={[0, 6]}
					className="w-full xl:w-[200px]"
					customToggle={isActive => (
						<CustomPopperTrigger
							onClick={!shouldShowGuestStep ? showGuestStep : hideGuestStep}
							selected={isActive}
							className="w-full"
							label="Guests"
							placeholder="Add guests"
							value={
								adults + children + infants >= 1
									? `${formatGuests(adults, children, infants)}`
									: ''
							}
						/>
					)}
				>
					<GuestForm
						initialValues={{
							adults: adults,
							children: children,
							infants: infants
						}}
						minAdults={0}
						onChange={onGuestsFormChange}
					/>
				</PopperButton>
			)}
			{isMobile && (
				<React.Fragment>
					<CustomPopperTrigger
						onClick={showGuestStep}
						selected={shouldShowGuestStep}
						className="w-full"
						label="Guests"
						placeholder="Add guests"
						value={
							adults + children + infants >= 1
								? `${formatGuests(adults, children, infants)}`
								: ''
						}
					/>
					<Modal
						headerText="Guests"
						isOpen={shouldShowGuestStep}
						onRequestClose={hideGuestStep}
					>
						<GuestForm
							initialValues={{
								adults: adults || 0,
								children: children || 0,
								infants: infants || 0
							}}
							minAdults={0}
							onChange={onGuestsFormChange}
						/>
						<Modal.Footer key="ModalFooter">
							<div className="flex justify-end">
								<Button
									text={!channel ? 'Select Destination' : 'Search'}
									onClick={onGuestsSubmit}
									color="primary"
									type="submit"
									data-popper-close
								/>
							</div>
						</Modal.Footer>
					</Modal>
				</React.Fragment>
			)}
			<div className="mx-6 mb-8 lg:mr-6 xl:mb-0 xl:ml-auto">
				<Button
					className="w-full"
					color="primary"
					text="Search"
					onClick={onSearchSubmit}
				/>
			</div>
		</div>
	);
};

interface ICustomPopperTriggerProps {
	selected?: boolean;
	className?: string;
	label?: string;
	value?: string;
	placeholder: string;
	onClick?: () => void;
}

const CustomPopperTrigger = ({
	className,
	selected,
	label,
	value,
	placeholder,
	onClick
}: ICustomPopperTriggerProps) => (
	<button
		onClick={onClick}
		className={`${className || ''} ${
			selected ? ' shadow-lg ' : ''
		} relative rounded-xl px-6 py-5 text-left text-base outline-none hover:opacity-75 focus:shadow-lg active:text-primary-500`}
	>
		<p
			className={`${
				selected ? 'text-primary-500' : ''
			} pr-4 font-title font-bold`}
		>
			{label}
		</p>
		<p
			className={`mt-1 whitespace-nowrap pr-8 text-md xl:mt-0.5 ${
				value ? '' : 'text-gray-300'
			}`}
		>
			{value ? value : placeholder}
		</p>
		<SvgArrowIcon className="pin-r svg-no-prefill mr-8" />
	</button>
);

interface ICustomPopperInputTriggerProps
	extends React.HTMLAttributes<HTMLInputElement> {
	innerRef?: React.LegacyRef<HTMLInputElement>;
	name: string;
	label?: string;
	value?: string;
	selected?: boolean;
	onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	onClear?: () => void;
}

const CustomPopperInputTrigger = ({
	className,
	selected,
	label,
	name,
	id,
	value,
	placeholder,
	onChange,
	onFocus,
	onKeyDown,
	onClear,
	innerRef
}: ICustomPopperInputTriggerProps) => {
	const shouldShowClear = onClear && value !== '';
	return (
		<div
			className={`${className || ''} ${
				selected ? ' shadow-lg ' : ''
			} relative block rounded-xl border border-white px-6 py-5 text-left text-base focus-within:shadow-lg  hover:opacity-75 `}
		>
			<label
				htmlFor={` ${id}`}
				className={`
				${selected ? 'text-primary-500' : ''}
				block pr-4 font-title font-bold
			 `}
			>
				{' '}
				{label}
			</label>
			<input
				ref={innerRef}
				id={` ${id}`}
				name={` ${name}`}
				value={value}
				type="search"
				placeholder={placeholder}
				className="hide-clear w-full cursor-pointer pr-8 text-md outline-none"
				onFocus={onFocus}
				onChange={onChange}
				onKeyDown={onKeyDown}
				autoComplete="off"
			/>
			<button
				{...(!shouldShowClear && { tabIndex: -1 })}
				className={`${
					shouldShowClear ? '' : 'pointer-events-none'
				} pin-r mr-8 flex w-5 items-center justify-center outline-none transition-opacity focus:opacity-50`}
				onClick={onClear}
			>
				{shouldShowClear ? (
					<SvgClear className="svg-no-prefill w-5" />
				) : (
					<SvgSearchIcon className="svg-no-prefill w-4" />
				)}
			</button>
		</div>
	);
};
