import * as Sentry from '@sentry/nextjs';
import { FLUSH_TIMEOUT } from '.';

/**
 * Flushes Sentry queue in a safe way.
 *
 * It's necessary to flush all Sentry events on the server, because Vercel runs on AWS Lambda, see https://vercel.com/docs/platform/limits#streaming-responses
 * If you don't flush, then it's possible the Sentry events won't be sent.
 * This helper is meant to be used for backend-only usage. (not frontend)
 *
 * There is a potential bug in Sentry that throws an exception when flushing times out, causing API endpoints to fail.
 * https://github.com/getsentry/sentry/issues/26870
 */
export const flushSafe = async (): Promise<boolean> => {
	try {
		return await Sentry.flush(FLUSH_TIMEOUT);
	} catch (e) {
		console.error(
			`[flushSafe] An exception was thrown while running Sentry.flush()`,
			e
		);
		return false;
	}
};
