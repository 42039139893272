import { addDefaultsToParams } from './getLocations';

export const locationKeys = {
	all: () => ['locations'] as const,
	lists: () => [...locationKeys.all(), 'list'] as const,
	list: (filters: any) =>
		[...locationKeys.lists(), { ...addDefaultsToParams(filters) }] as const,
	details: () => [...locationKeys.all(), 'detail'] as const,
	detail: (code: string) => [...locationKeys.details(), code] as const,
	detailBySlug: (slug: string) => [...locationKeys.details(), slug] as const
};
