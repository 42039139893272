export const priceParser = (
	centsPrice: number,
	currency = 'EUR',
	numberFormat = 'hr-HR'
) => {
	if (!centsPrice) {
		centsPrice = 0;
	}
	if (!currency) {
		currency = 'EUR';
	}
	const formatter = new Intl.NumberFormat(numberFormat, {
		style: 'currency',
		currency
	});

	return centsPrice
		? formatter
				.format(centsPrice / 100)
				.replace('EUR', '€')
				.replace(',00', '')
		: formatter
				.format(centsPrice / 100)
				.replace(/\D00$/, '')
				.replace('EUR', '€');
};

export const priceToCents = (price: number) => {
	return price * 100;
};

export const centsToPrice = (centsPrice: number): number => {
	return centsPrice / 100;
};

export const decimalToPercent = (decimal: number) => {
	return decimal * 100 + '%';
};
