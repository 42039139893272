import React from 'react';

import { InputFeedback } from '../CustomInput/CustomInput';

export type TextAreaInputState = 'default' | 'valid' | 'invalid';

export interface ITextAreaProps
	extends Omit<
		React.DetailedHTMLProps<
			React.TextareaHTMLAttributes<HTMLTextAreaElement>,
			HTMLTextAreaElement
		>,
		'size'
	> {
	className?: string;

	name: string;
	value?: string;
	label?: string;
	error?: string;
	state?: TextAreaInputState;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
	(
		{
			className = '',

			id,
			name,
			value,
			label,
			placeholder,
			error,
			state = 'default',
			iconLeft,
			iconRight,
			disabled,

			onChange
		},
		ref
	) => {
		const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
			onChange && onChange(e);
		};

		return (
			<div
				className={`${className} input input--state-${
					disabled ? 'default' : state
				}`}
			>
				{label && (
					<label
						className={`input__label ${
							disabled ? 'input__label__disabled' : ''
						}`}
						htmlFor={name}
					>
						{label}
					</label>
				)}
				<div className="input__element input__element_text_area">
					{iconLeft &&
						React.cloneElement(iconLeft, {
							className: 'input__icon__left'
						})}
					<textarea
						ref={ref}
						id={id || name}
						name={name}
						value={value}
						placeholder={placeholder}
						disabled={disabled}
						onChange={handleOnChange}
					/>

					{iconRight &&
						React.cloneElement(iconRight, {
							className: 'input__icon__right'
						})}
				</div>
				{error && <InputFeedback error={error} />}{' '}
			</div>
		);
	}
);

TextArea.displayName = 'TextArea';
