import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IAccommodation } from './interfaces';
import { QueryOptionsOmit } from 'litto-lib/types';

export const getAccommodation = async (
	code: string
): Promise<IAccommodation> => {
	return await axios
		.get<IAccommodation>(`/api/v2/shop/accommodations/${code}`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting accommodation: ${code}`, error);
			return Promise.reject(error);
		});
};

export function useGetAccommodationQuery(
	code: string,
	options?: QueryOptionsOmit<
		UseQueryOptions<IAccommodation, any, IAccommodation>
	>
) {
	return useQuery(
		['accommodation', code],
		() => {
			return getAccommodation(code);
		},
		options as any
	);
}
