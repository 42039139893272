import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HydraCollectionResponse } from '../interfaces';

import { IAccommodationAttributeRead } from './interfaces';

export const getAllAccommodationAttributes = async (): Promise<
	HydraCollectionResponse<IAccommodationAttributeRead>
> => {
	return await axios
		.get<HydraCollectionResponse<IAccommodationAttributeRead>>(
			`/api/v2/shop/accommodation-attributes`
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error getting accommodation-attributes: ', error);
			return Promise.reject(error);
		});
};

type IGetAllAccommodationAttributesQueryConfig = UseQueryOptions<
	HydraCollectionResponse<IAccommodationAttributeRead>,
	unknown,
	HydraCollectionResponse<IAccommodationAttributeRead>
>;

const defaultQueryOptions: IGetAllAccommodationAttributesQueryConfig = {
	refetchOnWindowFocus: false,
	enabled: false
};

export function useGetAllAccommodationAttributesQuery(
	options?: IGetAllAccommodationAttributesQueryConfig
) {
	return useQuery(
		['all_accommodation_attributes'],
		() => {
			return getAllAccommodationAttributes();
		},
		{ ...(defaultQueryOptions as any), ...options }
	);
}
