import React from 'react';
import { useState, useEffect } from 'react';

export interface ICustomCountProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	name: string;
	value?: number;
	step?: number;
	minDisabled?: boolean;
	maxDisabled?: boolean;
	onCountChange?: (val: number) => void;
	max?: number;
}

export const CustomCount: React.FC<ICustomCountProps> = ({
	value = 0,
	step = 1,
	min = 0,
	max = 10,
	minDisabled,
	maxDisabled,
	onCountChange
}) => {
	const [innerValue, setInnerValue] = useState<number>(0);

	useEffect(() => {
		setInnerValue(Number(value));
	}, [value]);

	return (
		<div className="flex items-center">
			<p className="mr-5 text-right text-xl font-bold">{innerValue}</p>
			<div className="h-12 w-32 overflow-hidden rounded border border-solid border-gray-100">
				{/*   @TODO: Možemo li ovde koristit button komponentu  */}
				<button
					type="button"
					disabled={minDisabled || innerValue === Number(min)}
					className={`${
						minDisabled || innerValue === min
							? 'cursor-default text-gray-100'
							: 'text-primary-500 hover:bg-gray-100'
					} h-full w-1/2 border-0 border-r border-solid border-gray-100 bg-transparent text-xl outline-none`}
					onClick={() => {
						const newVal = Number(innerValue) - step;
						setInnerValue(newVal);
						onCountChange && onCountChange(newVal);
					}}
				>
					-
				</button>
				<button
					type="button"
					disabled={maxDisabled || innerValue === max}
					className={`${
						maxDisabled || innerValue + 1 > max
							? 'cursor-default text-gray-100'
							: 'text-primary-500 hover:bg-gray-100'
					} text-primary-500 h-full w-1/2 border-none bg-transparent text-xl outline-none`}
					onClick={() => {
						const newVal = Number(innerValue) + step;
						setInnerValue(Number(innerValue) + step);
						onCountChange && onCountChange(newVal);
					}}
				>
					+
				</button>
			</div>
		</div>
	);
};
