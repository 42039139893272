import React from 'react';

interface IRowLayoutProps {
	className?: string;
	breakOn?: 'lg' | 'md' | 'sm';
	columns?: [number, number];
	title?: string | JSX.Element;
	subtitle?: string | JSX.Element;
	slotIntroBottom?: JSX.Element;
	noBorder?: boolean;
}

const breakOnMargins = new Map([
	['lg', 'lg:mr-5'],
	['md', 'md:mr-5'],
	['sm', 'sm:mr-5']
]);

export const RowLayout: React.FC<IRowLayoutProps> = ({
	className = '',
	columns = [4, 8],
	breakOn = 'md',
	title,
	subtitle,
	slotIntroBottom,
	noBorder = false,
	children
}) => {
	const marginClassName = React.useMemo(
		() => breakOnMargins.get(breakOn) || '',
		[breakOn]
	);
	return (
		<div
			className={`mb-10 flex flex-col justify-center border-b pb-10 ${
				noBorder ? '' : 'border-gray-100 last:border-none'
			} ${breakOn}:flex-row ${className} `}
		>
			<div className={`${breakOn}:col-${columns[0]}/12 ${marginClassName}`}>
				{title && (
					<h2 className={`mb-4 text-2xl font-bold ${breakOn}:mb-3`}>{title}</h2>
				)}
				<p className={`mb-5 leading-normal text-gray-300 ${breakOn}:last:mb-0`}>
					{subtitle}
				</p>
				{slotIntroBottom && slotIntroBottom}
			</div>
			<div className={`${breakOn}:col-${columns[1]}/12`}>{children}</div>
		</div>
	);
};
