import { useReducerWithLogger } from 'litto-lib/hooks';
import React from 'react';

import { CookieReducer } from './cookieReducer';

export const initialCookiesState: ICookieState = {
	isExpanded: false,
	isShown: false,
	levels: {
		necessary: true,
		analytics: false,
		marketing: false
	}
};

export const CookiesStateContext =
	React.createContext<ICookieState>(initialCookiesState);
export const CookiesDispatchContext = React.createContext<any>({});

export const CookiesProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducerWithLogger(
		CookieReducer,
		initialCookiesState,
		false && process.env.NODE_ENV === 'development'
	);

	return (
		<CookiesStateContext.Provider value={{ ...state }}>
			<CookiesDispatchContext.Provider value={dispatch}>
				{children}
			</CookiesDispatchContext.Provider>
		</CookiesStateContext.Provider>
	);
};

export const useCookiesStateContext = () => {
	const context = React.useContext(CookiesStateContext);
	if (context === undefined) {
		throw new Error(
			'useCookiesStateContext must be used within a CookiesStateProvider'
		);
	}
	return context;
};

export const useCookiesDispatchContext = () => {
	const context = React.useContext(CookiesDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useCookiesDispatchContext must be used within a CookiesDispatchContext'
		);
	}
	return context;
};

export type cookieLevelType = 'necessary' | 'analytics' | 'marketing';
export interface ICookieLevels {
	necessary: true;
	analytics: boolean;
	marketing: boolean;
}

export interface ICookieState {
	isExpanded: boolean;
	isShown: boolean;
	levels: ICookieLevels;
}
