import { QueryClient } from '@tanstack/react-query';

import { configReactQuery } from './react-query';
import { configAxios } from './axios';
import { configCloudinary } from './cloudinary';

export const configs = (): { client: QueryClient } => {
	const queryClient = configReactQuery();
	configAxios();
	configCloudinary();
	return { client: queryClient };
};
