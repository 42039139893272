import { useCallback, useEffect } from 'react';

export const useOnClickOutside = (
	ref: any,
	handler: () => void,
	deps?: any[]
) => {
	const escapeListener = useCallback((e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			handler?.();
		}
	}, []);
	const clickListener = useCallback(
		(e: MouseEvent) => {
			if (ref instanceof Array) {
				const hasRef = ref.some(r => {
					if (r.current && r.current.contains(e.target)) {
						return true;
					}
					return false;
				});
				if (!hasRef) {
					handler?.();
				}
			} else {
				if (ref && ref.current) {
					if (!ref.current.contains(e.target)) {
						handler?.();
					}
				}
			}
		},
		[ref, ...(deps ? deps : [])]
	);
	useEffect(() => {
		document.removeEventListener('click', clickListener);
		document.removeEventListener('keyup', escapeListener);
		document.addEventListener('click', clickListener);
		document.addEventListener('keyup', escapeListener);
		return () => {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
		};
	}, [...(deps ? deps : [])]);
	return ref;
};
