import React, { useEffect } from 'react';
import { SvgClear, SvgSearchIcon } from 'litto-lib/assets';

export interface ICustomInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	name: string;
	id?: string;
	value?: string;
	label?: string;
	placeholder?: string;
	className?: string;
	variant?: 'default' | 'label-inside' | 'borderless';
	inputBorder?: boolean;
	error?: string;
	info?: string;
	icon?: JSX.Element;
	onClear?: () => void;
	innerRef?: React.MutableRefObject<HTMLInputElement>;
}

export const CustomInput: React.FC<ICustomInputProps> = props => {
	// console.log('----props----', props);
	const {
		id,
		name,
		className = '',
		variant = 'default',
		type,
		label,
		value,
		placeholder,
		error,
		info,
		icon,
		onChange,
		onKeyDown,
		onClear,
		readOnly,
		onClick,
		innerRef,
		...rest
	} = props;
	const [innerValue, setInnerValue] = React.useState<string | undefined>('');
	// const [isHidden, setIsHidden] = useState<boolean>(true);

	useEffect(() => {
		if (value) setInnerValue(value);
	}, [value]);

	// const toggleIsHidden = () => {
	// 	setIsHidden(!isHidden);
	// };

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInnerValue(e.target.value);
		onChange && onChange(e);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		onKeyDown && onKeyDown(e);

		if (e.code === 'Space' && readOnly && onClick) {
			e.preventDefault();
			onClick(e as any);
		}
	};

	const handleSearchClear = () => {
		innerRef?.current.focus();
		onClear && onClear();
		setInnerValue('');
	};

	return (
		<div
			className={`
				${className}
				input
				input--type-${type}
				input--variant-${variant}

			`}
		>
			{label && (
				<label className="input__label" htmlFor={id || name}>
					{label}
				</label>
			)}
			{info && <p className="">{info}</p>}
			<div className="input__element">
				<input
					ref={innerRef}
					id={id || name}
					type={type}
					name={name}
					value={innerValue}
					placeholder={placeholder}
					onChange={handleOnChange}
					readOnly={readOnly}
					onClick={onClick}
					onKeyDown={handleKeyDown}
					{...rest}
				/>
				<div className="input__icon">
					{(icon && icon) ||
						(type === 'search' &&
							(innerValue ? (
								<button onClick={handleSearchClear}>
									<SvgClear />
								</button>
							) : (
								<SvgSearchIcon />
							)))}
				</div>

				{/* {type === 'password' && value && value !== '' && (
					<button
						onClick={toggleIsHidden}
						type="button"
						className="ml-20 mr-20 text-xs text-gray-600 underline bg-transparent border-none outline-none hover:text-primary"
					>
						Prikaži
					</button>
				)} */}
			</div>
			{error && <InputFeedback error={error} />}
		</div>
	);
};

export const InputFeedback = ({
	error,
	className = ''
}: {
	error: string;
	className?: string;
}) =>
	error ? (
		<p className={`${className} text-red mt-3 text-sm font-bold`}>{error}</p>
	) : null;
