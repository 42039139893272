import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import { createPopper, OptionsGeneric, State } from '@popperjs/core';
import type { Placement } from '@popperjs/core';

import { Button } from 'litto-lib/components/common';
import { useOnClickOutside } from 'litto-lib/hooks';
export interface IPopperButtonProps {
	innerRef?: IPopperButtonRef;
	className?: string;
	popoverClassName?: string;
	noPadding?: boolean;
	text?: string;
	size?: IPopperButtonSize;
	placement?: Placement;
	offset?: [number, number];
	modifiers?: any[];
	imperativeRefCallers?: React.Ref<any>[];
	customToggle?: JSX.Element | ((arg0: boolean) => JSX.Element);
	onClick?: () => void;
	onClickOutside?: () => void;
	onPopperClick?: () => void;
	defaultIsOpen?: boolean;
	disableToggle?: boolean;
	closeOnSelect?: boolean;
	closeOnOutsideClick?: boolean;
}

export type IPopperButtonSize = 'default' | 'lg';
export type IPopperButtonRef = React.Ref<{
	togglePopperOpen(): void;
	openPopper(): void;
	closePopper(): void;
	popperOpened: boolean;
}>;

const popoversizes = {
	default: 'px-6 py-5',
	lg: 'px-10 py-12'
};

export const PopperButton: React.FC<IPopperButtonProps> = ({
	innerRef,
	children,
	className,
	popoverClassName,
	noPadding = false,
	text,
	size = 'default',
	placement = 'bottom-start',
	offset = [0, 12],
	modifiers,
	imperativeRefCallers,
	customToggle,
	onClick,
	onClickOutside,
	onPopperClick,
	defaultIsOpen = false,
	disableToggle = false,
	closeOnSelect = false,
	closeOnOutsideClick = true
}) => {
	const [popperOpened, setPopperOpened] = useState<boolean>(defaultIsOpen);
	const togglePopperOpen = () => {
		setPopperOpened(prevState => !prevState);
	};
	const openPopper = () => {
		setPopperOpened(true);
	};
	const closePopper = () => {
		setPopperOpened(false);
	};

	useImperativeHandle(innerRef, () => ({
		togglePopperOpen: () => {
			togglePopperOpen();
		},
		openPopper: () => {
			openPopper();
		},
		closePopper: () => {
			closePopper();
		},
		popperOpened
	}));

	const toggleRef = useRef<HTMLSpanElement>(null);
	const popperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let popperInstance:
			| {
					destroy: any;
					state?: State;
					forceUpdate?: () => void;
					update?: () => Promise<Partial<State>>;
					setOptions?: (
						options: Partial<OptionsGeneric<any>>
					) => Promise<Partial<State>>;
			  }
			| undefined;
		const toggleCurrent = toggleRef.current;
		const popperCurrent = popperRef.current;
		if (toggleCurrent && popperCurrent) {
			popperInstance = createPopper(toggleCurrent, popperCurrent, {
				placement: placement,
				modifiers: [
					...(modifiers ? modifiers : []),
					{
						name: 'offset',
						options: {
							offset: offset
						}
					}
				]
			});
		}
		return () => {
			if (popperInstance) {
				popperInstance.destroy();
				popperInstance = undefined;
			}
		};
	}, [popperOpened]);

	useOnClickOutside(
		[
			toggleRef,
			...(closeOnSelect ? [] : [popperRef]),
			...(imperativeRefCallers ? imperativeRefCallers : [])
		],
		() => {
			if (closeOnOutsideClick) {
				setPopperOpened(false);
			}
			onClickOutside && onClickOutside();
		},
		[imperativeRefCallers]
	);

	usePopperClick(() => {
		setPopperOpened(false);
		onPopperClick && onPopperClick();
	});

	const customBtnEl =
		customToggle && typeof customToggle == 'object'
			? customToggle
			: typeof customToggle == 'function'
			? customToggle(popperOpened)
			: null;

	const customButton =
		customBtnEl &&
		React.cloneElement(customBtnEl, {
			text: text,
			onClick: () => {
				if (!disableToggle) {
					togglePopperOpen();
				}
				customBtnEl?.props?.onClick && customBtnEl?.props?.onClick();
				onClick && onClick();
			}
		});

	return (
		<>
			{customToggle ? (
				<span className={className || ''} ref={toggleRef}>
					{customButton}
				</span>
			) : (
				<span ref={toggleRef}>
					<Button
						text={text || 'Open'}
						onClick={() => {
							if (!disableToggle) {
								togglePopperOpen();
							}
							onClick && onClick();
						}}
						color={popperOpened ? 'primary' : 'dark'}
						size="sm"
					/>
				</span>
			)}
			{/* @Todo: dodat enter/leave animacije */}
			<div
				className={`${popoverClassName || ''} ${
					noPadding ? '' : popoversizes[size]
				} ${popperOpened ? '' : 'hidden'} z-50 rounded-xl bg-white shadow`}
				ref={popperRef}
			>
				{children}
			</div>
		</>
	);
};

const usePopperClick = (handler: () => void) => {
	const escapeListener = React.useCallback((e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			handler?.();
		}
	}, []);
	const clickListener = React.useCallback((e: MouseEvent) => {
		const shouldCloseOnClick =
			(e.target as any)?.getAttribute('data-popper-close') !== null ||
			((e.target as any)?.parentNode?.getAttribute &&
				(e.target as any)?.parentNode?.getAttribute('data-popper-close') !==
					null);
		if (shouldCloseOnClick) {
			handler?.();
		}
	}, []);
	useEffect(() => {
		document.addEventListener('click', clickListener);
		document.addEventListener('keyup', escapeListener);
		return () => {
			document.removeEventListener('click', clickListener);
			document.removeEventListener('keyup', escapeListener);
		};
	}, []);
	return;
};
