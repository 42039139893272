import React from 'react';
import { Slide, toast, ToastOptions } from 'react-toastify';

type UseToastType = () => ({
	headerText,
	message,
	type,
	options
}: IUseToastProps) => React.ReactText;

interface IUseToastProps {
	headerText: string;
	message: string;
	type: ToastType;
	options?: ToastOptions;
}

type ToastType = 'success' | 'warning' | 'error' | 'info';

type TextProp = {
	text: string;
};

export const ToastHeader = ({ text }: TextProp) => {
	return <h3>{text}</h3>;
};

export const ToastContent = ({ text }: TextProp) => {
	return <p className="line-clamp-1">{text}</p>;
};

export const useToast: UseToastType = () => {
	const clearWaitingQueue = () => {
		toast.clearWaitingQueue();
	};

	const show = ({
		headerText,
		message,
		type = 'success',
		options
	}: IUseToastProps) => {
		clearWaitingQueue();
		const Content = () => {
			return (
				<div className="max-w-5/5 flex flex-col items-center justify-center pl-4">
					<ToastHeader text={headerText} />
					<ToastContent text={message} />
				</div>
			);
		};

		const defaultOptions: ToastOptions = {
			toastId: 1,
			position: 'top-center',
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
			className: 'flex items-center w-full h-14 md:h-12 px-5',
			bodyClassName:
				'flex w-full justify-center items-center text-center text-sm text-white font-bold'
		};

		return toast[type](<Content />, { ...defaultOptions, ...options });
	};
	return show;
};
