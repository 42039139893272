import React from 'react';
import { CustomLabel } from '../';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

export interface ICustomPhoneInputProps extends PhoneInputProps {
	className?: string;
	label?: string;
	name: string;
	error?: string;
}

export const CustomPhoneInput: React.FC<ICustomPhoneInputProps> = ({
	className = '',
	label,
	name,
	error,
	buttonClass = `w-20`,
	inputClass = 'w-full h-14 pl-25 rounded text-md placeholder-gray-400',
	inputProps,
	...rest
}) => {
	return (
		<div className={`${className}`}>
			{label && (
				<CustomLabel className="mb-2" htmlFor={name}>
					{label}
				</CustomLabel>
			)}
			<PhoneInput
				buttonClass={buttonClass}
				inputClass={inputClass}
				inputProps={inputProps}
				{...rest}
			/>
			{error && <PhoneInputFeedback error={error} />}
		</div>
	);
};

const PhoneInputFeedback = ({ error }: { error: string }) =>
	error ? <p className="text-red mt-3 text-sm font-bold">{error}</p> : null;
