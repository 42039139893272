import { SvgArrowBack, SvgLogo } from 'litto-lib/assets';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';
interface IHeaderProps {
	hasSearch?: boolean;
	withBack?: boolean;
	slotCenter?: JSX.Element;
	slotRight?: JSX.Element;
	children?: ReactNode;
	customBackCallback?: () => void;
	theme?: 'transparent' | 'white';
	fullWidth?: boolean;
}
const themeMap = {
	transparent: {
		header: 'header-bg-shade',
		logo: 'text-brand',
		icons: 'text-white'
	},
	white: {
		header: 'bg-white border-0 border-b border-gray-100 border-solid',
		logo: 'text-primary-500',
		icons: 'text-black'
	}
};
export const Header: React.FC<
	IHeaderProps & React.HTMLAttributes<HTMLDivElement>
> = ({
	className,
	theme = 'white',
	withBack = false,
	customBackCallback,
	slotCenter,
	slotRight,
	fullWidth = false
}) => {
	const router = useRouter();

	return (
		<header className={`${className || ''} ${themeMap[theme]['header']} `}>
			<div
				className={` ${
					fullWidth ? 'px-4 md:px-10' : 'container'
				} relative flex h-[72px] items-center`}
			>
				<div className="flex items-center">
					{withBack ? (
						<button
							onClick={
								customBackCallback ? customBackCallback : () => router.back()
							}
							type="button"
							className={`pin-y focus:bg-gray-100" left-5 flex rounded-full p-2.5 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none md:left-10`}
						>
							<SvgArrowBack />{' '}
						</button>
					) : (
						<Link href="/">
							<a
								className={` ${
									fullWidth
										? ''
										: 'mr-5 cursor-pointer xl:mr-0 2xl:absolute 2xl:left-0 2xl:-translate-x-full 2xl:transform'
								}`}
								aria-label="Litto"
							>
								<SvgLogo className={`${themeMap[theme]['logo']} w-[55px]`} />
							</a>
						</Link>
					)}
				</div>
				{slotCenter && slotCenter}
				{slotRight && (
					<div className="ml-auto  2xl:absolute 2xl:right-0">{slotRight}</div>
				)}
			</div>
		</header>
	);
};
