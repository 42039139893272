import { NextApiRequest } from 'next';

import { GenericObject } from 'litto-lib/types';

export const convertRequestBodyToJSONbject = <T = unknown>(
	req: NextApiRequest
): GenericObject<T> => {
	let parsedBody: GenericObject<T> = {};

	if (typeof req?.body === 'string' && req?.body?.length > 0) {
		parsedBody = JSON.parse(req?.body);
	} else {
		parsedBody = req.body;
	}

	return parsedBody;
};
