import React, { PropsWithChildren } from 'react';
import { useField, useFormikContext } from 'formik';
import { CustomCheckboxGroup, ICustomCheckboxGroupProps } from '../../';

export const FieldCheckboxGroup = <ValueType,>(
	props: PropsWithChildren<ICustomCheckboxGroupProps<ValueType>>
) => {
	const { className, name, children, ...rest } = props;
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props.name);

	return (
		<div className={className}>
			<CustomCheckboxGroup
				{...field}
				{...props}
				onChange={(e: any) => {
					setFieldValue(name, e);
				}}
				{...rest}
			>
				{children}
			</CustomCheckboxGroup>
		</div>
	);
};
