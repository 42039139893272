import React, { PropsWithChildren, useEffect } from 'react';

const CustomCheckboxGroupContext = React.createContext<any>([]);

export const useCustomCheckboxGroupContext = () => {
	const context = React.useContext(CustomCheckboxGroupContext);
	if (context === undefined) {
		throw new Error(
			'useCustomCheckboxGroupContext must be used within a CustomCheckboxGroupContextProvider'
		);
	}
	return context;
};

export interface ICustomCheckboxGroupProps<ValueType>
	extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	defaultValue?: any;
	values?: ValueType;
	onChange?: any;
	format?: (state: ValueType[]) => any;
}

export const CustomCheckboxGroup = <ValueType,>(
	props: PropsWithChildren<ICustomCheckboxGroupProps<ValueType>>
) => {
	const { children, name, defaultValue, onChange, format, value, ...rest } =
		props;
	const [state, setState] = React.useState<ValueType[]>([
		...(defaultValue ? defaultValue : [])
	]);
	const handleOnChange = (value: ValueType) => {
		const nextState = [...state];

		if (!state.includes(value)) {
			nextState.push(value);
		} else {
			nextState.splice(nextState.indexOf(value), 1);
		}
		setState(nextState);
		onChange && onChange(format ? format(nextState) : nextState);
	};

	useEffect(() => {
		if (value === '') setState([]);
	}, [value]);

	return (
		<CustomCheckboxGroupContext.Provider value={[state, handleOnChange]}>
			<div id={name} {...rest}>
				{children}
			</div>
		</CustomCheckboxGroupContext.Provider>
	);
};
