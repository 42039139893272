import React from 'react';

export const GridHelper = () => {
	const [gridIsShown, setGridIsShown] = React.useState(false);
	const [pressed, setPressed] = React.useState(0);

	React.useEffect(() => {
		let timeoutInterval: any;
		const handleKeyboardGridToggle = (event: any) => {
			if (event.key && event.key.toLowerCase() === 'g') {
				clearTimeout(timeoutInterval);
				setPressed(pressed => pressed + 1);
				timeoutInterval = setTimeout(() => setPressed(0), 500);
			}
		};
		document.addEventListener('keydown', handleKeyboardGridToggle);
		return () => {
			document.removeEventListener('keydown', handleKeyboardGridToggle);
		};
	}, []);

	React.useEffect(() => {
		if (pressed > 1) {
			setGridIsShown(gridIsShown => !gridIsShown);
		}
	}, [pressed]);

	if (process.env.NODE_ENV === 'production') return null;

	return (
		<>
			{gridIsShown && (
				<div className="pointer-events-none fixed inset-0 z-20 opacity-25">
					<div className="container">
						<div className="flex gap-5">
							<div className="h-screen flex-1 bg-[#f00]"></div>
							<div className="h-screen flex-1 bg-[#f00]"></div>
							<div className="h-screen flex-1 bg-[#f00]"></div>
							<div className="h-screen flex-1 bg-[#f00]"></div>

							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
							<div className="hidden h-screen flex-1 bg-[#f00] md:block"></div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
