import React from 'react';
import { Field } from 'formik';

import {
	ICustomCheckboxProps,
	CustomCheckbox,
	useCustomCheckboxGroupContext
} from '../../';

export const FieldCheckbox: React.FC<ICustomCheckboxProps> = props => {
	const { className, name, ...rest } = props;
	const [state] = useCustomCheckboxGroupContext();
	return (
		<Field name={name}>
			{(props: any) => {
				const {
					field: { name, value, onChange }
				} = props;

				return (
					<div className={className}>
						<CustomCheckbox
							name={name}
							value={value}
							onChange={e => {
								if (!state) {
									onChange(e);
								}
							}}
							{...rest}
						/>
					</div>
				);
			}}
		</Field>
	);
};
